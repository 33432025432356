import { all, call, put, takeEvery , select } from 'redux-saga/effects';
import { LOAD_ACCOUNTS, 
	RECEIVE_ACCOUNTS, 
	UPDATE_ACCOUNT, 
	UPDATE_ACCOUNT_FAILED, 
	UPDATE_ACCOUNT_SUCCEEDED,
	REPAIR_ITEM,
	REPAIR_ITEM_RESPONSE,
	REPAIR_ITEM_FAILED,
	REFRESH_ITEM,
  	REFRESH_ITEM_SUCCEEDED,
  	REFRESH_ITEM_FAILED,
  	LOAD_ACCOUNT,
  	RECEIVE_ACCOUNT,
  	ADD_ACCOUNT,
  	ADD_ACCOUNT_SUCCEEDED,
  	ADD_ACCOUNT_FAILED,
  	DELETE_ACCOUNT,
  	DELETE_ACCOUNT_SUCCEEDED,
  	DELETE_ACCOUNT_FAILED } from '../actions/accounts';
import { LOAD_BALANCE_TIME_SERIES } from '../actions/timeSeries';
import { API_PATH } from '../utilities/apiPath'

const getUser = state => state.user;
const getAccounts = state => state.accounts;

function* fetchAccounts(action) {
  const token = action.token
  const user = yield select(getUser);

  if (token) {
	  const endpoint = API_PATH + 'api/accountsforuser';
	  const options = {
		  headers: {
		    Authorization: `Bearer ${token}`
		  }

	  }
	  const response = yield call(fetch, endpoint, options);
	  const data = yield response.json();
	  yield put({ type: RECEIVE_ACCOUNTS, accounts: data, user_id:user.userApp.user_id });
	}
}

export function* loadAccounts() {
  yield takeEvery(LOAD_ACCOUNTS, fetchAccounts);
}

function* putUpdateAccount(action) {
	//const user = yield select(getUser);
	const token = action.token

	if (token) {
		const endpoint = API_PATH + 'api/account/' + action.account.account_id
		const options = {
			method: 'PUT',
	        headers: {
	          'Accept': 'application/json',
	          'Content-Type': 'application/json',
	          'Authorization': `Bearer ${token}`
	        },
	        body: JSON.stringify(action.updatedAccount)
		}
		try {
			yield call(fetch, endpoint, options);
			yield put({ type: UPDATE_ACCOUNT_SUCCEEDED, account: action.account });
		}
		catch {
			yield put({ type: UPDATE_ACCOUNT_FAILED, account: action.account });
		}
		
	  	
	  	
	}

}

export function* updateAccount() {
  yield takeEvery(UPDATE_ACCOUNT, putUpdateAccount);
}

function* postAddAccount(action) {
	//const user = yield select(getUser);
	const token = action.token

	if (token) {
		const endpoint = API_PATH + 'api/account'
		const options = {
			method: 'POST',
	        headers: {
	          'Accept': 'application/json',
	          'Content-Type': 'application/json',
	          'Authorization': `Bearer ${token}`
	        },
	        body: JSON.stringify(action.account)
		}
		try {
			const response = yield call(fetch, endpoint, options);
	  		const data = yield response.json();
			yield put({ type: ADD_ACCOUNT_SUCCEEDED, account: data });
			yield put({ type: LOAD_BALANCE_TIME_SERIES, token });
		}
		catch {
			yield put({ type: ADD_ACCOUNT_FAILED, account: action.account });
		}
		
	  	
	  	
	}

}

export function* addAccount() {
  yield takeEvery(ADD_ACCOUNT, postAddAccount);
}

function* fetchRepairItem(action) {
	const token = action.token

	if (token) {
		const endpoint = API_PATH + 'api/item/' + action.item_id
		const options = {
	        headers: {
	          'Authorization': `Bearer ${token}`
	        },
		}
		try {
			const response = yield call(fetch, endpoint, options);
			const data = yield response.json();
			yield put({ type: REPAIR_ITEM_RESPONSE, item: data });
			yield put({ type: REFRESH_ITEM, item_id: action.item_id, token });
		}
		catch {
			yield put({ type: REPAIR_ITEM_FAILED, item_id: action.item_id });
		}
	}

}

export function* repairItem() {
  yield takeEvery(REPAIR_ITEM, fetchRepairItem);
}

function* fetchRefreshItem(action) {
	const token = action.token

	if (token) {
		const endpoint = API_PATH + 'plaid/item/'+action.item_id+'/update'
		const options = {
	        headers: {
	          'Authorization': `Bearer ${token}`
	        },
		}
		try {
			yield call(fetch, endpoint, options);
			yield put({ type: REFRESH_ITEM_SUCCEEDED, item_id: action.item_id, token });
		}
		catch {
			yield put({ type: REFRESH_ITEM_FAILED, item_id: action.item_id });
		}
	}

}

export function* refreshItem() {
  yield takeEvery(REFRESH_ITEM, fetchRefreshItem);
}

function* loopRefreshAccount(action) {
	const token = action.token
	const accounts = yield select(getAccounts);

	let matchingAccountIds = Object.keys(accounts).filter((account_id) => accounts[account_id].plaid_account && accounts[account_id].plaid_account.plaid_item.item_id === action.item_id)
	let yield_alls = []
	// console.log(matchingAccountIds)
	matchingAccountIds.forEach((aid) => {
		yield_alls.push(put({ type: LOAD_ACCOUNT, account_id: aid, token }));
	})
	yield all(yield_alls)	

}

export function* refreshAccount() {
  yield takeEvery(REFRESH_ITEM_SUCCEEDED, loopRefreshAccount);
}

function* fetchAccount(action) {
  const token = action.token

  if (token) {
	  const endpoint = API_PATH + 'api/account/' + action.account_id;
	  const options = {
		  headers: {
		    Authorization: `Bearer ${token}`
		  }

	  }
	  const response = yield call(fetch, endpoint, options);
	  const data = yield response.json();
	  yield put({ type: RECEIVE_ACCOUNT, account: data });
	}
}

export function* loadAccount() {
  yield takeEvery(LOAD_ACCOUNT, fetchAccount);
}


function* putDeleteAccount(action) {
	//const user = yield select(getUser);
	const token = action.token

	if (token) {
		const endpoint = API_PATH + 'api/account/' + action.account_id
		const options = {
			method: 'DELETE',
	        headers: {
	          'Accept': 'application/json',
	          'Content-Type': 'application/json',
	          'Authorization': `Bearer ${token}`
	        }
		}
		try {
			yield call(fetch, endpoint, options);
			yield put({ type: DELETE_ACCOUNT_SUCCEEDED });
		}
		catch {
			yield put({ type: DELETE_ACCOUNT_FAILED, account_id: action.account_id });
		}
		
	  	
	  	
	}

}

export function* deleteAccount() {
  yield takeEvery(DELETE_ACCOUNT, putDeleteAccount);
}

