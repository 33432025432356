import Accounts from '../components/Accounts'
import React from "react";

const AccountsView = (props) => {

	const { space_id } = props.match ? props.match.params : {space_id: null};

	const default_account_filters = {
		is_deleted: false,
		is_closed: false
	}

	const account_deleted_filters = {
		is_deleted: true
	}

	const closed_account_filters = {
		is_closed: true
	}	

	return (
	  <div>
	  	{!space_id && 
	  		<React.Fragment>
			  	<Accounts title="My Accounts" space_id={space_id} filters={default_account_filters} />
			  	<Accounts title="Closed Accounts" space_id={space_id} filters={closed_account_filters} show_add={false} />
			  	<Accounts title="Deleted Accounts" space_id={space_id} filters={account_deleted_filters} show_add={false} show_spaces={false}  />
		  	</React.Fragment>
		  }
		{space_id &&
			<React.Fragment>
				<Accounts title="Accounts" space_id={space_id} filters={default_account_filters} />
				<Accounts title="Closed Accounts" space_id={space_id} filters={closed_account_filters} show_add={false} />
			</React.Fragment>
		}
		
	  </div>
	)
}

export default AccountsView;