import React, {useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import ProgressSpinner from '../utilities/ProgressSpinner'
import Paper from '@material-ui/core/Paper';
import Title from './Title'
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend
} from 'recharts';
import moment from 'moment'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    // minWidth: 200,
    // width: '100%',
    // overflowX: 'auto',
  },
  selectMonths: {
    minWidth: 200,
    float: "right"
  },
});

// const colorArray = ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', 
// '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff',
// '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', 
// '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000'];

const colorArray = ['#80cbc4','#80deea','#b39ddb','#9fa8da','#ffe082','#b0bec5'];


const BalanceTimeChart = (props) => {
	const { classes } = props;
  const { account, dataReady, formattedTimeSeries, formattedTimeSeriesLongTerm } = props
  const [dataType, setDataType] = useState(account && account.closed_date ? "Historical" : "Recent")

	return(
		
			      
			<React.Fragment>
		      <Paper className={classes.paper}>
          <Grid container alignItems="center" justify="space-between">
              <Grid item xs={12} sm={6}>
                <Title>Account Balance</Title>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  value={dataType}
                  onChange={(event) => setDataType(event.target.value)}
                  className={classes.selectMonths}
                >
                  {account && !account.closed_date && 
                    <MenuItem value={"Recent"}>Recent</MenuItem>
                  }
                  <MenuItem value={"Historical"}>Historical</MenuItem>
                </Select>
              </Grid>
            </Grid>
				{!dataReady &&
		        	<ProgressSpinner />
		  		}
		  		{dataReady &&
		  		<ResponsiveContainer width="95%" height={400}>
			    	<LineChart data={dataType === 'Historical' ? formattedTimeSeriesLongTerm : formattedTimeSeries}
			            margin={{top: 10, right: 0, left: 30, bottom: 0}}>
			        <CartesianGrid strokeDasharray="3 3"/>
			        <XAxis dataKey="date" tickFormatter={(value) => moment(value).format('MM/DD/YY')}/>
			        <YAxis tickFormatter={(value) => new Intl.NumberFormat('en', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(value)} />
			        <Tooltip formatter={(value) => new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(value)} />
			        <Legend />
              <Line key={account.account_id} type='monotone' dataKey={account.name} stackId="1" dot={false} stroke={colorArray[0]} strokeWidth={2} />

			        }
			      </LineChart>
			     </ResponsiveContainer>
			    } 
			  </Paper>
			</React.Fragment>
	);
};

const mapStateToProps = (state, props) => {
  const { account_id } = props;
  const { timeSeries, accounts, timeSeriesLongTerm } = state


  if (!timeSeries || !timeSeriesLongTerm || !accounts[account_id]) {
    //console.log(timeSeries)
    // console.log(accounts[account_id])
    return {
      account: null,
      dataReady: false
    }
  }

  let accountTimeSeries = timeSeries[account_id] ? timeSeries[account_id] : {}
  let accountTimeSeriesLongTerm = timeSeriesLongTerm[account_id] ? timeSeriesLongTerm[account_id] : {}

  return {
    formattedTimeSeries: Object.keys(accountTimeSeries).map((entry) => {
      let formattedEntry = {}
      formattedEntry['date'] = entry
      formattedEntry[accounts[account_id].name] = accountTimeSeries[entry]
      return formattedEntry
    }),
    formattedTimeSeriesLongTerm: Object.keys(accountTimeSeriesLongTerm).map((entry) => {
      let formattedEntry = {}
      formattedEntry['date'] = entry
      formattedEntry[accounts[account_id].name] = accountTimeSeriesLongTerm[entry]
      return formattedEntry
    }),
    account: accounts[account_id],
    dataReady: true
  };
};



export default connect(mapStateToProps)(withStyles(styles)(BalanceTimeChart));