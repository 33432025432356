import React, {useState, useEffect} from 'react';
import Title from '../components/Title';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend
} from 'recharts';
import { connect } from 'react-redux'
import ProgressSpinner from '../utilities/ProgressSpinner'
import moment from 'moment'
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { labelFormater } from '../utilities/labelFormater'

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  selectMonths: {
    minWidth: 200,
    float: "right"
  },  
});

const colorArray = ['#4caf50','#f44336','#2196f3','#673ab7','#ff9800','#009688'];

const ScenariosTimeSeries = (props) => {

	const { classes, dataReady, formattedTimeSeries, scenarioNames } = props
	const [years, setYears] = useState(60)
	const [scenarioNamesFilter, setScenarioNamesFilter] = useState(scenarioNames)

	const firstPoint = formattedTimeSeries && formattedTimeSeries[0] ? formattedTimeSeries[0] : null;

	let scenarioLinesHidden = {}

	scenarioNames.forEach((scenario_name) => scenarioLinesHidden[scenario_name] = false)

	const hideSeries =  (event) => {
		let updatedNames = []
		scenarioNamesFilter.forEach((scenarioName) => {
			if (event.value !== scenarioName) {
				updatedNames.push(scenarioName)
			} else {
				if (scenarioName.endsWith(" ")) {
					updatedNames.push(scenarioName.trim())
				} else {
					updatedNames.push(scenarioName + " ")
				}
			}
		} )

		setScenarioNamesFilter(updatedNames)
	}

  useEffect(() => {
    async function resetFilter() {
      setScenarioNamesFilter(scenarioNames)
    }
    resetFilter()
  }, [scenarioNames]);	

  var filteredData = dataReady ? formattedTimeSeries.filter((x) => moment(x.date) <= moment(firstPoint.date).add({years},'y')) : null
 

	return (
	  <div>
	  	<Paper className={classes.paper}>
          <Grid container alignItems="center" justify="space-between">
              <Grid item xs={12} sm={6}>
                <Title>Plan Projections (Net Worth)</Title>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  value={years}
                  onChange={(event) => setYears(event.target.value)}
                  className={classes.selectMonths}
                >
                  <MenuItem value={5}>5 Years</MenuItem>
                  <MenuItem value={10}>10 Years</MenuItem>
                  <MenuItem value={20}>20 Years</MenuItem>
                  <MenuItem value={30}>30 Years</MenuItem>
                  <MenuItem value={60}>60 Years</MenuItem>
                </Select>
              </Grid>
            </Grid>	
			{!dataReady &&
	        	<ProgressSpinner />
	  		}
	  		{dataReady &&
	  		<ResponsiveContainer width="95%" height={400}>
		    	<LineChart data={filteredData}
		            margin={{top: 10, right: 0, left: 30, bottom: 0}}>
		        <CartesianGrid strokeDasharray="3 3"/>
		        <XAxis dataKey="date" tickFormatter={(value) => moment(value).format('YYYY')} />
		        <YAxis tickFormatter={labelFormater} type="number" allowDataOverflow={true} />
		        <Tooltip formatter={(value) => new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(value)} /> 
		        <Legend onClick={(event) => hideSeries(event) } />
		        {scenarioNamesFilter.map((scenarioName, index) => (
		        		<Line connectNulls key={scenarioName} type='monotone' dataKey={scenarioName} stroke={colorArray[index]} dot={false} strokeWidth={2} />
		        	))

		        }
		      </LineChart>
		     </ResponsiveContainer>
		    } 
	  	</Paper>
	  </div>
	)
}

const mapStateToProps = (state, props) => {
  const { scenarios } = state
  const { space_id } = props


  if (!scenarios || Object.keys(scenarios).length === 0) {
    return {
      dataReady: false,
      scenarioNames: []
    }
  }

	let data = {}
	let scenarioNames = []

	let scenario_ids = Object.keys(scenarios).filter((scenario_id) => !scenarios[scenario_id].is_deleted)

	if (space_id) {
		scenario_ids = scenario_ids.filter((scenario_id) => state.scenarios[scenario_id].space_id === Number(space_id))
	}	

	scenario_ids.forEach((scenario_id) => {
		let scenario_name = scenarios[scenario_id].scenario_name
		scenarioNames.push(scenario_name)
		scenarios[scenario_id].scenario_calculation_balances.sort((a,b) => moment(a.date) - moment(b.date)).forEach((balance) => {
			if (!data[balance.date]) {
				data[balance.date] = {}
				data[balance.date]['date'] = balance.date
			}
			if (!data[balance.date][scenario_name]) {
				data[balance.date][scenario_name] = 0
			}
			data[balance.date][scenario_name] += balance.balance

		})
	})


  return {
    dataReady: true,
    formattedTimeSeries: Object.values(data),
    scenarioNames: scenarioNames
  };
};

export default connect(mapStateToProps)(withStyles(styles)(ScenariosTimeSeries));