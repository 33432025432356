import { RECEIVE_USERS } from '../actions/users'
import {  UPDATE_USER_APP_DATA,
  UPDATE_USER_APP_DATA_FAILED } from '../actions/user';

export default function users (state = {}, action) {

  switch(action.type) {
    case RECEIVE_USERS :
      let users = {}
      action.users.map((user) => users[user.user_id] = user) 
      return {
        ...users
      }

    case UPDATE_USER_APP_DATA:
      return {
        ...state,
        [action.user.user_id]: {
          ...state[action.user_id],
          ...action.updatedUser
        }
      }

    case UPDATE_USER_APP_DATA_FAILED:
      return {
        ...state,
        [action.user.user_id]: {
          ...state[action.user_id],
          ...action.user
        }
      }        

    default :
      return state
  }
}