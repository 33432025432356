import { RECEIVE_TRANSACTION_QUERY, LOAD_TRANSACTION_QUERY} from '../actions/transactionQueries'

export default function transactions (state = {}, action) {
  switch(action.type) {
    case LOAD_TRANSACTION_QUERY :
      return {
        ...state,
        [action.queryParams] : {
          fetching: true,
        }
      }

    case RECEIVE_TRANSACTION_QUERY :

      return {
        ...state,
        [action.queryParams] : {
          fetching: false,
        }
      }

    default :
      return state
  }
}