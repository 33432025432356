export const loadState = () => {
  try {
    const expiry = localStorage.getItem('expiry');
    const now = new Date()
    const serializedState = localStorage.getItem('state');
    if (serializedState === null || expiry === null || now.getTime() > expiry) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
}; 

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
    const now = new Date()
    // Set to expire in 10 minutes
    localStorage.setItem('expiry', now.getTime() + (10 * 60 * 1000) )
  } catch {
    // ignore write errors
  }
};

export const clearState = () => {
  try {
    localStorage.setItem('state', {});
    console.log("local storage cleared")
  } catch {
    // ignore write errors
  } 
}