import { call, put, takeEvery } from 'redux-saga/effects';
import { LOAD_BALANCE_TIME_SERIES,
	RECEIVE_BALANCE_TIME_SERIES } from '../actions/timeSeries';
import { API_PATH } from '../utilities/apiPath'

function* fetchBalanceTimeSeries(action) {
  const token = action.token

  if (token) {
	  const endpoint = API_PATH + 'api/balancetimeseriesforuser';
	  const options = {
		  headers: {
		    Authorization: `Bearer ${token}`
		  }

	  }
	  const response = yield call(fetch, endpoint, options);
	  const data = yield response.json();
	  yield put({ type: RECEIVE_BALANCE_TIME_SERIES, timeseries: data });
	}
}

export function* loadBalanceTimeSeries() {
  yield takeEvery(LOAD_BALANCE_TIME_SERIES, fetchBalanceTimeSeries);
}