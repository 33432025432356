import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux'


const styles = theme => ({
  userAvatar : {
    width: 24,
    height: 24,
    fontSize: "1em"
  },
  intitutionAvatar : {
  	width: 24,
    height: 24,
    fontSize: "1em",
  	backgroundColor: 'teal',
  },
  userAvatarMedium: {
    width: 32,
    height: 32,
    fontSize: "1em"    
  },
  intitutionAvatarMedium : {
    width: 32,
    height: 32,
    fontSize: "1em",
    backgroundColor: 'teal',
  }, 
});


const AccountAvatars = (props) => {
	const { classes } = props;
	const { account, showUser, users, size } = props;

  const userApp = users[account.owner_user_id]

  const showUser2 = showUser && userApp

	return(
     <AvatarGroup>
                      
      { account.plaid_account && account.plaid_account.plaid_item.plaid_institution.logo &&
      <Tooltip title={account.institution_name} arrow>
        <Avatar component='span' alt={account.institution_name} className={(size && size === 'medium') ? classes.intitutionAvatarMedium : classes.intitutionAvatar} src={`data:image/jpeg;base64,${account.plaid_account.plaid_item.plaid_institution.logo}`} />
      </Tooltip>
      }
      {(!account.plaid_account || !account.plaid_account.plaid_item.plaid_institution.logo) &&
      <Tooltip title={account.institution_name} arrow>
        <Avatar component='span' className={(size && size === 'medium') ? classes.intitutionAvatarMedium : classes.intitutionAvatar}>{account.institution_name.charAt(0)}</Avatar>
      </Tooltip>
      }
      {showUser2 && 
      <Tooltip title={userApp.first_name + ' ' + userApp.last_name} arrow>
        <Avatar component='span' className={(size && size === 'medium') ? classes.userAvatarMedium : classes.userAvatar} src={userApp.profile_image_url}>
          {userApp.first_name && userApp.first_name.charAt(0)}
        </Avatar>
      </Tooltip>  
  		}
    </AvatarGroup>

	);
};

const mapStateToProps = (state,props) => {
  return {
    users: state.users,
  };
};


export default connect(mapStateToProps)(withStyles(styles)(AccountAvatars));



               