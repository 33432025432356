import React from "react";
import ProgressSpinner from '../utilities/ProgressSpinner'
import Grid from '@material-ui/core/Grid';
import NetWorthTimeChart from '../components/NetWorthTimeChart';
import AmountCard from '../components/AmountCard';
import { connect } from 'react-redux'
import NetWorthTable from '../components/NetWorthTable'
import NetWorthSettings from '../components/NetWorthSettings'


const NetWorth = (props) => {
  const { space_id } = props;
  const [showClosed, setShowClosed] = React.useState(false)
  const [compPeriod, setCompPeriod] = React.useState(1)

  const { asset_subtype_ids,
    liability_subtype_ids,
    asset_subtypes,
    liability_subtypes,
    accounts,
    dataReady,
    net_worth,
    assets_total,
    liabilities_total,
    accountTypes,
    account_ids } = props



  const toggleShowClosedAccounts = () => {
    setShowClosed(!showClosed)
  }

  const toggleCompPeriod = (newCompPeriod) => {
    setCompPeriod(newCompPeriod)
  }

  return (
    <div>
      {/* <Typography className={classes.header} align="center" variant="h6" gutterBottom>Net Worth</Typography> */}
      <NetWorthSettings toggleClosed={toggleShowClosedAccounts} toggleCompPeriod={toggleCompPeriod} />
        <Grid container>
          <Grid item xs={12} md={4}>
            <AmountCard title="Total" amount={net_worth} showResult={dataReady} numberType={'money'} compPeriod={compPeriod} account_ids={account_ids} />
          </Grid>
          <Grid item xs={12} md={4}>
            <AmountCard title="Assets" amount={assets_total} showResult={dataReady} numberType={'money'} compPeriod={compPeriod} account_ids={account_ids.filter((account_id) => accounts[account_id].account_type_id === 1)} />
          </Grid>
          <Grid item xs={12} md={4}>
            <AmountCard title="Liabilities" amount={liabilities_total} negative={true} showResult={dataReady} numberType={'money'} compPeriod={compPeriod} account_ids={account_ids.filter((account_id) => accounts[account_id].account_type_id === 2)} />
          </Grid>
        </Grid>
      <NetWorthTimeChart space_id={space_id} />
      {!dataReady &&
          <ProgressSpinner />
      }
      {dataReady && 
          <div>
          <Grid container>
            <Grid item xs={12} md={6}>
            {asset_subtype_ids.map(asset_subtype_id => (
              <NetWorthTable 
                key={asset_subtype_id}
                accountTypes={accountTypes}
                subtype_id={asset_subtype_id} 
                accounts={accounts} 
                account_subtypes={asset_subtypes}
                show_closed={showClosed}
                comp_period = {compPeriod} />
              ))} 
            </Grid>
            <Grid item xs={12} md={6}>
              {liability_subtype_ids.map(liability_subtype_id => (
              <NetWorthTable 
                key={liability_subtype_id}
                accountTypes={accountTypes}
                subtype_id={liability_subtype_id} 
                accounts={accounts} 
                account_subtypes={liability_subtypes}
                show_closed={showClosed}
                comp_period = {compPeriod} />
              ))} 
              </Grid>
            </Grid>
          </div>
      }
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const { space_id } = props;



  if (space_id && (!state.spaces || !state.spaces[space_id] || !state.spaceAccounts[space_id] || Object.keys(state.accountTypes).length === 0 || Object.keys(state.accounts).length === 0)) {
    return {
      account_ids: [],
      accounts: {},
      dataReady: false
    }
  }

  let net_worth = 0
  let assets_total = 0
  let liabilities_total = 0
  let account_ids = (space_id ? state.spaceAccounts[space_id] : state.user.account_ids)
      .sort((a,b) => state.accounts[b].latest_balance - state.accounts[a].name.latest_balance )

  let asset_subtypes = {}
  let liability_subtypes = {}

  account_ids.forEach((account_id) => {
    let account = state.accounts[account_id]
    let account_subtype_id = account.account_subtype_id
    let account_type_id = account.account_type_id

    net_worth += account.latest_balance

    if (account_type_id === 1) {
      assets_total += account.latest_balance

      if (!asset_subtypes[account_subtype_id] || !asset_subtypes[account_subtype_id].account_ids) {
        asset_subtypes[account_subtype_id] = {
          account_ids: [],
          sub_category_total: 0
        }
      }
      asset_subtypes[account_subtype_id].account_ids = asset_subtypes[account_subtype_id].account_ids.concat([account_id])
      asset_subtypes[account_subtype_id].sub_category_total += account.latest_balance
      asset_subtypes[account_subtype_id].account_ids.sort((a,b) => state.accounts[b].latest_balance - state.accounts[a].latest_balance)

    } else if (account_type_id === 2) {
      liabilities_total += account.latest_balance

      if (!liability_subtypes[account_subtype_id] || !liability_subtypes[account_subtype_id].account_ids) {
        liability_subtypes[account_subtype_id] = {
          account_ids: [],
          sub_category_total: 0
        }
      }
      liability_subtypes[account_subtype_id].account_ids = liability_subtypes[account_subtype_id].account_ids.concat([account_id])
      liability_subtypes[account_subtype_id].sub_category_total += account.latest_balance
      liability_subtypes[account_subtype_id].account_ids.sort((a,b) => state.accounts[a].latest_balance - state.accounts[b].latest_balance)

    }

  })

  let asset_subtype_ids = Object.keys(asset_subtypes).sort((a,b) => asset_subtypes[b].sub_category_total - asset_subtypes[a].sub_category_total)
  let liability_subtype_ids = Object.keys(liability_subtypes).sort((a,b) => liability_subtypes[a].sub_category_total - liability_subtypes[b].sub_category_total)


  return {
    asset_subtype_ids: asset_subtype_ids,
    liability_subtype_ids: liability_subtype_ids,
    asset_subtypes: asset_subtypes,
    liability_subtypes: liability_subtypes,
    accounts: state.accounts,
    dataReady: true,
    net_worth: net_worth,
    assets_total: assets_total,
    liabilities_total: liabilities_total,
    accountTypes: state.accountTypes,
    account_ids
  }
};

export default connect(mapStateToProps)(NetWorth);