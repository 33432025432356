import { call, put, takeEvery } from 'redux-saga/effects';
import { LOAD_TRANSACTION_RULES,
	RECEIVE_TRANSACTION_RULES } from '../actions/spaceTransactionRules';
import { API_PATH } from '../utilities/apiPath'

function* fetchTransactionRules(action) {
  const token = action.token

  if (token) {
	  const endpoint = API_PATH + 'api/rules';
	  const options = {
		  headers: {
		    Authorization: `Bearer ${token}`
		  }

	  }
	  const response = yield call(fetch, endpoint, options);
	  const data = yield response.json();
	  yield put({ type: RECEIVE_TRANSACTION_RULES, rules: data });
	}
}

export function* loadTransactionRules() {
  yield takeEvery(LOAD_TRANSACTION_RULES, fetchTransactionRules);
}