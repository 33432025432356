import { combineReducers } from 'redux'
import spaces from './spaces'
import user from './user'
import accounts from './accounts'
import pendingTransactions from './pendingTransactions'
import transactions from './transactions'
import transactionCategories from './transactionCategories'
import accountTypes from './accountTypes'
import timeSeries from './timeSeries'
import spaceTransactionCategoryBudgets from './spaceTransactionCategoryBudgets'
import spaceTransactionCategorySpend from './spaceTransactionCategorySpend'
import transactionQueries from './transactionQueries'
import spaceAccounts from './spaceAccounts'
import spacePendingTransactions from './spacePendingTransactions'
import webActions from './webActions'
import transactionSplitTemplates from './transactionSplitTemplates'
import timeSeriesLongTerm from './timeSeriesLongTerm'
import spaceUsers from './spaceUsers'
import users from './users'
import scenarios from './scenarios'
import spaceTransactionRules from './spaceTransactionRules'


export default combineReducers({
  spaces,
  user, 
  accounts,
  pendingTransactions,
  transactions,
  transactionCategories,
  transactionSplitTemplates,
  transactionQueries,
  accountTypes,
  timeSeries,
  timeSeriesLongTerm,
  scenarios,
  spaceTransactionCategoryBudgets,
  spaceTransactionCategorySpend,
  spaceAccounts,
  spacePendingTransactions,
  spaceTransactionRules,
  spaceUsers,
  users,
  webActions,
})