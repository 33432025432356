import React from "react";
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import NumberFormat from 'react-number-format';
import clsx from 'clsx';


const styles = theme => ({
  tightCell: {
    paddingRight: 6,
    paddingLeft: 6,
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    fontSize: "0.75rem"
    
  },
  underBudget: {
    background: "#a5d6a7",
  },
  overBudget: {
    background: "#ef9a9a",
  },
  transactionPopover: {
    maxWidth: "1200px",
  },
  clickable: {
    cursor: "pointer"
  }
});


const BudgetCell = (props) => {
	const { classes } = props;
	const { budget, amount, k, handleInfoClick} = props;

  var class_name = clsx(classes.tightCell)

  if (budget === null ) {
    class_name = clsx(classes.tightCell);
  } else if (amount < budget) {
    class_name = clsx(classes.tightCell,classes.overBudget);
  } else if (amount >= budget ) {
    class_name = clsx(classes.tightCell,classes.underBudget);
  }

  if (handleInfoClick) {
    class_name = clsx(class_name, classes.clickable)
  }


  function handleClick(event) {
    if (handleInfoClick) {
      handleInfoClick(event.currentTarget.id)
    }
  }


	return(
    <TableCell id={k} name={k} className={class_name} align="right" onClick={handleClick}>
      <NumberFormat id={k} value={amount} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0} fixedDecimalScale={true} />
    </TableCell>
	);
};


export default withStyles(styles)(BudgetCell);