import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ProgressSpinner from '../utilities/ProgressSpinner'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
//import { useAuth0 } from "../react-auth0-spa";
import Title from './Title'
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
// import MenuItem from '@material-ui/core/MenuItem';
import CheckIcon from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { connect } from 'react-redux'
import { API_PATH } from '../utilities/apiPath'


const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 600,
  },
  header: {
    paddingBottom: 12,
  },
  cellLessPadding: {
    paddingRight: 24,
  },  
  negativeCell : {
    color: 'red',
  },
  nameInput: {
    width: 300,
  },
  categoryInput: {
    width: 200,
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  checkIcon : {
    color: 'green',
  },
});


const MerchantMapping = (props) => {
	const { classes, space_id } = props;
	const [showResult, setShowResult] = useState(false);
	const [data, setData] = useState([]);
  const nameChangesToSave = {};
  const categoryChangesToSave = {};

  const { transactionCategories, dataReady, user } = props

  let categoryOptions = [];

  Object.keys(transactionCategories).map( transactionCategoryId => ( 
      categoryOptions.push({
        value: transactionCategories[transactionCategoryId].transaction_category_id,
        label: transactionCategories[transactionCategoryId].transaction_category_name
      })
    ))  

  async function submitEdits(original_name) {
    // const new_accounts = [];

    var rules = {};
    rules['original_name'] = original_name;

    const new_data = [];

    data.map(function(element) {
      if (element.original_name === original_name) {
        rules['merchant_name'] = element.merchant_name;
        rules['transaction_category_id'] = element.transaction_category_id;
        element.edit_mode = false;
      }
      new_data.push(element);
      return element;
    });

    // console.log(rules);

    // TODO: send rule to server
    const auth_token = user.accessToken
    fetch(API_PATH + 'api/rule/' + space_id, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth_token}`
      },
      body: JSON.stringify(rules)
    });

    // console.log(new_data)
    setData(new_data);

  }


  useEffect(() => {
    async function getData() {
      setShowResult(false);
      try {
        const token = user.accessToken

        var url = API_PATH + "api/merchantreview"
        if (space_id) {
          url = url + "/" + space_id
        }

        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const responseData = await response.json();
        responseData.map(function(x) {
          x.edit_mode = true;
          return x;
        });

        setData(responseData);
        setShowResult(true);
      } catch (error) {
        console.error(error);
      }

    };
    getData();
  }, [space_id, user]);

  const handleChange = event => {
    const new_data = [];
    data.map(function(element) {
      if (element.original_name === event.target.id) {
        element.merchant_name = event.target.value;
      }
      new_data.push(element);
      return element;
    });
    nameChangesToSave[event.target.id] = event.target.value
    // console.log(nameChangesToSave);
    setData(new_data);
  };

  const handleCatChange = name => value => {
    const new_data = [];
    data.map(function(element) {
      if (element.original_name === name) {
        element.transaction_category_id = value.value;
        element.transaction_category_name = value.label;
      }
      new_data.push(element);
      return element;
    });
    categoryChangesToSave[name] = value.value;
    // console.log(categoryChangesToSave);
    setData(new_data);
  };

	return(
		
			      
			<React.Fragment>
		      <Paper className={classes.paper}>
		      	<Title>Top Merchants</Title>
				{!showResult &&
		        	<ProgressSpinner />
		  		}
		  		{showResult &&
			      <Table className={classes.table} size="small">
			        <TableHead>
			          <TableRow>
			            <TableCell className={classes.cellLessPadding} variant="head" align="left">Merchant Name</TableCell>
			            <TableCell className={classes.cellLessPadding} variant="head" align="left">Original Name</TableCell>
			            <TableCell className={classes.cellLessPadding} variant="head" align="left">Category</TableCell>
			            <TableCell className={classes.cellLessPadding} variant="head" align="left">Number of Transactions</TableCell>
                   <TableCell className={classes.cellLessPadding} variant="head" align="left"></TableCell>
			          </TableRow>
			        </TableHead>
			        <TableBody>
			          {data.map(element => (
			            <TableRow key={element.original_name} >
			              <TableCell className={classes.cellLessPadding} align="left">
                    
                    <TextField 
                      className={classes.nameInput} 
                      id={element.original_name} 
                      label="" 
                      defaultValue={element.merchant_name}
                      onChange={handleChange}
                    />
                    </TableCell>
			              <TableCell className={classes.cellLessPadding} align="left">{element.original_name}</TableCell>
			              <TableCell className={classes.cellLessPadding} align="left">
                      <Select
                        className={classes.categoryInput}
                        id={element.original_name}
                        value={{value: element.transaction_category_id, label: element.transaction_category_name}}
                        // defaultValue={element.transaction_category_id}
                        onChange={handleCatChange(element.original_name)}
                        options={transactionCategories}
                        >

                      </Select>
                    </TableCell>
			              <TableCell className={classes.cellLessPadding} align="left">{element.num_transactions}</TableCell>
                    <TableCell className={classes.cellLessPadding} align="left">
                      {!element.edit_mode &&
                        <CheckCircleIcon className={classes.checkIcon}/>
                      }
                      {element.edit_mode &&
                      <IconButton aria-label="fix-error" onClick={() => submitEdits(element.original_name)}>
                        <CheckIcon />
                      </IconButton>
                      }
                    </TableCell>
			            </TableRow>
			          ))}
			        </TableBody>
			      </Table>
			    } 
			  </Paper>
			</React.Fragment>
	);
};

const mapStateToProps = (state, props) => {
  const { space_id } = props;

  if (!space_id || !state.user || !state.transactionCategories ) {
    return {
      dataReady: false
    }
  }

  return {
    transactionCategories: state.transactionCategories,
    user: state.user,
    dataReady: true,
  }
};



export default connect(mapStateToProps)(withStyles(styles)(MerchantMapping));