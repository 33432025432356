
export const LOAD_SCENARIOS = 'LOAD_SCENARIOS'
export const RECEIVE_SCENARIOS = 'RECEIVE_SCENARIOS'
export const ADD_SCENARIO = 'ADD_SCENARIO'
export const ADD_SCENARIO_SUCCEEDED = 'ADD_SCENARIO_SUCCEEDED'
export const UPDATE_SCENARIO = 'UPDATE_SCENARIO'
export const UPDATE_SCENARIO_SUCCEEDED = 'UPDATE_SCENARIO_SUCCEEDED'
export const UPDATE_SCENARIO_FAILED = 'UPDATE_SCENARIO_SUCCEEDED'
export const SET_DEFAULT_SPACE_SCENARIO = 'SET_DEFAULT_SPACE_SCENARIO'


export function addScenario (scenario, token) {
	return {
		type: ADD_SCENARIO,
		scenario,
		token
	}
}

export function updateScenario (updatedScenario, scenario, token) {
	return {
		type: UPDATE_SCENARIO,
		updatedScenario,
		scenario,
		token
	}
}

export function setDefaultScenario (scenario_id, space_id, token) {
	return {
		type: SET_DEFAULT_SPACE_SCENARIO,
		scenario_id,
		space_id,
		token
	}	
}
