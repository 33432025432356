import { all } from 'redux-saga/effects';

import { loadSpaces } from "./spaces"
import { loadAccounts, updateAccount, repairItem, refreshItem, loadAccount, refreshAccount, addAccount, deleteAccount } from "./accounts"
import { loadUserAppData, updateUserApp } from "./user"
import { loadPendingTransactions } from "./pendingTransactions"
import { loadTransactions, updateTransaction, updateTransactionResetBudget, splitTransaction } from "./transactions"
import { loadTransactionCategories } from "./transactionCategories"
import { loadAccountTypes } from './accountTypes'
import { loadBalanceTimeSeries } from './timeSeries'
import { loadBudgets, updateBudgets } from './spaceTransactionCategoryBudgets'
import { loadTransactionCategorySpend } from './spaceTransactionCategorySpend'
import { loadUserFinancialData } from './shared'
import { loadTransactionQuery, requestTransactionQuery } from './transactionQueries'
import { loadTransactionSplitTemplates } from './transactionSplitTemplates'
import { loadBalanceTimeSeriesLongTerm } from './timeSeriesLongTerm'
import { loadUsers } from './users'
import { loadScenarios, addScenario, updateScenario, setDefaultScenario } from './scenarios'
import { loadTransactionRules } from './spaceTransactionRules'

// replace the current rootSaga generator
export default function* rootSaga() {
  yield all([
  	loadSpaces(), 
  	loadAccounts(), 
  	updateAccount(),
  	loadUserAppData(),
  	loadPendingTransactions(),
  	loadTransactions(),
  	loadTransactionCategories(),
  	updateTransaction(),
    loadAccountTypes(),
    loadBalanceTimeSeries(),
    loadBudgets(),
    loadTransactionCategorySpend(),
    updateBudgets(),
    repairItem(),
    refreshItem(),
    loadAccount(),
    refreshAccount(),
    loadUserFinancialData(),
    loadTransactionQuery(),
    requestTransactionQuery(),
    updateTransactionResetBudget(),
    addAccount(),
    deleteAccount(),
    splitTransaction(),
    loadTransactionSplitTemplates(),
    loadBalanceTimeSeriesLongTerm(),
    loadUsers(),
    updateUserApp(),
    loadScenarios(),
    addScenario(),
    updateScenario(),
    loadTransactionRules(),
    setDefaultScenario(),
  	]);
}