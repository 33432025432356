import { RECEIVE_TRANSACTIONS, UPDATE_TRANSACTION_FAILED, UPDATE_TRANSACTION, SPLIT_TRANSACTION_SUCCEEDED } from '../actions/transactions'
import { RECEIVE_TRANSACTION_QUERY} from '../actions/transactionQueries'

export default function transactions (state = {}, action) {
  let newTransactions = {}
  switch(action.type) {
    case RECEIVE_TRANSACTIONS :

      action.transactionResponse.transactions
        .map((transaction) => newTransactions[transaction.transaction_id] = transaction) 
      return {
        ...newTransactions
      }

    case RECEIVE_TRANSACTION_QUERY :
      
      action.transactionResponse.transactions
        .map((transaction) => newTransactions[transaction.transaction_id] = transaction) 
      return {
        ...state,
        ...newTransactions
      }      

    case UPDATE_TRANSACTION :
      return {
        ...state,
        [action.transaction.transaction_id] : {
          ...action.updatedTransaction,
          is_reviewed: true
        }
      }

    case UPDATE_TRANSACTION_FAILED :
      return {
        ...state,
        [action.transaction.transaction_id] : {
          ...action.transaction
        }
      }

    case SPLIT_TRANSACTION_SUCCEEDED :
      action.newTransactions
        .map((transaction) => newTransactions[transaction.transaction_id] = transaction) 
      var existing_transactions = {...state}
      delete existing_transactions[action.transaction_id]

      return {
        ...existing_transactions,
        ...newTransactions
      }      

    default :
      return state
  }
}