export const labelFormater = (number) => {
	var adjustedNumber = number;
	var postFixString = ''
  if(number > 1000000000){
    adjustedNumber = (number/1000000000);
    postFixString = 'B';
  }else if(number > 1000000){
    adjustedNumber = (number/1000000);
    postFixString = 'M';    
  }else if(number > 1000){
    adjustedNumber = (number/1000);
    postFixString = 'K';        
  }
   return new Intl.NumberFormat('en', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(adjustedNumber) + postFixString
}