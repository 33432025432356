import { call, put, takeEvery } from 'redux-saga/effects';
import { LOAD_TRANSACTION_CATEGORY_SPEND,
	RECEIVE_TRANSACTION_CATEGORY_SPEND } from '../actions/spaceTransactionCategorySpend';
import { API_PATH } from '../utilities/apiPath'

function* fetchTransactionCategorySpend(action) {
  const token = action.token

  if (token) {
	  const endpoint = API_PATH + 'api/categoryspendforuser';
	  const options = {
		  headers: {
		    Authorization: `Bearer ${token}`
		  }

	  }
	  const response = yield call(fetch, endpoint, options);
	  const data = yield response.json();
	  yield put({ type: RECEIVE_TRANSACTION_CATEGORY_SPEND, transactionCategorySpend: data });
	}
}

export function* loadTransactionCategorySpend() {
  yield takeEvery(LOAD_TRANSACTION_CATEGORY_SPEND, fetchTransactionCategorySpend);
}