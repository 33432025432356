
export const LOAD_TRANSACTIONS = 'LOAD_TRANSACTIONS'
export const RECEIVE_TRANSACTIONS = 'RECEIVE_TRANSACTIONS'
export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION'
export const UPDATE_TRANSACTION_SUCCEEDED = 'UPDATE_TRANSACTION_SUCCEEDED'
export const UPDATE_TRANSACTION_FAILED = 'UPDATE_TRANSACTION_FAILED'
export const SPLIT_TRANSACTION = 'SPLIT_TRANSACTION'
export const SPLIT_TRANSACTION_SUCCEEDED = 'SPLIT_TRANSACTION_SUCCEEDED'
export const SPLIT_TRANSACTION_FAILED = 'SPLIT_TRANSACTION_FAILED'

export function receiveTransactions (transactionResponse) {
	return {
		type: RECEIVE_TRANSACTIONS,
		transactionResponse
	}
}

export function loadTransactions () {
	return {
		type: LOAD_TRANSACTIONS
	}
}

export function updateTransaction (transaction, updatedTransaction, token) {
	return {
		type: UPDATE_TRANSACTION,
		transaction,
		updatedTransaction,
		token
	}
}

export function splitTransaction (transaction_id, splitTransactions, token) {
	return {
		type: SPLIT_TRANSACTION,
		transaction_id,
		splitTransactions,
		token
	}
}