import React from "react";
import NetWorth from '../components/NetWorth'

const NetWorthView = (props) => {

	const { space_id } = props.match ? props.match.params : {space_id: null};


	return (
	  <div>
	  	<NetWorth space_id={space_id} />
	  </div>
	)
}


export default NetWorthView;