import { call, put, takeEvery , select } from 'redux-saga/effects';
import { LOAD_USERS, 
	RECEIVE_USERS, } from '../actions/users';
import { API_PATH } from '../utilities/apiPath'

const getUser = state => state.user;

function* fetchUsers(action) {
  const token = action.token
  const user = yield select(getUser);

  if (token) {
	  const endpoint = API_PATH + 'api/usersforuser';
	  const options = {
		  headers: {
		    Authorization: `Bearer ${token}`
		  }

	  }
	  const response = yield call(fetch, endpoint, options);
	  const data = yield response.json();
	  yield put({ type: RECEIVE_USERS, users: data, user_id:user.userApp.user_id });
	}
}

export function* loadUsers() {
  yield takeEvery(LOAD_USERS, fetchUsers);
}

