import { RECEIVE_BUDGETS, UPDATE_BUDGETS, UPDATE_BUDGETS_FAILED } from '../actions/spaceTransactionCategoryBudgets'

export default function spaceTransactionCategoryBudgets (state = {}, action) {

  let new_budgets = {...state}

  switch(action.type) {

    case RECEIVE_BUDGETS :

      let budgets = {}

      Object.keys(action.spaceBudgets).forEach((id) => {
      	budgets[action.spaceBudgets[id].space_id] = budgets[action.spaceBudgets[id].space_id] ? budgets[action.spaceBudgets[id].space_id] : {}
      	budgets[action.spaceBudgets[id].space_id][action.spaceBudgets[id].transaction_category_id] = action.spaceBudgets[id].budget
      })
      return {...budgets}

    case UPDATE_BUDGETS :

      new_budgets[action.space_id] = {...action.updatedTransactionCategoryBudgets}
      return new_budgets

    case UPDATE_BUDGETS_FAILED :

      new_budgets[action.space_id] = {...action.transactionCategoryBudgets}
      return new_budgets
      
    default :
      return state
  }
}