import React from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ProgressSpinner from '../utilities/ProgressSpinner'
import { withStyles } from '@material-ui/core/styles';
import Title from '../components/Title';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux'
import AccountRow from '../components/AccountRow'
import { repairItem, refreshItem } from '../actions/accounts'
import { useAuth0 } from "@auth0/auth0-react";
import { API_PATH } from '../utilities/apiPath'
import AddAccount from '../components/AddAccount'
import { filterAccounts } from '../utilities/accountFilters'
import Hidden from '@material-ui/core/Hidden';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    //minWidth: 600,
  },
  header: {
    paddingBottom: 12,
  },
  cellLessPadding: {
    paddingRight: 8,
    paddingLeft: 8,
  },
  logo : {
    width: 24,
  },
  negativeCell : {
    color: 'red',
  },
  plaidButton : {
    color: '#fff',
    backgroundColor: '#009be5',
    lineHeight: 1.75,
    fontWeight: 500
  },
  checkIcon : {
    color: 'green',
  },
  errorIcon : {
    color: 'red',
  },
  nameInput: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    // [theme.breakpoints.down('sm')]: {
    //   padding: theme.spacing(1),
    //   margin: theme.spacing(1),
    // },
  },
  iconCell: {
    paddingRight: 24,
    paddingTop: 0,
    paddingBottom: 0,
  },  
  avatar : {
    width: 24,
    height: 24,
    backgroundColor: 'teal',
    fontSize: '1rem',
  },
  userAvatar : {
    width: 24,
    height: 24,
    fontSize: '1rem',
    marginLeft: 8,
  },
  avatarCell: {
    paddingRight: 0,
    paddingLeft: 4,
  },
});


const Accounts = (props) => {
  const { classes } = props;
  // const { space_id } = props.match ? props.match.params : {space_id: null};
  const { account_ids, dataReady, user, dispatch, edit_mode_default, space_id, show_spaces, hide_if_empty } = props
  const { getAccessTokenSilently } = useAuth0();
  const { title, show_add } = props;


  const handleOnExit = () => {
    // handle the case when your user exits Link
    }


   const repairSuccess = async (item_id) => {
    const token = await getAccessTokenSilently();
    dispatch(repairItem(item_id, token))
  } 


  const fetchLinkToken = async (item_id) => {
    const auth_token = await getAccessTokenSilently();
    const response = await fetch(API_PATH + 'plaid/link_token_create', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth_token}`
      },
      body: JSON.stringify({
        item_id: item_id,
      })
    });
    const responseJSON = await response.json();
    return responseJSON.link_token;
  };


  const plaidRepairToken = async (token, item_id) => {
     // 1. Pass a new link_token to Link.
    const linkHandler = window.Plaid.create({
    token: token,
    onSuccess: () => repairSuccess(item_id),
    onExit: handleOnExit,
    // receivedRedirectUri: window.location.href,
  })
    linkHandler.open();   
  }




  const onRepairClick = async (item_id) => {
    const token =  await fetchLinkToken(item_id)
    await plaidRepairToken(token, item_id)
  }

  const updateItem = async (item_id) => {
    const token = await getAccessTokenSilently();
    dispatch(refreshItem(item_id, token))
  }      

  if (hide_if_empty && dataReady && account_ids.length === 0) {
    return(
      <div />
    )
  }

  return (
    <div>
      <Paper className={classes.paper}>
        <Grid container justify="space-between">
          <Grid item >
            <Title>{title}</Title>
          </Grid>
          {show_add && 
            <Grid item >
              <AddAccount />
            </Grid>
          }
        </Grid>

        {!dataReady &&
          <ProgressSpinner />
        }
        {dataReady && account_ids.length === 0 &&
          <div>No accounts.</div>
        }
        {dataReady && account_ids.length > 0 &&
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <Hidden xsDown>
                  <TableCell className={classes.cellLessPadding} variant="head" align="left">Inst.</TableCell>
                </Hidden>
                <TableCell className={classes.cellLessPadding} variant="head" align="left">Name</TableCell>
                {!space_id && show_spaces &&
                  <TableCell className={classes.cellLessPadding} variant="head" align="left">Include in Workspace</TableCell>
                }                
                <TableCell className={classes.cellLessPadding} variant="head" align="right">Balance</TableCell>
                <TableCell className={classes.cellLessPadding} variant="head" align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {account_ids.map(account_id => (
                <AccountRow 
                  account_id={account_id} 
                  key={account_id} 
                  space_id={space_id}
                  onRepair={onRepairClick} 
                  user_id={user.userApp.user_id}
                  updateItem={updateItem}
                  edit_mode_default={edit_mode_default}
                  show_spaces={show_spaces} />
              ))}
            </TableBody>
          </Table>
        }

      </Paper>
    </div>
  );
};

Accounts.defaultProps = {
  title: "Accounts", 
  show_add: true,
  edit_mode_default: false,
  show_spaces: true,
  filters: {
    is_deleted: false,
  },
}

const mapStateToProps = (state, props) => {
  // const { space_id } = props.match ? props.match.params : {space_id: null};
  const { account_ids_list, filters, space_id } = props

  var account_filters = filters ? filters : { is_deleted: false }

  if (!space_id && (!state.user || !state.user.account_ids)) {
    return {
      account_ids: [],
      dataReady: false
    }
  }

  if (space_id && (!state.spaceAccounts || !state.spaceAccounts[space_id])) {
    return {
      account_ids: [],
      dataReady: false
    }
  }

  if (!state.accountTypes || Object.keys(state.accountTypes).length === 0 || !state.spaces || Object.keys(state.spaces).length === 0) {
    return {
      account_ids: [],
      dataReady: false
    }    
  }

  let account_ids = account_ids_list ? account_ids_list : (space_id ? state.spaceAccounts[space_id] : state.user.account_ids)

  account_ids = filterAccounts(account_ids, state.accounts, account_filters)


  return {
    account_ids: account_ids,
    user: state.user,
    dataReady: true
  }
};


export default connect(mapStateToProps)(withStyles(styles)(Accounts));