import { ADD_ACCOUNT,
  ADD_ACCOUNT_SUCCEEDED,
  ADD_ACCOUNT_FAILED,
  ADD_ACCOUNT_CLEAR } from '../actions/accounts'

export default function webActions (state = {}, action) {

  switch(action.type) {
    case ADD_ACCOUNT :
      return {
        ...state,
        add_account: "pending"
      }

    case ADD_ACCOUNT_SUCCEEDED :
      return {
        ...state,
        add_account: "done",
        last_added_account_id: action.account.account_id
      }

    case ADD_ACCOUNT_FAILED :
      return {
        ...state,
        add_account: "failed"
      }

    case ADD_ACCOUNT_CLEAR :
      return {
        ...state,
        add_account: null,
        last_added_account_id: null
      }

    default :
      return state
  }
}