import { RECEIVE_SCENARIOS, ADD_SCENARIO_SUCCEEDED, UPDATE_SCENARIO, UPDATE_SCENARIO_FAILED, UPDATE_SCENARIO_SUCCEEDED, SET_DEFAULT_SPACE_SCENARIO } from '../actions/scenarios'

export default function scenarios (state = {}, action) {

  switch(action.type) {
    case RECEIVE_SCENARIOS :

      let scenarios = {}
      action.scenarios.map((scenario) => scenarios[scenario.scenario_id] = scenario) 
      return {
        ...scenarios
      }

    case ADD_SCENARIO_SUCCEEDED :

      return {
        ...state,
        [action.scenario.scenario_id]: {
          ...action.scenario
        }
      }

    case UPDATE_SCENARIO :

      return {
        ...state,
        [action.scenario.scenario_id]: {
          ...action.updatedScenario
        }
      }

    case UPDATE_SCENARIO_SUCCEEDED :

      return {
        ...state,
        [action.scenario.scenario_id]: {
          ...action.scenario,
          update_loading: false
        }
      }     

    case UPDATE_SCENARIO_FAILED :

      return {
        ...state,
        [action.scenario.scenario_id]: {
          ...action.scenario
        }
      }   


    case SET_DEFAULT_SPACE_SCENARIO:


      let newState = {}
      Object.keys(state).forEach((scenario_id) => {
        newState[scenario_id] = {...state[scenario_id]}
        if (Number(scenario_id) === Number(action.scenario_id)) {
          newState[scenario_id].space_default = true
        } else if (newState[scenario_id].space_id === Number(action.space_id)) {
          newState[scenario_id].space_default = false
        }
      }) 
      return {
        ...newState
      }

      
    default :
      return state
  }
}