import moment from 'moment'

export const filterAccounts = (account_ids, accounts, filters) => {

    if (filters) {
        Object.keys(filters).forEach((filter_name) => {
        	if (filter_name === 'is_deleted') {
        		account_ids = account_ids.filter((account_id) => accounts[account_id].is_deleted === filters[filter_name])
        	} else if (filter_name === 'is_reviewed') {
        		account_ids = account_ids.filter((account_id) => accounts[account_id].is_reviewed === filters[filter_name])
        	} else if (filter_name === 'error_state') {
        		account_ids = account_ids.filter((account_id) => accounts[account_id].plaid_account && accounts[account_id].plaid_account.plaid_item.error_state === filters[filter_name])
            } else if (filter_name === 'last_updated') {
                account_ids = account_ids.filter((account_id) => moment(accounts[account_id].balance_last_updated) <= moment(filters[filter_name]))
            } else if (filter_name === 'is_closed') {
                if (filters[filter_name]) {
                    account_ids = account_ids.filter((account_id) => accounts[account_id].closed_date)
                } else {
                    account_ids = account_ids.filter((account_id) => !accounts[account_id].closed_date)
                }
            } else { 
        		account_ids = account_ids.filter((account_id) => accounts[account_id][filter_name] === filters[filter_name])
        	}
        })
    }
    
    return account_ids.sort((a,b) => accounts[b].name.toUpperCase() > accounts[a].name.toUpperCase() ? -1 : 1 )

}
