import React from "react";
import Grid from '@material-ui/core/Grid';
import BalanceTimeChart from '../components/BalanceTimeChart';
import AccountSummaryCard from '../components/AccountSummaryCard';
import AddAccountData from '../components/AddAccountData'
import moment from 'moment'
import SearchableTransactions from '../components/SearchableTransactions'


const AccountDetail = (props) => {
  // const { space_id } = props.match.params;
  const { account_id } = props.match.params;

  var start_date = moment().subtract(30,'d').format('YYYY-MM-DD');
  const transaction_filters = {
    start_date: start_date,
    limit: 100,
    account_id: account_id,
  };

  return (
    <div>
      <Grid container alignItems='center'>
        <Grid item xs={12} sm={6}>
          <AccountSummaryCard account_id={account_id}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <AddAccountData account_id={account_id} />
        </Grid>

      </Grid>
      <BalanceTimeChart account_id={account_id}/>
      <SearchableTransactions title="Account Transactions" edit_mode_default={false} show_cards={false} initialFilters={transaction_filters} />          
    </div>
  );
};

export default AccountDetail;