
import { RECEIVE_PENDING_TRANSACTIONS } from '../actions/pendingTransactions'

export default function spacePendingTransactions (state = {}, action) {

  let spaceTransactions = {}

  switch(action.type) {

    case RECEIVE_PENDING_TRANSACTIONS :

      spaceTransactions = {}
      action.pendingTransactionResponse.transactions.map((pendingTransaction) => pendingTransaction.account.space_accounts
        .map((s) => s.space_id)
        .map((space_id) => spaceTransactions[space_id] = spaceTransactions[space_id]
          ? spaceTransactions[space_id].concat(pendingTransaction.pending_transaction_id)
          : [pendingTransaction.pending_transaction_id]
        ))

      return spaceTransactions

    default :
      return state
  }
}