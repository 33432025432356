
export const LOAD_TRANSACTION_QUERY = 'LOAD_TRANSACTION_QUERY'
export const RECEIVE_TRANSACTION_QUERY = 'RECEIVE_TRANSACTION_QUERY'
export const REQUEST_TRANSACTION_QUERY = 'REQUEST_TRANSACTION_QUERY'

export function loadTransactionQuery (queryParams, token) {
	return {
		type: LOAD_TRANSACTION_QUERY,
		queryParams,
		token
	}
}

export function requestTransactionQuery (queryParams, token) {
	return {
		type: REQUEST_TRANSACTION_QUERY,
		queryParams,
		token
	}
}

