import { RECEIVE_SPACES } from '../actions/spaces'

export default function spaces (state = {}, action) {

  switch(action.type) {
    case RECEIVE_SPACES :
      let newSpaces = {}
      action.spaces.map((space) => newSpaces[space.space_id] = space) 
      return {
        ...state,
        ...newSpaces
      }

    default :
      return state
  }
}