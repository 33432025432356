import React from "react";
// import { useState, useEffect } from "react";
// import { useAuth0 } from "../react-auth0-spa";
import { withStyles } from '@material-ui/core/styles';
import MerchantMapping from '../components/MerchantMapping';
import AddRule from '../components/AddRule';

const styles = theme => ({

});

const TransactionRules = (props) => {
  // const { classes } = props;
  const { space_id } = props.match.params;


  return (
    <div>
    	<AddRule space_id={space_id}/>
      <MerchantMapping space_id={space_id}/>
    </div>
  );
};

export default withStyles(styles)(TransactionRules);