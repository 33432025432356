import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Title from './Title';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import ProgressSpinner from '../utilities/ProgressSpinner'
import PriorBalanceComparison from '../components/PriorBalanceComparison'


const styles = theme => ({
  negativeCell : {
    color: 'red',
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    minWidth: 200,
  },
  alignRight: {
    textAlign: "right"
  }  
});

const AmountCard = (props) => {
  const { classes, title, amount, negative, showResult, numberType, account_ids, compPeriod} = props;

  return (
    <React.Fragment>
    <Paper className={classes.paper}>
      <Title>{title}</Title>
            {!showResult &&
          <ProgressSpinner />
      }
      {showResult && 
        <span >
      <Typography component="p" variant="h4">
        { !negative  && numberType==='money' &&
        <NumberFormat value={amount} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
        }
        { negative  && numberType==='money' &&
        <NumberFormat className={classes.negativeCell} value={amount} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
        }
        { numberType==='count' &&
          <NumberFormat value={amount} displayType={'text'} thousandSeparator={true} decimalScale={0} fixedDecimalScale={true} />
        }
        { numberType==='percent' &&
          <NumberFormat value={amount} displayType={'text'} thousandSeparator={true} suffix={'%'} decimalScale={1} fixedDecimalScale={true} />
        }
      </Typography>
              {compPeriod > -1 &&
                <PriorBalanceComparison days={compPeriod} account_ids={account_ids} />
              }
      </span>
      }
    </Paper>
    </React.Fragment>
  );
}

export default withStyles(styles)(AmountCard);