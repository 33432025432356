import React, { useState, Fragment } from "react";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Title from '../components/Title';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ScenarioDetailSpending from '../components/ScenarioDetailSpending'
import ScenarioDetailIncome from '../components/ScenarioDetailIncome'
import ScenarioDetailReturns from '../components/ScenarioDetailReturns'
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import { updateScenario, setDefaultScenario } from '../actions/scenarios'
import { connect } from 'react-redux'
import { useAuth0 } from "@auth0/auth0-react";
import TextField from '@material-ui/core/TextField';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ScenarioDetailTimeChart from '../components/ScenarioDetailTimeChart'
import ScenarioDetailCashflowChart from '../components/ScenarioDetailCashflowChart'
import ProgressSpinner from '../utilities/ProgressSpinner'
import Button from '@material-ui/core/Button';


const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  iconMargin: {
    marginBottom: "0.35em",
  },  
  titleInput: {
    fontSize: "1.25rem",
    fontWeight: "500",
    lineHeight: "1.6",
    letterSpacing: "0.0075em",
    color: "#009be5"
  },
  defaultButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
});


const ScenarioDetail = (props) => {

	const { scenario, classes, space_id, dispatch } = props
	const [expanded, setExpanded] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [editMode, setEditMode] = React.useState(false);
  const [updatedScenario, setUpdatedScenario] = React.useState(scenario)
  const { getAccessTokenSilently } = useAuth0();

  const handleTabsChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChange = (component, newValue) => {

    let newScenario = {
        ...updatedScenario
      }

    if(component === "inflation_yearly" || component === "scenario_name") {
        newScenario[component]=  newValue
    }
    else {
      newScenario[component] =  [...newValue]
    }

    setUpdatedScenario(newScenario)

  }

  const handleNameChange = (event) => {
    const {name, value} = event.target
    handleChange(name, value)
  }

    async function submitEdits() {

      var newScenario = {...updatedScenario}
      newScenario.scenario_calculation_balances = []
      newScenario.scenario_calculation_cashflow = []
      newScenario.update_loading = true
      setUpdatedScenario(newScenario)

    const token = await getAccessTokenSilently();
    await dispatch(updateScenario(newScenario, scenario, token))
    setEditMode(false)
  }

  async function  deleteScenario() {

    let newScenario = {
        ...updatedScenario,
        is_deleted: true
      }

    setUpdatedScenario(newScenario)

    const token = await getAccessTokenSilently();
    await dispatch(updateScenario(newScenario, scenario, token))

  }

  async function setAsDefault() {
    const token = await getAccessTokenSilently();
    await dispatch(setDefaultScenario(scenario.scenario_id, space_id, token))
    setUpdatedScenario(scenario)

  }



	return (
	  <div>
	  	<Paper className={classes.paper}>
          <Grid container item alignItems="center" justify="flex-start" >
            <Grid item >
              {!editMode &&
              <Title>{scenario.scenario_name}</Title>
              }
              {editMode &&
                <TextField
                  InputProps={{
                    classes: {
                      input: classes.titleInput,
                    },
                  }}
                  type="text"
                  // id="income_name"
                  name="scenario_name"
                  className={classes.titleInput}
                  defaultValue={scenario.scenario_name}
                  onChange={(event) => handleNameChange(event)}
                />
              }
            </Grid>
            <Grid item>
                {!expanded && 
                <IconButton className={classes.iconMargin} onClick={() => setExpanded(true)}>
                    <ExpandMoreIcon fontSize="small"/>
                </IconButton>   
              }
                {expanded && 
                <IconButton className={classes.iconMargin} onClick={() => setExpanded(false)}>
                    <ExpandLessIcon fontSize="small"/>
                </IconButton>   
              }
            </Grid>
          {expanded && 
            <Fragment>
            <Grid item>
            <Tabs
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabsChange}
            >
              <Tab label="Income" />
              <Tab label="Spending" />
              <Tab label="Returns & Cashflow" />
              <Tab label="Cashflow Chart" />
              <Tab label="Net Worth Chart" />
            </Tabs> 
            </Grid>
            <Grid item>
                {!editMode && 
                <IconButton className={classes.iconMargin} onClick={() => setEditMode(true)}>
                    <EditIcon fontSize="small"/>
                </IconButton>   
              }
                {editMode && 
                <IconButton className={classes.iconMargin} onClick={() => submitEdits()}>
                  <DoneIcon fontSize="small" />
                </IconButton>
                }
            </Grid>
            <Grid item>
                <IconButton className={classes.iconMargin} onClick={() => deleteScenario()}>
                    <DeleteOutlineIcon fontSize="small"/>
                </IconButton>   
            </Grid>
            {scenario.update_loading &&
            <Grid item>
                <ProgressSpinner />
            </Grid>

            }
            </Fragment>
            }           
          </Grid>
          {expanded && 
          	<Fragment>
            {tabValue === 0 && <ScenarioDetailIncome scenario={updatedScenario} space_id={space_id} editMode={editMode} updateScenario={handleChange} />}
            {tabValue === 1 && <ScenarioDetailSpending scenario={updatedScenario} space_id={space_id} editMode={editMode} updateScenario={handleChange} />}
            {tabValue === 2 && <ScenarioDetailReturns scenario={updatedScenario} space_id={space_id} editMode={editMode} updateScenario={handleChange} />}
            {tabValue === 3 && <ScenarioDetailCashflowChart scenario_id={scenario.scenario_id} space_id={space_id} />}
            {tabValue === 4 && <ScenarioDetailTimeChart scenario_id={scenario.scenario_id} space_id={space_id} />}
            <div className={classes.defaultButton}>
              <Button 
                color="secondary" 
                // variant="contained"
                disabled={scenario.space_default}
                onClick = {() => setAsDefault()}
                >Set As Default Scenario</Button>
            </div>
          	</Fragment>
          }

	  	</Paper>
	  </div>
	)
}

export default connect()(withStyles(styles)(ScenarioDetail));