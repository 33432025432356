import { all, call, put, takeEvery  } from 'redux-saga/effects';
import { LOAD_SPACES } from '../actions/spaces';
import { LOAD_USER_APP_DATA, RECEIVE_USER_APP_DATA, UPDATE_USER_APP_DATA, UPDATE_USER_APP_DATA_FAILED } from '../actions/user'
import { LOAD_TRANSACTION_CATEGORIES } from '../actions/transactionCategories'
import { LOAD_ACCOUNT_TYPES } from '../actions/accountTypes'
import { LOAD_BUDGETS } from '../actions/spaceTransactionCategoryBudgets'
import { LOAD_TRANSACTION_CATEGORY_SPEND } from '../actions/spaceTransactionCategorySpend';
import { LOAD_BALANCE_TIME_SERIES } from '../actions/timeSeries';
import { LOAD_PENDING_TRANSACTIONS } from '../actions/pendingTransactions'
import { LOAD_ACCOUNTS } from '../actions/accounts';
import { API_PATH } from '../utilities/apiPath'
import { LOAD_TRANSACTION_SPLIT_TEMPLATES } from '../actions/transactionSplitTemplates'
import { LOAD_BALANCE_TIME_SERIES_LONG_TERM } from '../actions/timeSeriesLongTerm';
import { LOAD_USERS } from '../actions/users'
import { LOAD_SCENARIOS } from '../actions/scenarios'
import { LOAD_TRANSACTION_RULES } from '../actions/spaceTransactionRules'



// import { takeLatest } from 'redux-saga/effects';
// import { HANDLE_AUTHENTICATION_CALLBACK, USER_PROFILE_LOADED } from '../actions/user';
// import { handleAuthentication } from '../utilities/Auth';


// function* parseHash() {
//   const user = yield call(handleAuthentication);
//   yield all([
//   	put({ type: USER_PROFILE_LOADED, user }),
//   	put({ type: LOAD_USER_APP_DATA }),
//   	put({ type: LOAD_TRANSACTION_CATEGORIES }),
//   	put({ type: LOAD_ACCOUNT_TYPES }),
//   	put({ type: LOAD_BUDGETS }),
//     put({ type: LOAD_TRANSACTION_CATEGORY_SPEND }),
//   	])
// }

// export function* handleAuthenticationCallback() {
//   yield takeLatest(HANDLE_AUTHENTICATION_CALLBACK, parseHash);
// }


function* fetchUserAppData(action) {
  const token = action.token

  if (token) {
	  const endpoint = API_PATH + 'api/user';

		const options = {
			method: 'PUT',
	      headers: {
	      	'Accept': 'application/json',
	      	'Content-Type': 'application/json',
	         'Authorization': `Bearer ${token}`
	      },
	      body: JSON.stringify(action.user)
		}

	  const response = yield call(fetch, endpoint, options);
	  const data = yield response.json();
	  yield all([
	  	put({ type: RECEIVE_USER_APP_DATA, user: data }),
	  	put({ type: LOAD_SPACES, token }),
      put({ type: LOAD_TRANSACTION_CATEGORIES, token }),
      put({ type: LOAD_TRANSACTION_SPLIT_TEMPLATES, token }),
      put({ type: LOAD_ACCOUNT_TYPES, token }),
      put({ type: LOAD_BUDGETS, token }),
      put({ type: LOAD_TRANSACTION_CATEGORY_SPEND, token }),
      put({ type: LOAD_PENDING_TRANSACTIONS, token }),
  	  put({ type: LOAD_BALANCE_TIME_SERIES, token }),
  	  put({ type: LOAD_BALANCE_TIME_SERIES_LONG_TERM, token }),
  	  put({ type: LOAD_ACCOUNTS, token }),
  	  put({ type: LOAD_USERS, token }),
  	  put({ type: LOAD_SCENARIOS, token }),
  	  put({ type: LOAD_TRANSACTION_RULES, token }),
	  	])
	}
}

export function* loadUserAppData() {
  yield takeEvery(LOAD_USER_APP_DATA, fetchUserAppData);
}


function* putUpdateUserApp(action) {
	//const user = yield select(getUser);
	const token = action.token

	if (token) {
		const endpoint = API_PATH + 'api/user/' + action.user.user_id
		const options = {
			method: 'PUT',
	        headers: {
	          'Accept': 'application/json',
	          'Content-Type': 'application/json',
	          'Authorization': `Bearer ${token}`
	        },
	        body: JSON.stringify(action.updatedUser)
		}
		try {
			yield call(fetch, endpoint, options);
		}
		catch {
			yield put({ type: UPDATE_USER_APP_DATA_FAILED, account: action.user });
		}
		
	  	
	  	
	}

}

export function* updateUserApp() {
  yield takeEvery(UPDATE_USER_APP_DATA, putUpdateUserApp);
}