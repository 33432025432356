import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useAuth0 } from "@auth0/auth0-react";
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import { addAccount, addAccountClear } from '../actions/accounts'
import InputLabel from '@material-ui/core/InputLabel';
import ProgressSpinner from '../utilities/ProgressSpinner'

const styles = theme => ({
  nameInput: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  categoryInput: {
    width: 300,
    marginBottom: theme.spacing(3),    
  },
  spaceInput: {
    width: '100%',
    marginBottom: theme.spacing(3),    
  },
  container: {
    width: '100%',
  }
});

const AddOfflineAccount = (props) => {
  const { classes, accountTypes, dispatch, spaces, webActions } = props
  const [accountName, setAccountName] = React.useState("");
  const [institutionName, setInstitutionName] = React.useState(null);
  const [accountTypeId, setAccountTypeId] = React.useState(1);
  const [accountSubtypeId, setAccountSubtypeId] = React.useState(1);
  const [accountCategoryId, setAccountCategoryId] = React.useState(null);
  const [balance, setBalance] = React.useState(0);

  const page = webActions.add_account ? webActions.add_account : "add"


  const space_options = Object.keys(spaces).map((s_id) => {
    return {
      value: s_id,
      label: spaces[s_id].space_name
    }
  })

  const [updatedSpaces, setUpdatedSpaces] = React.useState(space_options)
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    async function clearPreviousAdd() {
      dispatch(addAccountClear())
    }
    clearPreviousAdd()
  }, []);

  let categoryOptions = [];

  Object.keys(accountTypes).forEach( accountSubTypeId => { 
    categoryOptions.push({
      value: accountSubTypeId,
      label: accountTypes[accountSubTypeId].account_subtype_name
    })
    if (Object.keys(accountTypes[accountSubTypeId].account_categories).length > 0) {
      Object.keys(accountTypes[accountSubTypeId].account_categories).forEach((accountCategoryId) => {
        categoryOptions.push({
          value: accountSubTypeId + '-' + accountCategoryId,
          label: accountTypes[accountSubTypeId].account_subtype_name + ' - ' + accountTypes[accountSubTypeId].account_categories[accountCategoryId].account_category_name
        })  
      })
    }
  })



  const handleNameChange = (event) => {
    const {value} = event.target
    setAccountName(value);
  };

  const handleInstitutionChange = (event) => {
    const {value} = event.target
    setInstitutionName(value);
  };

  const handleBalanceChange = (event) => {
    const {value} = event.target
    setBalance(Number(value));
  };

  const handleCatChange = value => {
    const ids = value.value.split('-')
    const new_account_subtype_id = ids[0]
    const new_account_category_id = ids.length > 1 ? ids[1] : null
    const new_account_type_id = accountTypes[new_account_subtype_id].account_type.account_type_id

    setAccountTypeId(new_account_type_id)
    setAccountSubtypeId(new_account_subtype_id)
    setAccountCategoryId(new_account_category_id)
  };

 const handleSpaceChange = (value) => {
    setUpdatedSpaces(value);
  };


  async function handleSubmit(event) {
    event.preventDefault()
    const new_account = {
      name: accountName,
      account_type_id: accountTypeId,
      account_subtype_id: accountSubtypeId,
      account_category_id: accountCategoryId,
      institution_name: institutionName,
      latest_balance: balance,
      space_accounts: updatedSpaces ? updatedSpaces.map((element) => ({
        space_id: parseInt(element.value)
      })) : []
    }
    const token = await getAccessTokenSilently();
    dispatch(addAccount(new_account, token))
  }

  const inputProps = {
    step: 0.01,
  };


  return (
    <div>
        <DialogContentText>
          Add an Offline Account
        </DialogContentText>
        {page === "add" &&
          <form onSubmit={handleSubmit}>
            <TextField 
              required
              className={classes.nameInput} 
              id="new-account-name"
              label="Account Name" 
              defaultValue={accountName}
              onChange={handleNameChange}
              variant="outlined"
            />
            <TextField 
              required
              className={classes.nameInput} 
              id="new-account-institution"
              label="Institution Name" 
              defaultValue={institutionName}
              onChange={handleInstitutionChange}
              variant="outlined"
            />
            <Grid container justify="space-between" alignItems="center" className={classes.container}>
            <Grid item>
            <InputLabel shrink={true}>Account Category</InputLabel>
            <Select
              className={classes.categoryInput}
              id="new-account-category"
              value={{
                value: accountSubtypeId 
                  + (accountCategoryId ? '-' + accountCategoryId
                    : ''), 
                label: accountTypes[accountSubtypeId].account_subtype_name
                  + (accountCategoryId
                    ? ' - ' + accountTypes[accountSubtypeId].account_categories[accountCategoryId].account_category_name
                    : ''),
              }}
              onChange={handleCatChange}
              options={categoryOptions}
              />
            </Grid>
            <Grid item>
            <TextField
              required
              type="number"
              inputProps={inputProps}
              label="Account Balance"
              id="new-account-balance"
              className={classes.nameInput}
              defaultValue={balance}
              onChange={handleBalanceChange}
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
            </Grid>
            </Grid>
            <InputLabel shrink={true}>Include in Workspace</InputLabel>
            <Select
              className={classes.spaceInput}
              defaultValue={updatedSpaces}
              isMulti
              name="space_selector"
              options={space_options}
              classNamePrefix="select"
              onChange={handleSpaceChange}
            /> 
            <Button type="submit" color="primary">
              Add Account
            </Button>
          </form>
        }
        {page === 'pending' &&
          <ProgressSpinner />
        }
        {page === 'failed' &&
          <div>Add account failed.</div>
        }
        {page === 'done' &&
          <div>Account successfully added.</div>
        }
    </div>
  );
}

const mapStateToProps = (state,props) => {
  return {
    accountTypes: state.accountTypes,
    spaces: state.spaces,
    webActions: state.webActions
  };
};

export default connect(mapStateToProps)(withStyles(styles)(AddOfflineAccount))
