import React, { useState, useEffect } from "react";
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment'
import ProgressSpinner from '../utilities/ProgressSpinner'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Title from '../components/Title';
import BudgetRowsGroup from '../components/BudgetRowsGroup'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import TransactionTable from '../components/TransactionTable';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import { updateBudget } from '../actions/spaceTransactionCategoryBudgets'
import { useAuth0 } from "@auth0/auth0-react";
import NumberFormat from 'react-number-format';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CashflowWaterfallChart from '../components/CashflowWaterfallChart'
import BudgetSettings from '../components/BudgetSettings'

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    // minWidth: 600,
  },
  header: {
    paddingBottom: 12,
  },
  cellLessPadding: {
    paddingRight: 16,
    fontSize: "0.8rem"
  },  

  tightCell: {
    paddingRight: 6,
    paddingLeft: 6,
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    fontSize: "0.75rem"
  },  
  tightCellHead: {
    paddingRight: 6,
    paddingLeft: 6,
  }, 
  cellNoBottom: {
    borderBottom: "0px",
  },
  negativeCell : {
    color: 'red',
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  underBudget: {
    background: "#a5d6a7",
  },
  overBudget: {
    background: "#ef9a9a",
  },
  transactionPopover: {
    maxWidth: "1200px",
  },
  iconMargin: {
    marginBottom: "0.35em",
  },
  selectMonths: {
    minWidth: 200,
    float: "right"
  },
});





const Budget = (props) => {
	const { classes } = props;
  const { space_id } = props.match.params
  const { dates, transactionCategories, transactionCategoryBudgets, transactionCategorySpend, dataReady, dispatch} = props;
  const [open, setOpen] = React.useState(false);
  const [transactionFilters, setTransactionFilters] = React.useState({})
  const [editMode, setEditMode] = useState(false)
  const { getAccessTokenSilently } = useAuth0();
  const [months, setMonths] = React.useState(6);  
  const [includeMajorPurchases, setIncludeMajorPurchases] = React.useState(true)

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const tablet = useMediaQuery(theme.breakpoints.down('sm'));

  var updatedBudget = {...transactionCategoryBudgets}

  const yearToDateMonths = parseInt(moment().format('M'));
  const yearToDateYear = parseInt(moment().format('YYYY'));
  const lastYear = yearToDateYear - 1;


  useEffect(() => {
    if(mobile) {
      setMonths(1)
    } else if (tablet) {
      setMonths(3)
    }
  }, [mobile, tablet]);


  const handleInfoClick = (key) => {
    //setAnchorEl(event.currentTarget);
    setOpen(true)
    // console.log(event.currentTarget)
    //console.log(event)
    var params = key.split("?");
    var start_date = moment(params[1] + '-01', "YYYY-MM-DD").format("YYYY-MM-DD");
    var end_date = moment(params[1] + '-01', "YYYY-MM-DD").add(1, 'months').add(-1, 'days').format("YYYY-MM-DD");

    var filters = {
      "transaction_category_id": params[0],
      "start_date": start_date,
      "end_date": end_date,
    }
    // console.log(filters)
    setTransactionFilters(filters);
  };

  const handleInfoClose = () => {
    setOpen(false);
  };

  const id = open ? 'details' : undefined;

  function enterEditMode() {
    setEditMode(true);
  }

  async function submitEdits() {
    const token = await getAccessTokenSilently();
    dispatch(updateBudget(space_id, transactionCategoryBudgets, updatedBudget, token))
    setEditMode(false);
  }

  function saveBudget (transactionCategoryId, budget) {
    updatedBudget[transactionCategoryId] = Number(budget)
  }

  const transaction_category_ids = transactionCategories ? Object.keys(transactionCategories) : []

  let dateTotals = {}
  let type_total = 0

  // If "prior year" (24 months) chosen, splice array for those dates, otherwise take last n months from dates array
  var filteredDates = months === 24 ? dates.slice(Math.max(dates.length - (yearToDateMonths + 12), 0), Math.max(dates.length -(yearToDateMonths), 0)) : dates.slice(Math.max(dates.length - months, 0))


  filteredDates.forEach((date) => {
    let date_total = 0
    transaction_category_ids.forEach((transaction_category_id) => {
      date_total += transactionCategorySpend[transaction_category_id] && transactionCategorySpend[transaction_category_id][date] && (transaction_category_id !== 27 || includeMajorPurchases)
        ? transactionCategorySpend[transaction_category_id][date] : 0
    })
    dateTotals[date] = date_total
    type_total += date_total
  })

  let type_avg = type_total / filteredDates.length

  let budgetTotal = 0
  transaction_category_ids.forEach((transaction_category_id) => {
    budgetTotal += transactionCategoryBudgets[transaction_category_id]  && (transaction_category_id !== 27 || includeMajorPurchases) ? transactionCategoryBudgets[transaction_category_id] : 0
  })

  const handleMonthsChange = (event) => {
    setMonths(event.target.value);
  };

  const toggleIncludeMajorPurchases = () => {
    setIncludeMajorPurchases(!includeMajorPurchases)
  }



	return(
		<div>
      <BudgetSettings toggleMajorPurchases={toggleIncludeMajorPurchases} />
      <Paper className={classes.paper}>
        <Grid container alignItems="center" justify="space-between">
          <Grid container item alignItems="center" justify="flex-start" xs={6}>
            <Grid item >
              <Title>Budget</Title>
            </Grid>
            <Grid item>
                {!editMode && 
                <IconButton className={classes.iconMargin} onClick={enterEditMode}>
                    <EditIcon fontSize="small"/>
                </IconButton>   
              }
                {editMode && 
                <IconButton className={classes.iconMargin} onClick={() => submitEdits()}>
                  <DoneIcon fontSize="small" />
                </IconButton>
                }
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Select
              value={months}
              onChange={handleMonthsChange}
              className={classes.selectMonths}
            >
              <MenuItem value={1}>1 Months</MenuItem>
              <MenuItem value={3}>3 Months</MenuItem>
              <MenuItem value={6}>6 Months</MenuItem>
              <MenuItem value={9}>9 Months</MenuItem>
              <MenuItem value={12}>12 Months</MenuItem>
              <MenuItem value={yearToDateMonths}>{yearToDateYear} YTD</MenuItem>
              <MenuItem value={24}>{lastYear}</MenuItem>
            </Select>
          </Grid>
        </Grid>

        {!dataReady &&
          <ProgressSpinner />
        }
        {dataReady && 
          <Table className={classes.table} size="small">
              {/* <colgroup>
                <col style={{width:'200px'}}/>
                 {dates.map( date => ( 
                  <col key={date + "colgroup"} />
                ))}
              </colgroup> */}
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cellLessPadding} variant="head" align="left">Category</TableCell>
                  <TableCell className={classes.cellLessPadding} variant="head" align="right">
                    <i>Budget</i>
                  </TableCell>
                {filteredDates.map( date => ( 
                  <TableCell key={date} className={classes.tightCellHead} variant="head" align="center">{moment(date, "YYYY-MM").format("MMM-YY")}</TableCell>
                ))}
                {months > 1 && 
                  <React.Fragment>
                    <TableCell key="average" className={classes.tightCellHead} variant="head" align="center">Avg</TableCell>
                    <TableCell key="total" className={classes.tightCellHead} variant="head" align="center">Total</TableCell>
                  </React.Fragment>
                }
                </TableRow>
              </TableHead>

              <TableBody>
                {/* BLANK ROW 
                <TableRow >
                  <TableCell className={classes.cellNoBottom} align="left"><p> </p></TableCell>
                  <TableCell className={classes.cellNoBottom} align="right"></TableCell>
                  {dates.map( date => ( 
                  <TableCell key={date + "blank0"} className={classes.cellNoBottom} variant="head" align="right"></TableCell>
                    ))}
                  <TableCell key="averageblank0" className={classes.cellNoBottom} variant="head" align="right"></TableCell>
                  <TableCell key="totalblank0" className={classes.cellNoBottom} variant="head" align="right"></TableCell>
                </TableRow>
                */}

            {/* TOTALS ROW */}
                <TableRow >
                  <TableCell align="left" variant="head">Net</TableCell>
                  <TableCell align="right" className={classes.tightCellHead} variant="head">
                    <i>
                      <NumberFormat value={budgetTotal} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0} fixedDecimalScale={true} />
                    </i>
                  </TableCell>
                  {filteredDates.map( date => ( 
                  <TableCell key={date + "blank1"} className={classes.tightCellHead} variant="head" align="right">
                    <NumberFormat value={dateTotals[date]} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0} fixedDecimalScale={true} />
                  </TableCell>
                    ))}
                  { months > 1 &&
                  <React.Fragment>
                    <TableCell key={"averageblank1"} className={classes.tightCellHead} variant="head" align="right">
                      <NumberFormat value={type_avg} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0} fixedDecimalScale={true} />
                    </TableCell>
                    <TableCell key={"totalblank1"} className={classes.tightCellHead} variant="head" align="right">
                      <NumberFormat value={type_total} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0} fixedDecimalScale={true} />
                    </TableCell>
                  </React.Fragment>
                }
                </TableRow>


              <React.Fragment>

                <BudgetRowsGroup 
                  transaction_type={"Income"} 
                  dates={filteredDates} 
                  transactionCategories={transactionCategories}
                  transactionCategoryBudgets={transactionCategoryBudgets}
                  transactionCategorySpend={transactionCategorySpend}  
                  handleInfoClick={handleInfoClick}
                  editMode={editMode}
                  updateBudget={saveBudget} />
                <BudgetRowsGroup transaction_type={"Expense"}
                  dates={filteredDates} 
                  transactionCategories={transactionCategories}
                  transactionCategoryBudgets={transactionCategoryBudgets}
                  transactionCategorySpend={transactionCategorySpend}
                  handleInfoClick={handleInfoClick}
                  editMode={editMode}
                  updateBudget={saveBudget}
                  includeMajorPurchases={includeMajorPurchases}  />
              </React.Fragment>
              </TableBody> 
            </Table>
        }
      </Paper>
       <Dialog
        id={id}
        open={open}
        onClose={handleInfoClose}
        maxWidth="lg" fullWidth
      >
        <div className={classes.transactionPopover} >
          <TransactionTable space_id={space_id} title="Matching Transactions" edit_mode_default={false} filters={transactionFilters} show_cards={false} />
        </div>
      </Dialog>
      <CashflowWaterfallChart 
        dates={filteredDates} 
        transactionCategories={transactionCategories}
        transactionCategorySpend={transactionCategorySpend}
        includeMajorPurchases={includeMajorPurchases}
      />
		</div>

	);
};

const mapStateToProps = (state,props) => {
  const { space_id } = props.match.params

  if(!state.spaceTransactionCategorySpend.dates || !state.transactionCategories || !state.spaceTransactionCategoryBudgets[space_id]) {
    return {
      dataReady: false,
      dates: [],
      transactionCategories: [],
      transactionCategoryBudgets: {},
      transactionCategorySpend: {},
    }
  }

  return {
    dates: state.spaceTransactionCategorySpend.dates,
    transactionCategories: state.transactionCategories,
    transactionCategoryBudgets: state.spaceTransactionCategoryBudgets[space_id],
    transactionCategorySpend: state.spaceTransactionCategorySpend.transactionCategoryTimeSeries[space_id],
    dataReady: true
  };
};


export default connect(mapStateToProps)(withStyles(styles)(Budget));
