import { RECEIVE_ACCOUNTS, ADD_ACCOUNT_SUCCEEDED } from '../actions/accounts'

export default function spaceAccounts (state = {}, action) {

  switch(action.type) {

    case RECEIVE_ACCOUNTS :

      let spaceAccounts = {}
      action.accounts.map((account) => account.space_accounts
        .map((s) => s.space_id)
        .map((space_id) => spaceAccounts[space_id] = spaceAccounts[space_id] ? spaceAccounts[space_id].concat(account.account_id) : [account.account_id] ))

      return spaceAccounts

    case ADD_ACCOUNT_SUCCEEDED:
      let newSpaceAccounts = {...state} 

      action.account.space_accounts.forEach((element) => {
        newSpaceAccounts[element.space_id] = newSpaceAccounts[element.space_id].concat([action.account.account_id])
      })

      return newSpaceAccounts

    default :
      return state
  }
}