import React, { useState, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ProgressSpinner from '../utilities/ProgressSpinner'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Title from './Title'
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
// import MenuItem from '@material-ui/core/MenuItem';
import CheckIcon from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Button from '@material-ui/core/Button';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux'
import { API_PATH } from '../utilities/apiPath'


const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 600,
  },
  header: {
    paddingBottom: 12,
  },
  cellLessPadding: {
    paddingRight: 24,
  },  
  negativeCell : {
    color: 'red',
  },
  nameInput: {
    width: 300,
  },
  categoryInput: {
    width: 200,
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  checkIcon : {
    color: 'green',
  },
});


const AddRule = (props) => {
	const { classes, space_id, add_mode_default, show_matching_transactions, original_name_default, merchant_name_default, transaction_category_default } = props;
  const [searchTerm, setSearchTerm] = useState(original_name_default);
	const [showTransactions, setShowTransactions] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isSending, setIsSending] = useState(false);
  // const [transactionCategories, setTransactionCategories] = useState([]);
  const [addMode, setAddMode] = useState(add_mode_default);
  const [timer, setTimer] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [merchantName, setMerchantName] = useState(merchant_name_default);

  const { transactionCategories, dataReady, user } = props

  const [transactionCategory, setTransactionCategory] = useState({
    value: transaction_category_default ? transaction_category_default : null,
    label: transaction_category_default && transactionCategories[transaction_category_default] ? transactionCategories[transaction_category_default].transaction_category_name : null,
    });
  // var timer = null;
  const WAIT_INTERVAL = 1000;
  // const ENTER_KEY = 13;
  var search_term;

  let categoryOptions = [];

  

  Object.keys(transactionCategories).map( transactionCategoryId => ( 
      categoryOptions.push({
        value: transactionCategories[transactionCategoryId].transaction_category_id,
        label: transactionCategories[transactionCategoryId].transaction_category_name
      })
    ))  

  async function submitEdits(original_name) {
    // const new_accounts = [];
    setIsSending(true);

    var rules = {};
    rules['original_name'] = searchTerm;
    rules['merchant_name'] = merchantName;
    rules['transaction_category_id'] = transactionCategory.value;

    const auth_token = user.accessToken
    await fetch(API_PATH + 'api/rule/' + space_id, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth_token}`
      },
      body: JSON.stringify(rules)
    });
    setIsSending(false);

    // // console.log(new_data)
    // setData(new_data);

  }



  const triggerSearch = async () => {

    if (show_matching_transactions) {
      setIsSearching(true);
      const token = user.accessToken

      var url = API_PATH + "api/rules/" + space_id + "/search"

      var search_body = {
        'search_term': search_term
      }


      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(search_body)
      });

      const responseData = await response.json();
      setTransactions(responseData);
      setIsSearching(false);
      setShowTransactions(true);
    }

  }

  const handleChange = event => {
    const {name, value} = event.target
    if (name === 'search_string'){
      clearTimeout(timer);
      setSearchTerm(value);
      search_term = value;
      // console.log(event.target.value);
      setTimer(setTimeout(triggerSearch, WAIT_INTERVAL));
    }
    if (name === 'merchant_name') {
      setMerchantName(value);
    }
  };

  const handleCatChange = value => {
    const new_data = {value: value.value, label: value.label };
    setTransactionCategory(new_data)
  };

	return(
		
			      
			<React.Fragment>
		      <Paper className={classes.paper}>
          {!addMode &&
            <Button variant="contained" color="primary" onClick={() => setAddMode(true)}>
              New Rule
            </Button>
          }
          { addMode &&
            <Fragment>
            <Title>Add or Edit Rule</Title>
            <Table className={classes.table} size="small">
              {/*<TableHead>
               
                <TableRow>
                  <TableCell className={classes.cellLessPadding} variant="head" align="left">Original Name Search</TableCell>
                  <TableCell className={classes.cellLessPadding} variant="head" align="left">Merchant Name</TableCell>
                  <TableCell className={classes.cellLessPadding} variant="head" align="left">Category</TableCell>
                  <TableCell className={classes.cellLessPadding} variant="head" align="left"></TableCell>
                </TableRow>
              </TableHead>
            */}
              <TableBody>
                <TableRow>
                    <TableCell className={classes.cellLessPadding} align="left">
                    
                    <TextField 
                      className={classes.nameInput} 
                      name={'search_string'} 
                      label="Original Name Search" 
                      value={searchTerm}
                      onChange={handleChange}
                    />
                    </TableCell>
                    <TableCell className={classes.cellLessPadding} align="left">
                    <TextField 
                      className={classes.nameInput} 
                      name={'merchant_name'} 
                      label="Merchant Name" 
                      value={merchantName}
                      onChange={handleChange}
                    />
                    </TableCell>
                    <TableCell className={classes.cellLessPadding} align="left">
                      <Select
                        className={classes.categoryInput}
                        id={'transaction_category_id'}
                        value={transactionCategory}
                        // defaultValue={element.transaction_category_id}
                        onChange={handleCatChange}
                        options={categoryOptions}
                        >

                      </Select>
                    </TableCell>
                    <TableCell className={classes.cellLessPadding} align="left">
                      {isSending &&
                        <ProgressSpinner />
                      }
                      {!isSending &&
                      <IconButton aria-label="fix-error" onClick={() => submitEdits("")}>
                        <CheckIcon />
                      </IconButton>
                      }
                    </TableCell>
                  </TableRow>
                </TableBody>
            </Table>
            </Fragment>
          }
          <div>
          {addMode && show_matching_transactions &&
            <Title>Matching Transactions</Title>
          }
          {isSearching && addMode && show_matching_transactions &&
              <ProgressSpinner />
          }

            {showTransactions && addMode && show_matching_transactions &&
            <div>
             <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cellLessPadding} variant="head" align="left">Date</TableCell>
                  <TableCell className={classes.cellLessPadding} variant="head" align="left">Account</TableCell>
                  <TableCell className={classes.cellLessPadding} variant="head" align="left">Category</TableCell>
                  <TableCell className={classes.cellLessPadding} variant="head" align="left">Merchant Name</TableCell>
                  <TableCell className={classes.cellLessPadding} variant="head" align="left">Notes</TableCell>
                  <TableCell className={classes.cellLessPadding} variant="head" align="right">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map(transaction => (
                  <TableRow key={transaction.transaction_id} >
                    <TableCell className={classes.cellLessPadding} component="th" scope="row" >
                      <Moment format="MM/DD/YY">{transaction.date_time}</Moment>
                    </TableCell>
                    <TableCell className={classes.cellLessPadding} align="left">{transaction.account.name}</TableCell>
                    <TableCell className={classes.cellLessPadding} align="left">{transaction.transaction_category && transaction.transaction_category.transaction_category_name}</TableCell>
                    <TableCell className={classes.cellLessPadding} align="left">{transaction.merchant_name}</TableCell>
                    <TableCell className={classes.cellLessPadding} align="left">{transaction.notes}</TableCell>
                    <TableCell className={classes.cellLessPadding} align="right">
                      { transaction.amount >= 0.0 &&
                          <div><NumberFormat value={transaction.amount} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} /></div>
                          }
                          { transaction.amount < 0.0  &&
                          <div><NumberFormat className={classes.negativeCell} value={transaction.amount} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} /></div>
                          }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </div>
          }
          </div>

			  </Paper>
			</React.Fragment>
	);
};

AddRule.defaultProps = {
  add_mode_default: false,
  show_matching_transactions: true,
  original_name_default: '',
  merchant_name_default: '',
  transaction_category_default: {},
  transaction_categories: [],
}

const mapStateToProps = (state, props) => {
  const { space_id } = props;

  if (!space_id || !state.user || !state.transactionCategories ) {
    return {
      dataReady: false
    }
  }

  return {
    transactionCategories: state.transactionCategories,
    user: state.user,
    dataReady: true,
  }
};



export default connect(mapStateToProps)(withStyles(styles)(AddRule));