import { RECEIVE_PENDING_TRANSACTIONS } from '../actions/pendingTransactions'

export default function pendingTransactions (state = {}, action) {
  switch(action.type) {
    case RECEIVE_PENDING_TRANSACTIONS :

      let newPendingTransactions = {}
      action.pendingTransactionResponse.transactions
        .map((pendingTransaction) => newPendingTransactions[pendingTransaction.pending_transaction_id] = pendingTransaction) 
      return {
        ...newPendingTransactions
      }

      
    default :
      return state
  }
}