import { RECEIVE_BALANCE_TIME_SERIES } from '../actions/timeSeries'

export default function timeSeries (state = {}, action) {

  switch(action.type) {
    case RECEIVE_BALANCE_TIME_SERIES :
      return {...action.timeseries}
      
    default :
      return state
  }
}