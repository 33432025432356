import React from "react";
import { connect } from 'react-redux'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Title from '../components/Title';

const styles = theme => ({
  cellLessPadding: {
    paddingRight: 8,
    paddingLeft: 8,
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  userAvatar : {
    width: 24,
    height: 24,
    fontSize: "1em"
  },
  userAvatarMedium: {
    width: 32,
    height: 32,
    fontSize: "1em"    
  },  
});

const RulesTable = (props) => {
  const { rules, dataReady, classes, transactionCategories } = props


  return (
    <div>
      <Paper className={classes.paper}>
        <Title>Transaction Rules</Title>
        {dataReady &&
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.cellLessPadding} variant="head" align="left">Original Name Like</TableCell>
                <TableCell className={classes.cellLessPadding} variant="head" align="left">Merchant Name</TableCell>
                <TableCell className={classes.cellLessPadding} variant="head" align="left">Category</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rules.map(rule => (
                <TableRow key={rule.original_name_like} >
                  <TableCell className={classes.cellLessPadding} align="left">
                    {rule.original_name_like} 
                  </TableCell>
                <TableCell className={classes.cellLessPadding} scope="row" >
                  {rule.merchant_name} 
                </TableCell>
                <TableCell className={classes.cellLessPadding} scope="row" >
                  {transactionCategories[rule.transaction_category_id].transaction_category_name} 
                </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }
        </Paper>

    </div>
  );
};

const mapStateToProps = (state,props) => {
  const { space_id } = props


  if (!state.spaceTransactionRules || !state.spaceTransactionRules[space_id]) {
    return {
      rules: [],
      dataReady: false
    }
  }

  let rules = state.spaceTransactionRules[space_id]

  return {
    rules,
    dataReady: true,
    transactionCategories: state.transactionCategories,
  }
};

export default connect(mapStateToProps)(withStyles(styles)(RulesTable));