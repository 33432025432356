import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import moment from 'moment'
import { connect } from 'react-redux'



const styles = theme => ({
  red: {
    color: "red",
    fontStyle: "italic"
  },
  gray: {
    color: "gray",
    fontStyle: "italic"
  },
  green: {
    color: "green",
    fontStyle: "italic"
  },
});


const PriorBalanceComparison2 = (props) => {
	const { classes, days, account_ids, accounts, daily_balances } = props;


	if(!accounts || !daily_balances) {
		return null
	}

  var prior_date = moment().subtract(days,'d').format('YYYY-MM-DD');

  var prior_balance = 0;
  var current_balance = 0;

  account_ids.forEach((account_id) => {
  	prior_balance += daily_balances && daily_balances[account_id] && daily_balances[account_id][prior_date] ? daily_balances[account_id][prior_date] : 0
  	current_balance += accounts && accounts[account_id] ? accounts[account_id].latest_balance : 0
  })


	var difference = current_balance - prior_balance
	var difference_percent =  difference && (difference / prior_balance) * 100.0

	if (prior_balance < 0) {
		difference_percent = -difference_percent
	}

	if (prior_balance === 0) {
		if (current_balance >= 0) {
			difference_percent = 100
		} else {
			difference_percent = -100
		}
		
	}

  var class_name = 'green'

  if (difference > 0) {
    class_name = 'green'
  } else if (difference < 0) {
    class_name = 'red'
  } else {
    class_name = 'gray'
  }


	return(
			<span className={classes[class_name]}>
				<NumberFormat value={difference} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
				{" "}(<NumberFormat value={difference_percent} displayType={'text'} thousandSeparator={false} suffix={'%'} decimalScale={1} fixedDecimalScale={true} />)
			</span>
	);
};

const mapStateToProps = (state, props) => {
	const { account_ids } = props;

	if (!state.accounts || !state.accounts[account_ids[0]] || !state.timeSeries) {
		return {
			daily_balances: null,
			accounts: null
		}
	}

	return {
		daily_balances: state.timeSeries,
		accounts: state.accounts
	}
}



export default connect(mapStateToProps)(withStyles(styles)(PriorBalanceComparison2));