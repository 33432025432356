
import React from "react";
import loading_image from '../assets/money_pile.gif'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  image: {
    width: '100%',
    maxWidth: '400px'
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  loadingText: {
    textAlign: 'center'
  }
});



const Loading = (props) => {
  const { classes } = props

  return (
    <div className={classes.paper} >
  	<Grid container direction="column" alignItems='center' alignContent='center'>
    		<Grid item className={classes.loadingText}>
    			<Typography variant="h4" gutterBottom>
    				Gathering Financial Data...
    			</Typography>
    		</Grid>
    		<Grid item>
    			<img className={classes.image} src={loading_image} alt="Loading" />
    		</Grid>
    	</Grid>
    </div>
  )

}

export default withStyles(styles)(Loading);