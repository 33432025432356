import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ProgressSpinner from '../utilities/ProgressSpinner'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Title from './Title';
import AmountCard from '../components/AmountCard';
import Grid from '@material-ui/core/Grid';
import TransactionRow from '../components/TransactionRow';
import { connect } from 'react-redux'
import { getTransactionQueryParams, filterTransactions } from '../utilities/transactionFilters'
import { requestTransactionQuery } from '../actions/transactionQueries'
import { useAuth0 } from "@auth0/auth0-react";
import Hidden from '@material-ui/core/Hidden';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    // minWidth: 600,
  },
  header: {
    paddingBottom: 12,
  },
  cellLessPadding: {
    paddingRight: 24,
  },  
  negativeCell : {
    color: 'red',
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  nameInput: {
    width: 300,
  },
  notesInput: {
    width: 150,
  },
  categoryInput: {
    width: 200,
  },
  checkIcon : {
    color: 'green',
  },
});



const TransactionTable = (props) => {
	const { classes, space_id, edit_mode_default, title, show_cards, hide_if_empty } = props;
  const { dataReady, transaction_ids, fetching, dispatch, queryParams, transactionCategories } = props
	const [numTransactions, setNumTransactions] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const { getAccessTokenSilently } = useAuth0();

  async function getTransactions () {
    if (!dataReady && !fetching) {
      const token = await getAccessTokenSilently();
      dispatch(requestTransactionQuery(queryParams, token))
    }
  }
  getTransactions();

  if (hide_if_empty && dataReady && transaction_ids && transaction_ids.length === 0 && Object.keys(transactionCategories).length > 0) {
    return (
      <div />
    )
  }


	return(  
			<React.Fragment>
      {show_cards && 
  			<Grid container>
  				<Grid item>
  					<AmountCard title="Transactions" amount={numTransactions} numberType={'count'} />
  				</Grid>
          <Grid item>
            <AmountCard title="Amount" amount={totalAmount} numberType={'money'} />
          </Grid>
  			</Grid>
      }
		      <Paper className={classes.paper}>
		      	<Title>{title}</Title>
				{(!dataReady || Object.keys(transactionCategories).length === 0) &&
		        	<ProgressSpinner />
		  		}
          {dataReady && transaction_ids && transaction_ids.length === 0 && Object.keys(transactionCategories).length > 0 &&
            <div>No transactions.</div>
          }
		  		{dataReady && transaction_ids && transaction_ids.length > 0 && Object.keys(transactionCategories).length > 0 && 
			      <Table className={classes.table} size="small">
			        <TableHead>
			          <TableRow>
                  <Hidden smDown>
			             <TableCell className={classes.cellLessPadding} variant="head" align="left">Merchant Name</TableCell>
                  </Hidden>
                  <Hidden mdUp>
                   <TableCell className={classes.cellLessPadding} variant="head" align="left">Details</TableCell>
                  </Hidden>                  
                  <Hidden smDown>
			             <TableCell className={classes.cellLessPadding} variant="head" align="left">Category</TableCell>
                  </Hidden>
			            <TableCell className={classes.cellLessPadding} variant="head" align="right">Amount</TableCell>
                  <Hidden smDown>
			             <TableCell className={classes.cellLessPadding} variant="head" align="right"></TableCell>
                  </Hidden>
			          </TableRow>
			        </TableHead>
			        <TableBody>
			          {transaction_ids.map(transaction_id => (
			          	<TransactionRow key={transaction_id} space_id={space_id} transaction_id={transaction_id} edit_mode={edit_mode_default} />
			          	
			          ))}
			        </TableBody>
			      </Table>
			    } 
			  </Paper>
			</React.Fragment>
	);
};

TransactionTable.defaultProps = {
  edit_mode_default: false,
  filters: {},
  title: "Transactions",
  show_cards: true,
}

const mapStateToProps = (state, props) => {
  const { space_id, filters } = props;
  const { transactionQueries, transactionCategories } = state;

  let transaction_ids = []

  let queryParams = getTransactionQueryParams(filters, space_id)

  if (!transactionQueries || !transactionQueries[queryParams]) {
    return {
      transaction_ids,
      dataReady: false, 
      fetching: false,
      queryParams
    }
  } else if (transactionQueries[queryParams].fetching) {
    return {
      transaction_ids,
      dataReady: false, 
      fetching: true,
      queryParams
    }
  } else {
    // transaction_ids = transactionQueries[queryParams].transaction_ids
    //console.log(transaction_ids)

    transaction_ids = Object.keys(state.transactions)

    if ( space_id ) {
      transaction_ids = transaction_ids.filter((transaction_id) => state.transactions[transaction_id].account.space_accounts.map((s) => s.space_id).includes(parseInt(space_id)))
    } 

    transaction_ids = filterTransactions(transaction_ids, state.transactions, filters)

    // Object.keys(filters).forEach((filter_name) => {
    //   transaction_ids = transaction_ids.filter((transaction_id) => state.transactions[transaction_id][filter_name] === filters[filter_name])
    // })

    


    return {
      transaction_ids,
      dataReady: true,
      fetching: false,
      queryParams,
      transactionCategories
    }    
  }



  // let transaction_ids = []

  // if (space_id && !state.spaces[space_id].transaction_ids) {
  //   return {
  //     transaction_ids: transaction_ids,
  //     dataReady: false
  //   }
  // }

  // if (account_id && Object.keys(state.transactions).length === 0) {
  //   return {
  //     transaction_ids: transaction_ids,
  //     dataReady: false
  //   }
  // }

  // if (filters && filters.is_reviewed === false) {
  //   transaction_ids = state.spaces[space_id].transaction_ids.filter((transaction_id) => state.transactions[transaction_id].is_reviewed === false)
  // } else if (space_id) {
  //   transaction_ids = state.spaces[space_id].transaction_ids
  // } else if (account_id) {
  //   transaction_ids = Object.keys(state.transactions).filter((transaction_id) => state.transactions[transaction_id].account_id === parseInt(account_id))
  // }

  // //const ids = space_id ? state.spaces[space_id].pending_transaction_ids : state.user.pending_transaction_ids
  // return {
  //   transaction_ids: transaction_ids,
  //   dataReady: true
  // };
};


export default connect(mapStateToProps)(withStyles(styles)(TransactionTable));