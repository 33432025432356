import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ProgressSpinner from '../utilities/ProgressSpinner'
import Paper from '@material-ui/core/Paper';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend
} from 'recharts';
import { connect } from 'react-redux'
import moment from 'moment'


const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    // minWidth: 200,
    // width: '100%',
    // overflowX: 'auto',
  },
  selectMonths: {
    minWidth: 200,
    float: "right"
  },
  chartTooltip: {
  	margin: "0px",
  	padding: "10px",
  	backgroundColor: "white",
  	border: "1px solid rgb(204, 204, 204)",
  	whiteSpace: "nowrap"
  },
  chartTooltipLabel: {
  	margin: "0px",
  	fontWeight: "bold",
  },
  chartTooltipItemList: {
  	margin: "0px",
  	padding: "0px",
  },  
  chartTooltipItem: {
  	display: "block", 
  	paddingTop: "4px",
  	paddingBottom: "4px",
  },
  chartTooltipItemTotal: {
  	display: "block", 
  	paddingTop: "4px",
  	paddingBottom: "4px",
  	fontWeight: "bold",
  }

});

// const colorArray = ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', 
// '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff',
// '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', 
// '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000'];

// const colorArray = ['#80cbc4','#80deea','#b39ddb','#9fa8da','#ffe082','#b0bec5'];
// const colorArray = ['#c5e1a5','#ef9a9a','#90caf9','#b39ddb','#ffe082','#b0bec5'];
// Google Pallette - 500 level
const colorArray = ['#4caf50','#f44336','#2196f3','#673ab7','#ff9800','#009688'];


const ScenarioDetailTimeChart = (props) => {
	const { classes } = props;
	const { dataReady, formattedTimeSeries, subcategories } = props


	const CustomTooltip = ({ active, payload, label }) => {

	  if (active) {
	  	var totalValue = 0
	  	if (payload) {
		  	payload.forEach((pld) => {
		  		totalValue += pld.value
		  	})
		  }
	    return (
	      <div className={classes.chartTooltip}>
	      	<p className={classes.chartTooltipLabel}>
	      		{label} 
	      	</p>
	      	<ul className={classes.chartTooltipItemList}>
	      		<li className={classes.chartTooltipItemTotal} >
	      			<span className="recharts-tooltip-item-name">
	      				Total
	        		</span>
	        		<span className="recharts-tooltip-item-separator">
	        			{` : `}
	        		</span>
	        		<span className="recharts-tooltip-item-value">
	        			{Intl.NumberFormat('en', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(totalValue)}
	        		</span>
        		</li>

		      	{payload && payload.map((pld) => (
		      		<li key={pld.name} className={classes.chartTooltipItem} style={{color: `${pld.color}`}}>
		      			<span>
		      				{pld.name}
		        		</span>
		        		<span>
		        			{` : `}
		        		</span>
		        		<span>
		        			{Intl.NumberFormat('en', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(pld.value)}
		        		</span>
	        		</li>
		        ))}
	      	</ul>
	      </div>
	    );
	  }

	  return null;
	};	

	return(
			<React.Fragment>
		      <Paper className={classes.paper}> 
				{!dataReady &&
		        	<ProgressSpinner />
		  		}
		  		{dataReady &&
		  		<ResponsiveContainer width="95%" height={400}>
			    	<AreaChart data={formattedTimeSeries}
			            margin={{top: 10, right: 0, left: 30, bottom: 0}}>
			        <CartesianGrid strokeDasharray="3 3"/>
			        <XAxis dataKey="date" tickFormatter={(value) => moment(value).format('YYYY')} />
			        <YAxis tickFormatter={(value) => new Intl.NumberFormat('en', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(value)} />
			        <Tooltip content={<CustomTooltip />} />
			        {/*<Tooltip formatter={(value) => new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(value)} /> */}
			        <Legend />
			        {subcategories.map((subtype, index) => (
			        		<Area connectNulls key={subtype} type='monotone' dataKey={subtype} stackId="1" stroke={colorArray[index]} fill={colorArray[index]} />
			        	))

			        }
			      </AreaChart>
			     </ResponsiveContainer>
			    } 
			  </Paper>
			</React.Fragment>
	);
};

const mapStateToProps = (state, props) => {
  const { scenario_id } = props;
  const { scenarios, accountTypes } = state


  if (!scenarios || !accountTypes || !scenarios[scenario_id] || !scenarios[scenario_id].scenario_calculation_balances) {
    return {
      dataReady: false
    }
  }


	let data = {}
	let sub_categories = {}

	scenarios[scenario_id].scenario_calculation_balances.forEach((balance) => {
		let account_subtype_id = balance.account_subtype_id
		let account_subtype_name = accountTypes[account_subtype_id].account_subtype_name
		if (!data[balance.date]) {
			data[balance.date] = {}
			data[balance.date]['date'] = balance.date
		}

		if (balance.balance !== 0) {
			data[balance.date][account_subtype_name] = balance.balance
			if (!sub_categories[account_subtype_id]) {
				sub_categories[account_subtype_id] = account_subtype_name
			}			
		}

	})


  return {
    dataReady: true,
    formattedTimeSeries: Object.values(data),
    subcategories: Object.values(sub_categories)
  };
};



export default connect(mapStateToProps)(withStyles(styles)(ScenarioDetailTimeChart));