import React, { useState, Fragment } from "react";
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NumberFormat from 'react-number-format';
import moment from 'moment'
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from 'react-select';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
  spendingAdditional: {
    marginTop: "16px"
  },
  cellLessPadding: {
    paddingRight: 8,
    paddingLeft: 8,
  },
  incomeNameInput: {
    fontSize: "0.875rem"
  },
  growthInput: {
    width: "70px",
    fontSize: "0.875rem"
  },
  taxInput: {
    width: "70px",
    fontSize: "0.875rem"
  },
  incomeInput: {
    maxWidth: "120px",
    fontSize: "0.875rem"
  },
  userInput: {
    minWidth: "120px",
    fontSize: "0.875rem"
  },
  dateInput: {
    width: "130px",
    fontSize: "0.875rem"
  },
  recurringInput: {
    minWidth: "100px",
    fontSize: "0.875rem"
  },
});


const ScenarioDetailSpendingAdditional = (props) => {

	const { scenario, classes, editMode, updateScenario } = props
  const [updatedScenarioSpendingAdditional, setUpdatedScenarioSpendingAdditional] = useState([...scenario.scenario_assumptions_spending_additional])

  const recurringTypes = {
    1: "Monthly",
    2: "Yearly",
    3: "One Time",
  }


  const recurringOptions = Object.keys(recurringTypes).map((recurring_type_id) => ({
    value: recurring_type_id,
    label: recurringTypes[recurring_type_id]
  }))


  const handleChanges = (event, additional_spending_id) => {
    let newSpending = [...scenario.scenario_assumptions_spending_additional]
    const {name, value} = event.target

    newSpending.forEach((spend, i) => {
      if (spend.additional_spending_id === additional_spending_id) {
        newSpending[i][name] = value
      }
    })

    updateScenario("scenario_assumptions_spending_additional", newSpending)
    setUpdatedScenarioSpendingAdditional(newSpending)

  }


  const handleRecurringChanges = (value, additional_spending_id) => {

    let newSpending = [...scenario.scenario_assumptions_spending_additional]

    newSpending.forEach((spend, i) => {
      if (spend.additional_spending_id === additional_spending_id) {
        newSpending[i].recurring_type_id = Number(value.value)
        newSpending[i].recurring_type = {
          recurring_type_id: Number(value.value),
          recurring_name: recurringTypes[value.value]
        }
      }
    })

    updateScenario("scenario_assumptions_spending_additional", newSpending)
    setUpdatedScenarioSpendingAdditional(newSpending)
  };

  const handleStartDateChanges = (value, additional_spending_id) => {

    let newSpending = [...scenario.scenario_assumptions_spending_additional]

    newSpending.forEach((spend, i) => {
      if (spend.additional_spending_id === additional_spending_id) {
        if (value) {
          newSpending[i].start_date = moment(value).format('YYYY-MM-DD')
        }
        else {
          newSpending[i].start_date = null
        }
      }
    })

    updateScenario("scenario_assumptions_spending_additional", newSpending)
    setUpdatedScenarioSpendingAdditional(newSpending)
  };

  const handleEndDateChanges = (value, additional_spending_id) => {

    let newSpending = [...scenario.scenario_assumptions_spending_additional]

   newSpending.forEach((spend, i) => {
      if (spend.additional_spending_id === additional_spending_id) {
        if (value) {
          newSpending[i].end_date = moment(value).format('YYYY-MM-DD')
        }
        else {
          newSpending[i].end_date = null
        }
      }
    })

    updateScenario("scenario_assumptions_spending_additional", newSpending)
    setUpdatedScenarioSpendingAdditional(newSpending)
  };

  const removeSpend = (additional_spending_id) => {

    let newSpending = [...scenario.scenario_assumptions_spending_additional]

   newSpending.forEach((spend, i) => {
      if (spend.additional_spending_id === additional_spending_id) {
        newSpending.splice(i, 1)
      }
    })


    updateScenario("scenario_assumptions_spending_additional", newSpending)
    setUpdatedScenarioSpendingAdditional(newSpending)
  };

  const addSpend = () => {

    let newSpending = [...scenario.scenario_assumptions_spending_additional]

    let newSpendAdditional = {
      end_date: null,
      additional_spending_id: Math.floor(Math.random() * 100000),
      additional_spending_name: "New Spending",
      recurring_type: {recurring_name: "Monthly", recurring_type_id: 1},
      recurring_type_id: 1,
      scenario_id: scenario.scenario_id,
      start_date: null,
      amount: 0,
    }

    newSpending.push(newSpendAdditional)

    updateScenario("scenario_assumptions_spending_additional", newSpending)
    setUpdatedScenarioSpendingAdditional(newSpending)
  };


	return (
    <div className={classes.spendingAdditional}>
          <Typography variant="h6" gutterBottom>
            Additional Spending
          </Typography>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.cellLessPadding} variant="head" align="left">Spending Name</TableCell>
                <TableCell className={classes.cellLessPadding} variant="head" align="left">Type</TableCell>
                <TableCell className={classes.cellLessPadding} variant="head" align="right">Amount</TableCell>  
                <TableCell className={classes.cellLessPadding} variant="head" align="right">Start Date</TableCell>
                <TableCell className={classes.cellLessPadding} variant="head" align="right">End Date</TableCell> 
                {editMode && 
                  <TableCell className={classes.cellLessPadding} variant="head" align="center"></TableCell> 
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {updatedScenarioSpendingAdditional.map(additional_spending => (
                <TableRow key={additional_spending.additional_spending_id} >
                  <TableCell className={classes.cellLessPadding} align="left">
                    {!editMode && 
                      <span>{additional_spending.additional_spending_name}</span>
                    }  
                    {editMode &&
                    <TextField
                      InputProps={{
                        classes: {
                          input: classes.incomeNameInput,
                        },
                      }}
                      type="text"
                      // id="income_name"
                      name="additional_spending_name"
                      className={classes.incomeNameInput}
                      defaultValue={additional_spending.additional_spending_name}
                      onChange={(event) => handleChanges(event, additional_spending.additional_spending_id)}
                    />
                    }
                  </TableCell>
                  <TableCell className={classes.cellLessPadding} align="left">
                    {!editMode && 
                      <span>{additional_spending.recurring_type.recurring_name}</span>
                    }  
                    {editMode &&
                      <Select
                        className={classes.recurringInput}
                        value={{
                          value: additional_spending.recurring_type.recurring_type_id, 
                          label: recurringTypes[additional_spending.recurring_type.recurring_type_id],
                        }}
                        onChange={(value) => handleRecurringChanges(value, additional_spending.additional_spending_id)}
                        options={recurringOptions}
                        >

                      </Select>
                    }
                  </TableCell>
                  <TableCell className={classes.cellLessPadding} align="right">
                  {!editMode &&
                    <NumberFormat value={additional_spending.amount} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                    }
                    {editMode &&
                    <TextField
                      type="number"
                      // id="yearly_amount"
                      name="amount"
                      className={classes.incomeInput}
                      defaultValue={additional_spending.amount}
                      onChange={(event) => handleChanges(event, additional_spending.additional_spending_id)}
                      inputProps={{
                        style: { textAlign: "right", fontSize: "0.875rem" },
                        step: 0.01,
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                    }
                  </TableCell>
                  <TableCell className={classes.cellLessPadding} align="right">
                    {!editMode && additional_spending.start_date &&
                      <span>{moment(additional_spending.start_date).format('MM/DD/YYYY')}</span>
                    }
                    {!editMode && !additional_spending.start_date &&
                      <span>None</span>
                    }
                  {editMode && additional_spending.start_date && 
                    <Fragment>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            InputProps={{
                              classes: {
                                input: classes.dateInput,
                              },
                            }}
                            disableToolbar
                            className={classes.dateInput}
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            name="start_date"
                            value={moment(additional_spending.start_date).format('MM/DD/YYYY')}
                            onChange={(value) => handleStartDateChanges(value, additional_spending.additional_spending_id)}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        <IconButton aria-label="edit" onClick={() => handleStartDateChanges(null, additional_spending.additional_spending_id)}>
                          <ClearIcon fontSize="small" />
                        </IconButton>
                    </Fragment>
                  }
                  {editMode && !additional_spending.start_date && 
                    <Button color="primary" onClick={() => handleStartDateChanges(moment(), additional_spending.additional_spending_id)}>Add</Button>
                  }
                  </TableCell>
                  <TableCell className={classes.cellLessPadding} align="right">
                    {(additional_spending.recurring_type_id === 1 || additional_spending.recurring_type_id === 2) && 
                      <span>
                      {!editMode && additional_spending.end_date &&
                        <span>{moment(additional_spending.end_date).format('MM/DD/YYYY')}</span>
                      }
                      {!editMode && !additional_spending.end_date &&
                        <span>None</span>
                      }
                      {editMode && additional_spending.end_date &&
                        <Fragment>
                         <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                              InputProps={{
                                classes: {
                                  input: classes.dateInput,
                                },
                              }}
                              disableToolbar
                              className={classes.dateInput}
                              variant="inline"
                              format="MM/dd/yyyy"
                              margin="normal"
                              name="end_date"
                              value={moment(additional_spending.end_date).format('MM/DD/YYYY')}
                              onChange={(value) => handleEndDateChanges(value, additional_spending.additional_spending_id)}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />
                          </MuiPickersUtilsProvider>
                          <IconButton aria-label="edit" onClick={() => handleEndDateChanges(null, additional_spending.additional_spending_id)}>
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        </Fragment>
                      }
                      {editMode && !additional_spending.end_date && 
                        <Button color="primary" onClick={() => handleEndDateChanges(moment(), additional_spending.additional_spending_id)}>Add</Button>
                      }
                      </span>
                    }
                    {additional_spending.recurring_type_id === 3 &&
                      <span>n/a</span>
                    }
                  </TableCell>
                  {editMode && 
                    <TableCell className={classes.cellLessPadding} variant="head" align="center">
                      <IconButton aria-label="edit" onClick={() => removeSpend(additional_spending.additional_spending_id)}>
                        <DeleteOutlineIcon fontSize="small" />
                    </IconButton>
                    </TableCell> 
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {editMode && 
            <Button color="primary" onClick={() => addSpend()}>Add Spending</Button>
          }
    </div>
	)
}


export default withStyles(styles)(ScenarioDetailSpendingAdditional);