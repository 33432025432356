import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import { useAuth0 } from "@auth0/auth0-react";
import { connect } from 'react-redux'
import { deleteAccount, updateAccount } from '../actions/accounts'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment'
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import Tooltip from '@material-ui/core/Tooltip';

const DeleteAccount = (props) => {
  const [open, setOpen] = React.useState(false);
  const { account, dispatch } = props
  const { getAccessTokenSilently } = useAuth0();
  const [closeDelete, setCloseDelete] = React.useState('close');
  const [closeDate, setCloseDate] = useState(new Date(moment()))

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function submitDeleteAccount() {
    const token = await getAccessTokenSilently();
    var newAccount = {}
    if (account.is_deleted) {
       newAccount = {
        ...account,
        is_deleted: false
      }
      await dispatch(updateAccount(account, newAccount, token))     
    } else if (account.closed_date) {
       newAccount = {
        ...account,
        closed_date: null
      }
      await dispatch(updateAccount(account, newAccount, token))    
    } else if (closeDelete === 'close') {
      newAccount = {
        ...account,
        closed_date: moment(closeDate).format('YYYY-MM-DD')
      }
      await dispatch(updateAccount(account, newAccount, token))
    } else if (closeDelete === 'delete') {
      await dispatch(deleteAccount(account.account_id, token))
    }
  }

  const handleChange = (event) => {
    setCloseDelete(event.target.value);
  };

  const handleCloseDateChange = (date) => {
    setCloseDate(date);
  };

  return (
    <span>
      <IconButton onClick={handleClickOpen}>
        {(!account.is_deleted && !account.closed_date) &&
          <Tooltip title={`Delete or Close Account`} arrow>
            <DeleteOutlineIcon fontSize="small" />
          </Tooltip>
        }
        {(account.is_deleted || account.closed_date) &&
          <Tooltip title={`Restore Account`} arrow>
            <RestoreFromTrashIcon fontSize="small" />
          </Tooltip>
        }
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{"Delete or close account?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>{account.name}</span>
            {account.is_deleted &&
              <React.Fragment>
                <br />
                <span>Undeleted account?</span>
              </React.Fragment>
            }
            {account.closed_date &&
              <React.Fragment>
                <br />
                <span>Unclose account?</span>
              </React.Fragment>
            }
          </DialogContentText>
          {!account.is_deleted && !account.closed_date && 
          <FormControl component="fieldset">
            <RadioGroup name="closeOrDelete" value={closeDelete} onChange={handleChange}>
              <FormControlLabel value="close" control={<Radio />} label="Close - Mark as closed but keep historical data." />
              <FormControlLabel value="delete" control={<Radio />} label="Delete - Exclude all data." />
            </RadioGroup>
          </FormControl>
        }
            {!account.is_deleted && !account.closed_date && closeDelete === 'close' &&
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="close-date"
                  label="Close Date"
                  value={closeDate}
                  onChange={handleCloseDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={submitDeleteAccount} color="primary" autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}

export default connect()(DeleteAccount);
