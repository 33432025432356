

export const RECEIVE_SPACES = 'RECEIVE_SPACES'
export const LOAD_SPACES = 'LOAD_SPACES'


export function loadSpaces () {
	return {
		type: LOAD_SPACES,
	}
}

export function receiveSpaces (spaces) {
	return {
		type: RECEIVE_SPACES,
		spaces
	}
}