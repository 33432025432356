import { RECEIVE_BALANCE_TIME_SERIES_LONG_TERM } from '../actions/timeSeriesLongTerm'

export default function timeSeriesLongTerm (state = {}, action) {

  switch(action.type) {
    case RECEIVE_BALANCE_TIME_SERIES_LONG_TERM :
      return {...action.timeseries}
      
    default :
      return state
  }
}