import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ProgressSpinner from '../utilities/ProgressSpinner'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Title from './Title';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux'


const styles = theme => ({
  table: {
    // minWidth: 600,
  },
  cellLessPadding: {
    paddingRight: 24,
  },  
  negativeCell : {
    color: 'red',
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  date: {
    fontStyle: "italic",
    color: "gray",
    fontSize: '0.7rem',
  },
  amount: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    },
  },
  accountName: {
    fontStyle: "italic",
    color: "gray",
    fontSize: '0.7rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem'
    },
  },
  merchantName: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    },
  },
});


const PendingTransactionTable = (props) => {
	const { classes } = props;
  const { dataReady, pendingTransactions, pending_transaction_ids } = props

	return(	      
			<React.Fragment>
		      <Paper className={classes.paper}>
		      	<Title>Pending Transactions</Title>
				{!dataReady &&
		        	<ProgressSpinner />
		  		}
          {dataReady && pending_transaction_ids.length === 0 &&
            <div>No pending transactions.</div>
          }
		  		{dataReady && pending_transaction_ids.length > 0 &&
			      <Table className={classes.table} size="small">
			        <TableHead>
			          <TableRow>
			            <TableCell className={classes.cellLessPadding} variant="head" align="left">Description</TableCell>
			            <TableCell className={classes.cellLessPadding} variant="head" align="right">Amount</TableCell>
			          </TableRow>
			        </TableHead>
			        <TableBody>
			          {pending_transaction_ids.map(pending_transaction_id => (
                  <TableRow key={pending_transaction_id} >
                    <TableCell className={classes.cellLessPadding} align="left">
                      <div className={classes.merchantName}>
                      {pendingTransactions[pending_transaction_id].original_name}
                      </div>
                      <div className={classes.accountName} >
                        {pendingTransactions[pending_transaction_id].account.name}
                      </div>
                    </TableCell>       
                    <TableCell className={classes.cellLessPadding} align="right">
                      <div className={classes.amount}>
                      { pendingTransactions[pending_transaction_id].amount >= 0.0 &&
                          <div><NumberFormat value={pendingTransactions[pending_transaction_id].amount} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} /></div>
                          }
                          { pendingTransactions[pending_transaction_id].amount < 0.0  &&
                          <div><NumberFormat className={classes.negativeCell} value={pendingTransactions[pending_transaction_id].amount} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} /></div>
                          }
                      </div>
                      <div className={classes.date}><Moment format="ddd, MMM D">{pendingTransactions[pending_transaction_id].date_time}</Moment></div>
                    </TableCell>
                  </TableRow>
			          ))}
			        </TableBody>
			      </Table>
			    } 
			  </Paper>
			</React.Fragment>
	);
};

const mapStateToProps = (state, props) => {
  const { space_id } = props;


  if (!state.spacePendingTransactions ) {
    return {
      pending_transaction_ids: [],
      pendingTransactions: {},
      dataReady: false    
    }
  }

  if (state.spacePendingTransactions && space_id && !state.spacePendingTransactions[space_id] ) {
    return {
      pending_transaction_ids: [],
      pendingTransactions: state.pendingTransactions,
      dataReady: true    
    }
  }

  if (state.spacePendingTransactions && !space_id && !state.user.pending_transaction_ids ) {
    return {
      pending_transaction_ids: [],
      pendingTransactions: state.pendingTransactions,
      dataReady: true    
    }
  }

  const ids = space_id ? state.spacePendingTransactions[space_id] : state.user.pending_transaction_ids
  return {
    pending_transaction_ids: ids,
    pendingTransactions: state.pendingTransactions,
    dataReady: true
  };
};


export default connect(mapStateToProps)(withStyles(styles)(PendingTransactionTable));