import React from "react";
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import NumberFormat from 'react-number-format';
import BudgetRow from '../components/BudgetRow'


const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 600,
  },
  header: {
    paddingBottom: 12,
  },
  cellLessPadding: {
    paddingRight: 16,
    fontSize: "0.8rem"
  },  

  tightCell: {
    paddingRight: 6,
    paddingLeft: 6,
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    fontSize: "0.75rem"
  },  
  tightCellHead: {
    paddingRight: 6,
    paddingLeft: 6,
  }, 
  cellNoBottom: {
    borderBottom: "0px",
  },
  negativeCell : {
    color: 'red',
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  underBudget: {
    background: "#a5d6a7",
  },
  overBudget: {
    background: "#ef9a9a",
  },
  transactionPopover: {
    maxWidth: "1200px",
  },
});


const BudgetRowsGroup = (props) => {
	const { classes } = props;
	const { dates, transactionCategories, transactionCategoryBudgets, transactionCategorySpend, transaction_type, includeMajorPurchases } = props;
  const { editMode, handleInfoClick, updateBudget } = props

	const transaction_category_ids = Object.keys(transactionCategories)
		.filter((transaction_category_id) => transactionCategories[transaction_category_id].transaction_type === transaction_type)
    .sort((a,b) => transactionCategories[a].display_order - transactionCategories[b].display_order)

	let dateTotals = {}
	let type_total = 0

	dates.forEach((date) => {
		let date_total = 0
		transaction_category_ids.forEach((transaction_category_id) => {
			date_total += transactionCategorySpend[transaction_category_id] && transactionCategorySpend[transaction_category_id][date] && (transaction_category_id !== 27 || includeMajorPurchases)
				? transactionCategorySpend[transaction_category_id][date] : 0
		})
		dateTotals[date] = date_total
		type_total += date_total
	})

	let type_avg = type_total / dates.length


	let budgetTotal = 0
	transaction_category_ids.forEach((transaction_category_id) => {
		budgetTotal += transactionCategoryBudgets[transaction_category_id] && (transaction_category_id !== 27 || includeMajorPurchases) ? transactionCategoryBudgets[transaction_category_id] : 0
	})

	

	return(
              <React.Fragment>
              	{/* BLANK ROW */}
                <TableRow >
                  <TableCell className={classes.cellNoBottom} align="left"><p> </p></TableCell>
                  <TableCell className={classes.cellNoBottom} align="right"></TableCell>
                  {dates.map( date => ( 
                  <TableCell key={date + "blank0"} className={classes.cellNoBottom} variant="head" align="right"></TableCell>
                    ))}
                  {dates.length > 1 && 
                    <React.Fragment>
                      <TableCell key="averageblank0" className={classes.cellNoBottom} variant="head" align="right"></TableCell>
                      <TableCell key="totalblank0" className={classes.cellNoBottom} variant="head" align="right"></TableCell>
                    </React.Fragment>
                  }
                </TableRow>

            {/* TOTALS ROW */}
                <TableRow >
                  <TableCell align="left" variant="head">{transaction_type}</TableCell>
                  <TableCell align="right" className={classes.tightCellHead} variant="head">
                    <i>
                      <NumberFormat value={budgetTotal} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0} fixedDecimalScale={true} />
                    </i>
                  </TableCell>
                  {dates.map( date => ( 
                  <TableCell key={date + "blank1"} className={classes.tightCellHead} variant="head" align="right">
                    <NumberFormat value={dateTotals[date]} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0} fixedDecimalScale={true} />
                  </TableCell>
                    ))}
                  {dates.length > 1 && 
                    <React.Fragment>
                      <TableCell key={"averageblank1"} className={classes.tightCellHead} variant="head" align="right">
                        <NumberFormat value={type_avg} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0} fixedDecimalScale={true} />
                      </TableCell>
                      <TableCell key={"totalblank1"} className={classes.tightCellHead} variant="head" align="right">
                        <NumberFormat value={type_total} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0} fixedDecimalScale={true} />
                      </TableCell>
                    </React.Fragment>
                }
                </TableRow>

                

            	{transaction_category_ids.filter((transaction_category_id) => transaction_category_id !== 27 || includeMajorPurchases).map((transaction_category_id) => (
            		<BudgetRow 
            			transaction_category_id={transaction_category_id} 
            			budget={transactionCategoryBudgets[transaction_category_id]}
            			dates={dates}
            			transactionCategorySpend={transactionCategorySpend[transaction_category_id]}
            			transactionCategory={transactionCategories[transaction_category_id]}
            			key={transaction_category_id}
            			handleInfoClick={handleInfoClick}
                  editMode={editMode}
                  updateBudget={updateBudget}
            			/>
            	))}

            	</React.Fragment>
	);
};



export default withStyles(styles)(BudgetRowsGroup);

