import { RECEIVE_TRANSACTION_CATEGORIES } from '../actions/transactionCategories'

export default function transactionCategories (state = {}, action) {

  switch(action.type) {
    case RECEIVE_TRANSACTION_CATEGORIES :

      let newTransactionCategories = {}
      action.transactionCategories.map((tc) => newTransactionCategories[tc.transaction_category_id] = tc) 
      return {
        ...newTransactionCategories
      }
      
    default :
      return state
  }
}