import React from "react";
import { connect } from 'react-redux'
import AmountCard from '../components/AmountCard';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AccountAvatars from '../components/AccountAvatars'
import Hidden from '@material-ui/core/Hidden';
import {NavLink} from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import Paper from '@material-ui/core/Paper';
import Moment from 'react-moment';
import LastUpdatedDate from '../components/LastUpdatedDate'

const styles = theme => ({
  cellLessPadding: {
    paddingRight: 8,
    paddingLeft: 8,
  },
  negativeCell : {
    color: 'red',
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  unstyledLink: {
    color: 'inherit',
    textDecoration: 'inherit',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    },
  },
  accountName: {
  },
  balance: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    },
  },
  lastUpdated: {
    fontSize: '0.7rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem'
    },
  },
  closedDate: {
    fontStyle: "italic",
    color: "gray",
    fontSize: '0.7rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem'
    },
  },
});

const Credit = (props) => {
  const { space_id } = props.match.params;
  const { account_ids, accounts, dataReady, classes } = props

  var totalCredit = 0;
  var totalBalance = 0;
  

  account_ids.forEach((account_id) => {
    totalCredit = totalCredit + accounts[account_id].latest_limit
    totalBalance = totalBalance + accounts[account_id].latest_balance
  })

  var utilization = (-totalBalance / totalCredit) * 100;

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={4}>
          <AmountCard title="Available Credit" amount={totalCredit} showResult={dataReady} numberType={'money'} />
        </Grid>
        <Grid item xs={12} md={4}>
          <AmountCard title="Total Balance" amount={totalBalance} showResult={dataReady} numberType={'money'} />
        </Grid>
        <Grid item xs={12} md={4}>
          <AmountCard title="Credit Utilization" amount={utilization} showResult={dataReady} numberType={'percent'} />
        </Grid>
      </Grid>
      <Paper className={classes.paper}>
        {dataReady && account_ids.length === 0 &&
          <div>No accounts.</div>
        }
        {dataReady && account_ids.length > 0 &&
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <Hidden xsDown>
                  <TableCell className={classes.cellLessPadding} variant="head" align="left">Inst.</TableCell>
                </Hidden>
                <TableCell className={classes.cellLessPadding} variant="head" align="left">Name</TableCell>
                {/* 
                <Hidden xsDown>
                  <TableCell className={classes.cellLessPadding} variant="head" align="right">Annual Fee</TableCell>
                </Hidden>
              */}
                <TableCell className={classes.cellLessPadding} variant="head" align="right">Credit Limit</TableCell>           
                <TableCell className={classes.cellLessPadding} variant="head" align="right">Balance</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {account_ids.map(account_id => (
                <TableRow key={account_id} >
                <Hidden xsDown>
                  <TableCell className={classes.cellLessPadding} align="left">
                      <AccountAvatars account={accounts[account_id]} showUser={space_id} />
                  </TableCell>
                </Hidden>
                <TableCell className={classes.cellLessPadding} scope="row" >
                  <NavLink to={"/accountdetail/" + accounts[account_id].account_id} className={classes.unstyledLink}>
                    {accounts[account_id].name}
                  </NavLink>
                </TableCell>
                {/* 
                <Hidden xsDown>
                  <TableCell className={classes.cellLessPadding} align="right">
                    
                  </TableCell>
                </Hidden>
              */}

                <TableCell className={classes.cellLessPadding} align="right">
                  <div className={classes.balance}>
                    <NumberFormat value={accounts[account_id].latest_limit} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                  </div>
                </TableCell>

                <TableCell className={classes.cellLessPadding} align="right">
                  <div className={classes.balance}>
                  { accounts[account_id].latest_balance >= 0 &&
                  <div><NumberFormat value={accounts[account_id].latest_balance} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} /></div>
                  }
                  { accounts[account_id].latest_balance < 0 &&
                  <div><NumberFormat className={classes.negativeCell} value={accounts[account_id].latest_balance} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} /></div>
                  }
                  </div>
                  <div className={classes.lastUpdated}>
                      {!accounts[account_id].closed_date && 
                        <LastUpdatedDate last_updated_date={accounts[account_id].balance_last_updated} />
                      }
                      {accounts[account_id].closed_date &&
                        <span className={classes.closedDate}>Closed <Moment format="MMM YYYY">{accounts[account_id].closed_date}</Moment></span>
                      }
                  </div>
                </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        }
        </Paper>

    </div>
  );
};

const mapStateToProps = (state,props) => {
  const { space_id } = props.match ? props.match.params : {space_id: null};

  if (!space_id && (!state.user || !state.user.account_ids)) {
    return {
      account_ids: [],
      dataReady: false
    }
  }

  if (space_id && (!state.spaceAccounts || !state.spaceAccounts[space_id])) {
    return {
      account_ids: [],
      dataReady: false
    }
  }

  if (!state.accountTypes || Object.keys(state.accountTypes).length === 0 || !state.spaces || Object.keys(state.spaces).length === 0) {
    return {
      account_ids: [],
      dataReady: false
    }    
  }

  let account_ids = space_id ? state.spaceAccounts[space_id] : state.user.account_ids

  account_ids = account_ids.filter((account_id) => 
    state.accounts[account_id].account_subtype_id === 4
    && !state.accounts[account_id].is_deleted
    && !state.accounts[account_id].closed_date
    ).sort((a,b) => state.accounts[b].name.toUpperCase() > state.accounts[a].name.toUpperCase() ? -1 : 1 )


  return {
    account_ids: account_ids,
    dataReady: true,
    accounts: state.accounts
  }
};

export default connect(mapStateToProps)(withStyles(styles)(Credit));