import moment from 'moment'

export const getTransactionQueryParams = (filters, space_id) => {
	var queryParams = ''
	if (space_id) {
	  queryParams = queryParams + "/" + space_id
	}
	

	if (Object.keys(filters).length > 0) {

		var esc = encodeURIComponent;
		var query = Object.keys(filters)
		.map(k => esc(k) + '=' + esc(filters[k]))
		.join('&');

		queryParams = queryParams + "?" + query

	}

	return queryParams
};


export const filterTransactions = (transaction_ids, transactions, filters) => {
	// console.log(transaction_ids)
    Object.keys(filters).forEach((filter_name) => {
    	// console.log(filter_name)
    	// console.log(filters[filter_name])

        transaction_ids = transaction_ids.filter((transaction_id) => !transactions[transaction_id]['ignored'])

    	if (filter_name === 'start_date') {
    		transaction_ids = transaction_ids.filter((transaction_id) => moment(transactions[transaction_id].date_time) >= moment(filters[filter_name]))
    	} else if (filter_name === 'end_date') {
    		transaction_ids = transaction_ids.filter((transaction_id) => moment(transactions[transaction_id].date_time) <= moment(filters[filter_name]))
    	} else if (filter_name === 'limit') {
    		transaction_ids = transaction_ids
    	} else if (filter_name === 'is_reviewed') { 
    		transaction_ids = transaction_ids.filter((transaction_id) => transactions[transaction_id][filter_name] === filters[filter_name])
    	} else if (filter_name === 'search_term') {
            transaction_ids = transaction_ids.filter((transaction_id) => 
                (transactions[transaction_id].merchant_name && transactions[transaction_id].merchant_name.toLowerCase().includes(filters[filter_name].toLowerCase())) 
                || (transactions[transaction_id].original_name && transactions[transaction_id].original_name.toLowerCase().includes(filters[filter_name].toLowerCase()))
                || (transactions[transaction_id].notes && transactions[transaction_id].notes.toLowerCase().includes(filters[filter_name].toLowerCase())))
        } else { 
    		transaction_ids = transaction_ids.filter((transaction_id) => transactions[transaction_id][filter_name] === parseInt(filters[filter_name]))
    	}
    	// console.log(transaction_ids)
    })
    

    return transaction_ids.sort((a,b) => moment(transactions[b].date_time) - moment(transactions[a].date_time) )

}