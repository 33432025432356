import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Bar, BarChart, LabelList
} from 'recharts';
import Title from '../components/Title';
// import Hidden from '@material-ui/core/Hidden';


const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    // minWidth: 200,
    // width: '100%',
    // overflowX: 'auto',
  },
  selectMonths: {
    minWidth: 200,
    float: "right"
  },
  chartTooltip: {
  	margin: "0px",
  	padding: "10px",
  	backgroundColor: "white",
  	border: "1px solid rgb(204, 204, 204)",
  	whiteSpace: "nowrap"
  },
  chartTooltipLabel: {
  	margin: "0px",
  	fontWeight: "bold",
  },
  chartTooltipItemList: {
  	margin: "0px",
  	padding: "0px",
  },  
  chartTooltipItem: {
  	display: "block", 
  	paddingTop: "4px",
  	paddingBottom: "4px",
  	fontWeight: "bold",
  },
  chartTooltipItemTotal: {
  	display: "block", 
  	paddingTop: "4px",
  	paddingBottom: "4px",
  	fontWeight: "bold",
  }

});

// const colorArray = ['#4caf50','#f44336','#2196f3','#673ab7','#ff9800','#009688'];
const colorArray = ['#a5d6a7','#ef9a9a','#a4c2f4'];


const CashflowWaterfallChart = (props) => {
	const { classes } = props;
	const { dates, transactionCategorySpend, includeMajorPurchases } = props;

	let data = [
	{ 
		name: "Salary Income",
		category_ids: [17],
		type: "income"
	},
	{ 
		name: "Other Income",
		category_ids: [19,21],
		type: "income"
	},
	{ 
		name: "Taxes",
		category_ids: [16],
		type: "expense"
	},
	{ 
		name: "Housing and Bills",
		category_ids: [1,2],
		type: "expense"
	},
	{ 
		name: "Other Spending",
		category_ids: [3,4,5,6,7,8,9,10,11,12,13,14,15,28,29,30],
		type: "expense"
	},
	// { 
	// 	name: "Investment",
	// 	category_ids: [22],
	// 	type: "transfer"
	// }
	]

	let running_buffer = 0

	data.forEach((d) => {
		d[d.type] = 0
		d.buffer = running_buffer
		dates.forEach((date) => {
			d.category_ids.forEach((transaction_category_id) => {
				d[d.type] += transactionCategorySpend[transaction_category_id] && transactionCategorySpend[transaction_category_id][date] && (transaction_category_id !== 27 || includeMajorPurchases)
				? transactionCategorySpend[transaction_category_id][date] : 0
			})
		})
		running_buffer = d.buffer + d[d.type]
	})

	if (running_buffer !== 0)
	{
		data.push({
			name: "Cash",
			type: "transfer",
			buffer: running_buffer,
			transfer: -running_buffer,
		})
	}


	const CustomTooltip = ({ active, payload, label }) => {

	  if (active) {

	    return (
	      <div className={classes.chartTooltip}>
	      	<p className={classes.chartTooltipLabel}>
	      		{label} 
	      	</p>
	      	<ul className={classes.chartTooltipItemList}>

		      	{payload.filter((pld) => pld.value !== 0).filter((pld) => pld.name !== 'buffer').map((pld) => (
		      		<li key={pld.name} className={classes.chartTooltipItem} style={{color: `${pld.color}`}}>
		        		<span>
		        			{Intl.NumberFormat('en', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(pld.name === 'Transfer' ? -pld.value : pld.value)}
		        		</span>
	        		</li>
		        ))}
	      	</ul>
	      </div>
	    );
	  }

	  return null;
	};	


	const CustomLabel = (props) => {

		const { x, y, width, height, value, type } = props;

		if (!value || value === 0) {
			return null;
		}

		if (type === "income") {

		    return (
        		<g>
        		<text x={x + width / 2} y={y - 10} fill="#4caf50" textAnchor="middle" dominantBaseline="middle">
        			{Intl.NumberFormat('en', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value)}
        		</text>
        		</g>
		    );

		} else if (type === "expense") {
		    return (
        		<g>
        		<text x={x + width / 2} y={y + height - 10} fill="#f44336" textAnchor="middle" dominantBaseline="middle">
        			{Intl.NumberFormat('en', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value)}
        		</text>
        		</g>
		    );
		} else if (type === "transfer") {
		    return (
        		<g>
        		<text x={x + width / 2} y={y + height - 10} fill="#2196f3" textAnchor="middle" dominantBaseline="middle">
        			{Intl.NumberFormat('en', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0  }).format(-value)}
        		</text>
        		</g>
		    );
		}
		else {
			return null;
		}
	};	

	return(
			<React.Fragment>
		      <Paper className={classes.paper}> 
		      <Title>Cashflow</Title>
		  		<ResponsiveContainer width="95%" height={400}>
			    	<BarChart data={data}
			            margin={{top: 10, right: 0, left: 30, bottom: 0}}>
			        <CartesianGrid strokeDasharray="3 3"/>
			        <XAxis dataKey="name" />
			        <YAxis tickFormatter={(value) => new Intl.NumberFormat('en', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(value)} />
			        <Tooltip content={<CustomTooltip />} />
			        {/*<Tooltip formatter={(value) => new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(value)} /> */}
			        <Bar name={""} key={"buffer"} type='monotone' dataKey={"buffer"} stackId="1" fill="transparent" />
			        <Bar name={"Income"} key={"income"} type='monotone' dataKey={"income"} stackId="1" fill={colorArray[0]} isAnimationActive={false} >
			        		<LabelList dataKey="income" position="top" content={<CustomLabel type={"income"} />} />
			        </Bar>
			        <Bar connectNulls name={"Expense"} key={"expense"} type='monotone' dataKey={"expense"} stackId="1" fill={colorArray[1]} isAnimationActive={false}  >
			        		<LabelList dataKey="expense" position="top" content={<CustomLabel type={"expense"} />} />
			        </Bar>
			        <Bar connectNulls name={"Transfer"} key={"transfer"} type='monotone' dataKey={"transfer"} stackId="1" fill={colorArray[2]} isAnimationActive={false} >
			        		<LabelList dataKey="transfer" position="top" content={<CustomLabel type={"transfer"} />} />
			        </Bar>
			      </BarChart>
			     </ResponsiveContainer>
			  </Paper>
			</React.Fragment>
	);
};



export default withStyles(styles)(CashflowWaterfallChart);