import { call, put, takeEvery, select } from 'redux-saga/effects';
import { RECEIVE_TRANSACTION_QUERY, LOAD_TRANSACTION_QUERY, REQUEST_TRANSACTION_QUERY } from '../actions/transactionQueries'
import { API_PATH } from '../utilities/apiPath'

function* fetchTransactionQuery(action) {
  const token = action.token

  if (token) {
	  var endpoint = API_PATH + 'api/transactions';
	  const options = {
		  headers: {
		    Authorization: `Bearer ${token}`
		  }

	  }

	  endpoint = endpoint + action.queryParams

	  const response = yield call(fetch, endpoint, options);
	  const data = yield response.json();
	  yield put({ type: RECEIVE_TRANSACTION_QUERY, 
	  		transactionResponse: data, 
	  		queryParams: action.queryParams
	  		 });
	}
}

export function* loadTransactionQuery() {
  yield takeEvery(LOAD_TRANSACTION_QUERY, fetchTransactionQuery);
}

function* decideToFetchTransactionQuery(action) {
  const token = action.token
  const state = yield select();
  // console.log(state)

  if (state.transactionQueries[action.queryParams]) {
  	return
  } else {
  	yield put({ type: LOAD_TRANSACTION_QUERY, queryParams: action.queryParams, token})
  }

}


export function* requestTransactionQuery() {
	yield takeEvery(REQUEST_TRANSACTION_QUERY, decideToFetchTransactionQuery);	
}
