import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import Fab from '@material-ui/core/Fab';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


const styles = theme => ({
  settingsButton: {
  	// float: "right"
  	position: "fixed",
  	right: 20,
  	top: 20,
  	bottom: "auto",
  	left: "auto",
  	[theme.breakpoints.down('sm')]: {
      top: 58,
      right: 10
    },
  },
  popover: {
    padding: theme.spacing(2),
    minWidth: 200
  },  
   selectComp: {
    minWidth: 150,
  }, 
});


const BudgetSettings = (props) => {
	const { classes, toggleMajorPurchases } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [includeMajorPurchases, setIncludeMajorPurchases] = React.useState(true)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };	

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

   const handleChange = (event) => {
    setIncludeMajorPurchases(!includeMajorPurchases)
    toggleMajorPurchases()
  }; 

	return(
			<React.Fragment>
		      <Fab size="small" className={classes.settingsButton} onClick={handleClick}>
		        <SettingsIcon />
		      </Fab>
		      <Popover
		        id={id}
		        open={open}
		        anchorEl={anchorEl}
		        onClose={handleClose}
		        anchorOrigin={{
		          vertical: 'bottom',
		          horizontal: 'center',
		        }}
		        transformOrigin={{
		          vertical: 'top',
		          horizontal: 'center',
		        }}
		      >
		      	<div className={classes.popover}>
		        	<Typography variant="h6">Budget & Cashflow Settings</Typography>
		        		<div>
			        	<FormControlLabel
				        control={
				          <Checkbox
				            checked={includeMajorPurchases}
				            onChange={handleChange}
				            color="primary"
				          />
				        }
				        label="Include major purchases"
				      />
				      </div>
		        </div>
		      </Popover>
			</React.Fragment>
	);
};




export default withStyles(styles)(BudgetSettings);