import React from "react";
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Title from '../components/Title';
import {NavLink} from "react-router-dom";
import LastUpdatedDate from '../components/LastUpdatedDate'
import AccountAvatars from '../components/AccountAvatars'
import Moment from 'react-moment';
import PriorBalanceComparison from '../components/PriorBalanceComparison'

const styles = theme => ({
  cellLessPadding: {
    paddingRight: 0,
    paddingLeft: 8
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  unstyledLink: {
    color: 'inherit',
    textDecoration: 'inherit',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    },
  },
  balance: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    },
  },
  lastUpdated: {
    fontSize: '0.7rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem'
    },
  },
  priorBalance: {
    fontSize: '0.7rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem'
    },
  },
  negativeCell : {
    color: 'red',
  },
  closedDate: {
    fontStyle: "italic",
    color: "gray",
    fontSize: '0.7rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem'
    },
  },
  accountType: {
    fontStyle: "italic",
    color: "gray",
    fontSize: '0.7rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem'
    },
  },
  alignRight: {
    textAlign: "right"
  }  
});

const NetWorthTable = (props) => {
  const { classes } = props;

  const { subtype_id,
    accountTypes,
    account_subtypes,
    accounts,
    show_closed,
    comp_period } = props

  return (
            <Paper className={classes.paper} key={subtype_id}>
            <div>
              <Grid container justify="space-between" alignItems="center">
              <Grid item >
                <Title>{accountTypes[subtype_id].account_subtype_name}</Title>
              </Grid>
              <Grid item className={classes.alignRight} >
              <span >
              <Typography component="p" variant="h5">
                { account_subtypes[subtype_id].sub_category_total >= 0 &&
                <NumberFormat value={account_subtypes[subtype_id].sub_category_total} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                }
                { account_subtypes[subtype_id].sub_category_total < 0 &&
                <NumberFormat className={classes.negativeCell} value={account_subtypes[subtype_id].sub_category_total} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                }
              </Typography>
              {comp_period > -1 &&
                <PriorBalanceComparison days={comp_period} account_ids={account_subtypes[subtype_id].account_ids} />
              }
              </span>
              </Grid>
            </Grid>
            

            </div>
            <Table size="small" key={subtype_id}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cellLessPadding} variant="head" align="left"></TableCell>
                  <TableCell className={classes.cellLessPadding} variant="head" align="left"></TableCell>
                  <TableCell className={classes.cellLessPadding} variant="head" align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {account_subtypes[subtype_id].account_ids.filter((account_id) => show_closed || !accounts[account_id].closed_date).map(account_id => (
                  <TableRow key={account_id} >
                    <TableCell className={classes.cellLessPadding} component="th" scope="row" >
                      <AccountAvatars account={accounts[account_id]} showUser={true} />
                    </TableCell>
                    <TableCell className={classes.cellLessPadding} component="th" scope="row" >                        
                    <NavLink to={"/accountdetail/" + account_id} className={classes.unstyledLink}>
                      {accounts[account_id].name}
                    </NavLink>
                    <div className={classes.accountType}>
                      {accounts[account_id].account_category_id && accountTypes[accounts[account_id].account_subtype_id].account_categories[accounts[account_id].account_category_id].account_category_name} 
                    </div>
                    </TableCell>
                    <TableCell className={classes.cellLessPadding} align="right">
                      { accounts[account_id].latest_balance >= 0 &&
                        <div className={classes.balance}><NumberFormat value={accounts[account_id].latest_balance} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} /></div>
                      }
                      { accounts[account_id].latest_balance < 0 &&
                        <div className={classes.balance}><NumberFormat className={classes.negativeCell} value={accounts[account_id].latest_balance} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} /></div>
                      }
                      <div className={classes.priorBalance}>
                        {comp_period > -1 && 
                          <PriorBalanceComparison days={comp_period} account_ids={[account_id]} />
                        }
                      </div>
                      <div className={classes.lastUpdated}>
                          {!accounts[account_id].closed_date && 
                            <LastUpdatedDate last_updated_date={accounts[account_id].balance_last_updated} />
                          }
                          {accounts[account_id].closed_date &&
                            <span className={classes.closedDate}>Closed <Moment format="MMM YYYY">{accounts[account_id].closed_date}</Moment></span>
                          }
                      </div>                      
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
           
  );
};

export default withStyles(styles)(NetWorthTable);