export const LOAD_INITIAL_DATA = 'LOAD_INITIAL_DATA'
export const LOAD_USER_FINANCIAL_DATA = 'LOAD_USER_FINANCIAL_DATA'
export const LOAD_CATEGORY_DATA = 'LOAD_CATEGORY_DATA'


export function loadUserFinancialData (token) {
	return {
		type: LOAD_USER_FINANCIAL_DATA,
		token
	}
}

export function loadCategoryData (token) {
	return {
		type: LOAD_CATEGORY_DATA,
		token
	}
}

