import { RECEIVE_ACCOUNTS, 
  UPDATE_ACCOUNT, 
  UPDATE_ACCOUNT_FAILED, 
  REPAIR_ITEM, 
  REPAIR_ITEM_RESPONSE, 
  REPAIR_ITEM_FAILED,
  REFRESH_ITEM,
  RECEIVE_ACCOUNT,
  ADD_ACCOUNT_SUCCEEDED,
  ADD_PLAID_ACCOUNTS_SUCCEEDED,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_FAILED,
  UPDATE_ACCOUNT_LOCAL } from '../actions/accounts'
// import { RECEIVE_PENDING_TRANSACTIONS } from '../actions/pendingTransactions'
// import { RECEIVE_TRANSACTIONS } from '../actions/transactions'

export default function accounts (state = {}, action) {

  //let account_ids = Object.keys(state)
  //let newState = {}
  let new_state = {}

  switch(action.type) {
    case RECEIVE_ACCOUNTS :
      let accounts = {}
      action.accounts.map((account) => accounts[account.account_id] = account) 
      return {
        ...accounts
      }

    case UPDATE_ACCOUNT :
      return {
        ...state,
        [action.account.account_id] : {
          ...action.updatedAccount
        }
      }

    case UPDATE_ACCOUNT_LOCAL :
      return {
        ...state,
        [action.account.account_id] : {
          ...action.updatedAccount
        }
      }

    case UPDATE_ACCOUNT_FAILED :
      return {
        ...state,
        [action.account.account_id] : {
          ...action.account
        }
      }

    case ADD_ACCOUNT_SUCCEEDED :
      return {
        ...state,
        [action.account.account_id] : {
          ...action.account
        }
      }

    case ADD_PLAID_ACCOUNTS_SUCCEEDED :
      let newAccounts = {...state}

      action.accounts.forEach((account) => {
        newAccounts[account.account_id] = {...account}
      })
      return newAccounts

    case REPAIR_ITEM:
      new_state = {...state}
      let repairedAccountIds = Object.keys(state).filter((account_id) => state[account_id].plaid_account && state[account_id].plaid_account.plaid_item.item_id === action.item_id)
      repairedAccountIds.forEach((account_id) => {
        new_state[account_id] = {...state[account_id]}
        new_state[account_id].plaid_account.plaid_item.error_state = false
      })
      return {...new_state}

    case REPAIR_ITEM_RESPONSE:
      // console.log(action)
      if (!action.item.error_state) {
        return state
      }
      else {
        new_state = {...state}
        let unRepairedAccountIds = Object.keys(state).filter((account_id) => state[account_id].plaid_account && state[account_id].plaid_account.plaid_item.item_id === action.item.item_id)
        unRepairedAccountIds.forEach((account_id) => {
          new_state[account_id] = {...state[account_id]}
          new_state[account_id].plaid_account.plaid_item.error_state = action.item.error_state
        })
        return {...new_state}
      }


    case REPAIR_ITEM_FAILED:

      new_state = {...state}
      let unRepairedAccountIds2 = Object.keys(state).filter((account_id) => state[account_id].plaid_account && state[account_id].plaid_account.plaid_item.item_id === action.item_id)
      unRepairedAccountIds2.forEach((account_id) => {
        new_state[account_id] = {...state[account_id]}
        new_state[account_id].plaid_account.plaid_item.error_state = true
      })
      return {...new_state}
      

    case REFRESH_ITEM:
      new_state = {...state}
      let matchingAccountIds = Object.keys(state).filter((account_id) => state[account_id].plaid_account && state[account_id].plaid_account.plaid_item.item_id === action.item_id)
      matchingAccountIds.forEach((account_id) => {
        new_state[account_id] = {...state[account_id]}
        new_state[account_id].is_refreshing = true
      })
      return {...new_state}

    case RECEIVE_ACCOUNT:
      return {
        ...state,
        [action.account.account_id] : {
          ...action.account,
          is_refreshing: false
        }
      }

    case DELETE_ACCOUNT:
      return {
        ...state,
        [action.account_id] : {
          ...state[action.account_id],
          is_deleted: true,
          space_accounts: []
        }
      }

    case DELETE_ACCOUNT_FAILED:
      return {
        ...state,
        [action.account_id] : {
          ...state[action.account_id],
          is_deleted: false
        }
      }

    default :
      return state
  }
}