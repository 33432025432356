import { RECEIVE_ACCOUNT_TYPES } from '../actions/accountTypes'

export default function accountTypes (state = {}, action) {

  switch(action.type) {
    case RECEIVE_ACCOUNT_TYPES :

      let newAccountTypes = {}
      let newAccountCategories = {}
      action.accountTypes.forEach((at) => {
        newAccountCategories = {}
        at.account_categories.forEach((ac) => {
          newAccountCategories[ac.account_category_id] = ac
        })
        newAccountTypes[at.account_subtype_id] = {
          ...at,
          account_categories: {...newAccountCategories}
        }

      }) 
      return {
        ...newAccountTypes
      }
      
    default :
      return state
  }
}