import { USER_PROFILE_LOADED, 
  RECEIVE_USER_APP_DATA, 
  SET_USER, 
  UPDATE_USER_APP_DATA,
  UPDATE_USER_APP_DATA_FAILED } from '../actions/user';
import { RECEIVE_ACCOUNTS, ADD_ACCOUNT_SUCCEEDED } from '../actions/accounts'


export default function toDoApp(state = null, action) {
  switch (action.type) {

    case SET_USER:
      return {
        ...state,
        profile: {
          ...action.user
        }
      }

    case USER_PROFILE_LOADED:
      return action.user

    case RECEIVE_USER_APP_DATA:
      return {
        ...state,
        userApp: {
          ...action.user
        }
      }

    case UPDATE_USER_APP_DATA:
      return {
        ...state,
        userApp: {
          ...state.userApp,
          ...action.updatedUser
        }
      }

    case UPDATE_USER_APP_DATA_FAILED:
      return {
        ...state,
        userApp: {
          ...state.userApp,
          ...action.user
        }
      }      

    case RECEIVE_ACCOUNTS :
      const myAccounts = action.accounts.filter((account) => account.owner_user_id === action.user_id)
        .map((account) => account.account_id)
      return {
        ...state,
        account_ids: myAccounts
      }

    case ADD_ACCOUNT_SUCCEEDED:
      return {
        ...state,
        account_ids: state.account_ids.concat([action.account.account_id])
      }

    default:
      return state
  }
}