import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { API_PATH } from '../utilities/apiPath'
import { useAuth0 } from "@auth0/auth0-react";
import AddOfflineAccount from '../components/AddOfflineAccount'
import { withStyles } from '@material-ui/core/styles';
import { addPlaidAccountsSucceeded } from '../actions/accounts'
import Accounts from '../components/Accounts'
import { connect } from 'react-redux'

const styles = theme => ({
  addAccountDialog: {
    width: 500,
    height: 500
  },
});

const AddAccount = (props) => {
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState("main")
  const [newAccountIds, setNewAccountIds] = React.useState([])
  const [fullWidth, setFullWidth] = React.useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { dispatch } = props

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOffline = () => {
    setPage("offline");
    setFullWidth(true);
  };

  const handleOnSuccess = async (token, metadata) => {
    //setShowResult(true);
    //const auth_token = user.accessToken
    const auth_token = await getAccessTokenSilently();
    const response = await fetch(API_PATH + 'plaid/item', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth_token}`
      },
      body: JSON.stringify({
      public_token: token,
      metadata: metadata,
      })
    });
    const responseData = await response.json();
    await dispatch(addPlaidAccountsSucceeded(responseData))
    setNewAccountIds(responseData.map((account) => account.account_id))
    setOpen(true)
    setPage("plaid_confirm")
  }


  const handleOnExit = (err,metadata) => {
    // 2b. Gracefully handle the invalid link token error. A link token
      // can become invalidated if it expires, has already been used
      // for a link session, or is associated with too many invalid logins.
      if (err != null && err.error_code === 'INVALID_LINK_TOKEN') {
        // linkHandler.destroy();
        // linkHandler = Plaid.create({
        //   ...configs,
        //   token: await fetchLinkToken(),
        // });
      }
      if (err != null) {
        // Handle any other types of errors.
      }
      // metadata contains information about the institution that the
      // user selected and the most recent API request IDs.
      // Storing this information can be helpful for support.
    }

  const fetchLinkToken = async () => {
    const auth_token = await getAccessTokenSilently();
    const response = await fetch(API_PATH + 'plaid/link_token_create', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth_token}`
      },
      body: JSON.stringify({
        item_id: null,
      })
    });
    const responseJSON = await response.json();
    return responseJSON.link_token;
  };

  const plaidNewToken = async () => {
     // 1. Pass a new link_token to Link.
    const linkHandler = window.Plaid.create({
    token: await fetchLinkToken(),
    onSuccess: handleOnSuccess,
    onExit: handleOnExit,
    // receivedRedirectUri: window.location.href,
  })
    setOpen(false)
    linkHandler.open();   
  }


  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add Account
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md"  fullWidth={fullWidth} >
        <DialogTitle id="form-dialog-title">Add Account</DialogTitle>
        <DialogContent>
        { page === "main" && 
          <React.Fragment>
          <DialogContentText>
            Link an online account or add an offline one.
          </DialogContentText>
          <Button onClick={() => plaidNewToken()} color="primary"> 
            Link Account
          </Button>
          <Button onClick={handleOffline} color="primary">
            Add Offline Account
          </Button>
          </React.Fragment>
        }
        { page === "offline" &&
          <AddOfflineAccount />
        }
        { page === "plaid_confirm" &&
          <Accounts title="Accounts Added" show_add={false} edit_mode_default={true} account_ids_list={newAccountIds}/>
        }
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default connect()(withStyles(styles)(AddAccount))
