import { call, put, takeEvery } from 'redux-saga/effects';
import { LOAD_TRANSACTION_SPLIT_TEMPLATES, RECEIVE_TRANSACTION_SPLIT_TEMPLATES } from '../actions/transactionSplitTemplates'
import { API_PATH } from '../utilities/apiPath'

function* fetchTransactionSplitTemplates(action) {
  const token = action.token

  if (token) {
	  const endpoint = API_PATH + 'api/transactionsplittemplates';
	  const options = {
		  headers: {
		    Authorization: `Bearer ${token}`
		  }

	  }
	  const response = yield call(fetch, endpoint, options);
	  const data = yield response.json();
	  yield put({ type: RECEIVE_TRANSACTION_SPLIT_TEMPLATES, transactionSplitTemplates: data });
	}
}

export function* loadTransactionSplitTemplates() {
  yield takeEvery(LOAD_TRANSACTION_SPLIT_TEMPLATES, fetchTransactionSplitTemplates);
}