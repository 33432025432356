import { RECEIVE_USERS } from '../actions/users'

export default function spaceUsers (state = {}, action) {

  switch(action.type) {

    case RECEIVE_USERS :

      let spaceUsers = {}
      action.users.map((user) => user.space_users
        .map((s) => s.space_id)
        .map((space_id) => spaceUsers[space_id] = spaceUsers[space_id] ? spaceUsers[space_id].concat(user.user_id) : [user.user_id] ))

      return spaceUsers

    default :
      return state
  }
}