import { call, put, takeEvery  } from 'redux-saga/effects';
import { LOAD_ACCOUNT_TYPES, RECEIVE_ACCOUNT_TYPES } from '../actions/accountTypes'
import { API_PATH } from '../utilities/apiPath'

function* fetchAccountTypes(action) {
  const token = action.token

  if (token) {
	  const endpoint = API_PATH + 'api/accounttypes';
	  const options = {
		  headers: {
		    Authorization: `Bearer ${token}`
		  }

	  }
	  const response = yield call(fetch, endpoint, options);
	  const data = yield response.json();
	  yield put({ type: RECEIVE_ACCOUNT_TYPES, accountTypes: data });
	}
}

export function* loadAccountTypes() {
  yield takeEvery(LOAD_ACCOUNT_TYPES, fetchAccountTypes);
}