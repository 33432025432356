import React, { Fragment } from "react";
import TransactionTable from '../components/TransactionTable';
import Accounts from '../components/Accounts'
import NetWorth from '../components/NetWorth'
import moment from 'moment'

const Dashboard = (props) => {

	const { space_id } = props.match ? props.match.params : {space_id: null};

	const review_filters = {
		is_reviewed: false
	};

	const account_error_filters = {
		error_state: true,
		is_deleted: false,
		is_closed: false,
		source_type: "plaid",
	}

	const account_review_filters = {
		is_reviewed: false,
		is_deleted: false,
		is_closed: false
	}

	const account_balance_filters = {
		is_deleted: false,
		is_closed: false,
		last_updated: moment().subtract(14,'d'),
		source_type: "offline",
	}	

	return (
	  <div>
	  	<TransactionTable title="Transactions to Review" edit_mode_default={true} filters={review_filters} show_cards={false} space_id={space_id} hide_if_empty={true} />
	  	<Accounts title="Accounts in Error State" error_only={true} show_add={false} filters={account_error_filters} space_id={space_id} hide_if_empty={true} />
	  	<Accounts title="Accounts to Review" review_only={true} show_add={false} edit_mode_default={true} filters={account_review_filters} space_id={space_id} hide_if_empty={true} />
	  	<Accounts title="Accounts with Stale Balances" review_only={true} show_add={false} edit_mode_default={false} filters={account_balance_filters} space_id={space_id} hide_if_empty={true} />
	  
	  {space_id &&
	  	<Fragment>
	  		<NetWorth space_id={space_id} />
	  	</Fragment>
	  }
	  </div>
	)
}

export default Dashboard;