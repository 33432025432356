import React, {useState} from "react";
import TransactionTable from '../components/TransactionTable';
import moment from 'moment'
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { connect } from 'react-redux'
import Select from 'react-select';


const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  searchInput: {
    width: '100%',
  },
  categoryInput: {
    //width: 200,
    minWidth: "120px",
    // width: "100%",
    marginTop: "16px",
    marginBottom: "8px",
    marginRight: "8px",
    // marginLeft: "8px",
  },
  dateInput: {
  	width: '150px'
  }
});

const SearchableTransactions = (props) => {
	const { classes, space_id, title, initialFilters, transactionCategories } = props
	const [searchTerm, setSearchTerm] = useState("");
	const [searchFilters, setSearchFilters] = useState(initialFilters);
	const [startDate, setStartDate] = useState(initialFilters && initialFilters.start_date ? initialFilters.start_date : new Date(moment().subtract(30,'d')))
	const [endDate, setEndDate] = useState(initialFilters && initialFilters.end_date ? initialFilters.end_date : new Date(moment()))
	const [transactionCategory, setTransactionCategory] = useState(-1)

	let categoryOptions = [
	{ 
		value: -1,
		label: "All"
	}
	];



	Object.keys(transactionCategories).map( transactionCategoryId => ( 
	  categoryOptions.push({
	    value: transactionCategories[transactionCategoryId].transaction_category_id,
	    label: transactionCategories[transactionCategoryId].transaction_category_name
	  })
	  ))

	const handleCatChange = value => {
		setTransactionCategory(value.value);
	};

	const handleSearch = event => {
		event.preventDefault()
		var new_search_filters = {
			...initialFilters,
			search_term: searchTerm,
			start_date: moment(startDate).format('YYYY-MM-DD'),
			end_date: moment(endDate).format('YYYY-MM-DD'),
			limit: 100
		}

		if (transactionCategory !== -1) {
			new_search_filters['transaction_category_id'] = transactionCategory
		}

		setSearchFilters(new_search_filters)
	}

	const handleChange = event => {
		const {value} = event.target
		setSearchTerm(value);
	};

	const handleStartDateChange = (date) => {
		setStartDate(date);
	};

	const handleEndDateChange = (date) => {
		setEndDate(date);
	};

	return (
	  <div>
	  	<Paper className={classes.paper}>
	  		<form onSubmit={handleSearch}>
		  	<Grid container alignItems="center" justify="space-between">
		  		<Grid item xs={6} sm={4} lg={2} >
		  			<MuiPickersUtilsProvider utils={DateFnsUtils}>
			  			<KeyboardDatePicker
				          disableToolbar
				          className={classes.dateInput}
				          variant="inline"
				          format="MM/dd/yyyy"
				          margin="normal"
				          id="start-date"
				          label="Start Date"
				          value={startDate}
				          onChange={handleStartDateChange}
				          KeyboardButtonProps={{
				            'aria-label': 'change date',
				          }}
				        />
		        	</MuiPickersUtilsProvider>
		  		</Grid>
		  		<Grid item xs={6} sm={4} lg={2}>
		  			<MuiPickersUtilsProvider utils={DateFnsUtils}>
			  			<KeyboardDatePicker
				          disableToolbar
				          className={classes.dateInput}
				          variant="inline"
				          format="MM/dd/yyyy"
				          margin="normal"
				          id="end-date"
				          label="End Date"
				          value={endDate}
				          onChange={handleEndDateChange}
				          KeyboardButtonProps={{
				            'aria-label': 'change date',
				          }}
				        />
		        	</MuiPickersUtilsProvider>
		  		</Grid>
				<Grid item xs={6} sm={4} lg={3}>
	              <Select
	                className={classes.categoryInput}
	                value={{
	                  value: transactionCategory, 
	                  label: transactionCategory === -1 ? "All" : transactionCategories[transactionCategory].transaction_category_name}}
	                // defaultValue={element.transaction_category_id}
	                onChange={handleCatChange}
	                options={categoryOptions}
	                >

	              </Select>
              	</Grid>
		  		<Grid item xs={6} sm={6} lg={3}>
				    <TextField 
				      className={classes.searchInput} 
				      name={'transaction_search'} 
				      label="Search Transactions" 
				      value={searchTerm}
				      onChange={handleChange}
				    />
				</Grid>
		  		<Grid item xs={12} sm={6} lg={1}>
		  			<div>
			            <Button type="submit" variant="contained" color="primary">
			              Search
			            </Button>
		            </div>
				</Grid>
			</Grid>
			</form>
	    </Paper>
	    {searchFilters && 
		  	<TransactionTable title={title} edit_mode_default={false} filters={searchFilters} show_cards={false} space_id={space_id} />
		  }
	  </div>
	)
}

SearchableTransactions.defaultProps = {
  title: "Search Results",
}

const mapStateToProps = (state,props) => {
  return {
    transactionCategories: state.transactionCategories
  };
};

export default connect(mapStateToProps)(withStyles(styles)(SearchableTransactions));