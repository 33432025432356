
export const LOAD_PENDING_TRANSACTIONS = 'LOAD_PENDING_TRANSACTIONS'
export const RECEIVE_PENDING_TRANSACTIONS = 'RECEIVE_PENDING_TRANSACTIONS'


export function receivePendingTransactions (pendingTransactionResponse) {
	return {
		type: RECEIVE_PENDING_TRANSACTIONS,
		pendingTransactionResponse
	}
}

export function loadPendingTransactions () {
	return {
		type: LOAD_PENDING_TRANSACTIONS
	}
}