import { RECEIVE_TRANSACTION_RULES } from '../actions/spaceTransactionRules'

export default function spaceTransactionRules (state = {}, action) {

  switch(action.type) {
    case RECEIVE_TRANSACTION_RULES :

    	let space_rules = {}

    	action.rules.forEach((rule) => {
    		space_rules[rule.space_id] = space_rules[rule.space_id] ? space_rules[rule.space_id] : []
        space_rules[rule.space_id].push(rule)
    	})

      return {
      	...space_rules
      }
      
    default :
      return state
  }
}