export const USER_PROFILE_LOADED = 'USER_PROFILE_LOADED';
export const HANDLE_AUTHENTICATION_CALLBACK = 'HANDLE_AUTHENTICATION_CALLBACK';
export const LOAD_USER_APP_DATA = 'LOAD_USER_APP_DATA';
export const RECEIVE_USER_APP_DATA = 'RECEIVE_USER_APP_DATA';
export const SET_USER = 'SET_USER';
export const UPDATE_USER_APP_DATA = 'UPDATE_USER_APP_DATA'
export const UPDATE_USER_APP_DATA_FAILED = 'UPDATE_USER_APP_DATA_FAILED'


export function handleAuthenticationCallback() {
  return {
    type: HANDLE_AUTHENTICATION_CALLBACK
  };
}

export function setUser(user) {
  return {
    type: SET_USER,
    user
  };
}

export function loadUserAppData(token, user) {
	return {
		type: LOAD_USER_APP_DATA,
		token,
    user
	}
}

export function updateUserAppData (user, updatedUser, token) {
  return {
    type: UPDATE_USER_APP_DATA,
    user,
    updatedUser,
    token
  }
}