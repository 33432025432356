import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ProgressSpinner from '../utilities/ProgressSpinner'
import Paper from '@material-ui/core/Paper';
import { Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Bar, ComposedChart
} from 'recharts';
import { connect } from 'react-redux'
import moment from 'moment'


const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    // minWidth: 200,
    // width: '100%',
    // overflowX: 'auto',
  },
  selectMonths: {
    minWidth: 200,
    float: "right"
  },
  chartTooltip: {
  	margin: "0px",
  	padding: "10px",
  	backgroundColor: "white",
  	border: "1px solid rgb(204, 204, 204)",
  	whiteSpace: "nowrap"
  },
  chartTooltipLabel: {
  	margin: "0px",
  	fontWeight: "bold",
  },
  chartTooltipItemList: {
  	margin: "0px",
  	padding: "0px",
  },  
  chartTooltipItem: {
  	display: "block", 
  	paddingTop: "4px",
  	paddingBottom: "4px",
  },
  chartTooltipItemTotal: {
  	display: "block", 
  	paddingTop: "4px",
  	paddingBottom: "4px",
  	fontWeight: "bold",
  }

});

// const colorArray = ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', 
// '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff',
// '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', 
// '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000'];

// const colorArray = ['#80cbc4','#80deea','#b39ddb','#9fa8da','#ffe082','#b0bec5'];
// const colorArray = ['#c5e1a5','#ef9a9a','#90caf9','#b39ddb','#ffe082','#b0bec5'];
// Google Pallette - 500 level
const colorArray = ['#4caf50','#f44336','#2196f3','#673ab7','#ff9800','#009688'];


const ScenarioDetailCashflowChart = (props) => {
	const { classes } = props;
	const { dataReady, formattedTimeSeries } = props


	const CustomTooltip = ({ active, payload, label }) => {

	  if (active) {

	    return (
	      <div className={classes.chartTooltip}>
	      	<p className={classes.chartTooltipLabel}>
	      		{label} 
	      	</p>
	      	<ul className={classes.chartTooltipItemList}>

		      	{payload.map((pld) => (
		      		<li key={pld.name} className={classes.chartTooltipItem} style={{color: `${pld.color}`}}>
		      			<span>
		      				{pld.name}
		        		</span>
		        		<span>
		        			{` : `}
		        		</span>
		        		<span>
		        			{Intl.NumberFormat('en', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(pld.value)}
		        		</span>
	        		</li>
		        ))}
	      	</ul>
	      </div>
	    );
	  }

	  return null;
	};	

	return(
			<React.Fragment>
		      <Paper className={classes.paper}> 
				{!dataReady &&
		        	<ProgressSpinner />
		  		}
		  		{dataReady &&
		  		<ResponsiveContainer width="95%" height={400}>
			    	<ComposedChart data={formattedTimeSeries}
			            margin={{top: 10, right: 0, left: 30, bottom: 0}}>
			        <CartesianGrid strokeDasharray="3 3"/>
			        <XAxis dataKey="date" tickFormatter={(value) => moment(value).format('YYYY')} />
			        <YAxis tickFormatter={(value) => new Intl.NumberFormat('en', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(value)} />
			        <Tooltip content={<CustomTooltip />} />
			        {/*<Tooltip formatter={(value) => new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(value)} /> */}
			        <Legend />
			        <Line connectNulls name={"Cash Flow"} key={"cash_flow"} type='monotone' dataKey={"cash_flow"} stroke={colorArray[0]} fill={colorArray[0]} dot={false} strokeWidth={3} />
			        <Bar connectNulls name={"Regular Spending"} key={"regular_spending"} type='monotone' dataKey={"regular_spending"} stackId="1" stroke={colorArray[1]} fill={colorArray[1]} dot={false} strokeWidth={1} />
			        <Bar connectNulls name={"Additional Spending"} key={"additional_spending"} type='monotone' dataKey={"additional_spending"} stackId="1" stroke={colorArray[2]} fill={colorArray[2]} dot={false} strokeWidth={1} />
			        {/* <Bar connectNulls name={"Gross Income"} key={"gross_income"} type='monotone' dataKey={"gross_income"} stackId="1" stroke={colorArray[3]} fill={colorArray[3]} dot={false} strokeWidth={1} /> */}
			        <Bar connectNulls name={"Net Income"} key={"net_income"} type='monotone' dataKey={"net_income"} stackId="2" stroke={colorArray[4]} fill={colorArray[4]} dot={false} strokeWidth={1} />
			      </ComposedChart>
			     </ResponsiveContainer>
			    } 
			  </Paper>
			</React.Fragment>
	);
};

const mapStateToProps = (state, props) => {
  const { scenario_id } = props;
  const { scenarios } = state


  if (!scenarios) {
    return {
      dataReady: false
    }
  }


	let data = {}

	scenarios[scenario_id].scenario_calculation_cashflow.forEach((cashflow) => {
		let year = moment(cashflow.date).format('YYYY')
		if (!data[year]) {
			data[year] = {}
			data[year]['date'] = year
			data[year]["cash_flow"] = 0
			data[year]["regular_spending"] = 0
			data[year]["additional_spending"] = 0
			data[year]["gross_income"] = 0
			data[year]["net_income"] = 0		
		}
		data[year]["cash_flow"] += cashflow.cash_flow
		data[year]["regular_spending"] += -cashflow.regular_spending
		data[year]["additional_spending"] += -cashflow.additional_spending
		data[year]["gross_income"] += cashflow.gross_income
		data[year]["net_income"] += cashflow.net_income

	})



  return {
    dataReady: true,
    // formattedTimeSeries: scenarios[scenario_id].scenario_calculation_cashflow,
    formattedTimeSeries: Object.values(data)
  };
};



export default connect(mapStateToProps)(withStyles(styles)(ScenarioDetailCashflowChart));