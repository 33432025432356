
export const LOAD_ACCOUNTS = 'LOAD_ACCOUNTS'
export const RECEIVE_ACCOUNTS = 'RECEIVE_ACCOUNTS'
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT'
export const UPDATE_ACCOUNT_FAILED = 'UPDATE_ACCOUNT_FAILED'
export const ADD_ACCOUNTS = 'ADD_ACCOUNTS'
export const ADD_ACCOUNT = 'ADD_ACCOUNT'
export const ADD_ACCOUNT_SUCCEEDED = 'ADD_ACCOUNT_SUCCEEDED'
export const ADD_ACCOUNT_FAILED = 'ADD_ACCOUNT_FAILED'
export const ADD_ACCOUNT_CLEAR = 'ADD_ACCOUNT_CLEAR'
export const ADD_PLAID_ACCOUNTS_SUCCEEDED = 'ADD_PLAID_ACCOUNTS_SUCCEEDED'
export const UPDATE_ACCOUNT_SUCCEEDED = 'UPDATE_ACCOUNT_SUCCEEDED'
export const REPAIR_ITEM = 'REPAIR_ITEM'
export const REPAIR_ITEM_RESPONSE = 'REPAIR_ITEM_RESPONSE'
export const REPAIR_ITEM_FAILED = 'REPAIR_ITEM_FAILED'
export const REFRESH_ITEM = 'REFRESH_ITEM'
export const REFRESH_ITEM_SUCCEEDED = 'REFRESH_ITEM_SUCCEEDED'
export const REFRESH_ITEM_FAILED = 'REFRESH_ITEM_FAILED'
export const LOAD_ACCOUNT = 'LOAD_ACCOUNT'
export const RECEIVE_ACCOUNT = 'RECEIVE_ACCOUNT'
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const DELETE_ACCOUNT_SUCCEEDED = 'DELETE_ACCOUNT_SUCCEEDED'
export const DELETE_ACCOUNT_FAILED = 'DELETE_ACCOUNT_FAILED'
export const UPDATE_ACCOUNT_LOCAL = 'UPDATE_ACCOUNT_LOCAL'



export function receiveAccounts (accounts) {
	return {
		type: RECEIVE_ACCOUNTS,
		accounts
	}
}

export function loadAccounts () {
	return {
		type: LOAD_ACCOUNTS
	}
}

export function updateAccount (account, updatedAccount, token) {
	return {
		type: UPDATE_ACCOUNT,
		account,
		updatedAccount,
		token
	}
}

export function updateAccountLocalState (account, updatedAccount, token) {
	return {
		type: UPDATE_ACCOUNT_LOCAL,
		account,
		updatedAccount
	}
}

export function updateAccountFailed (account) {
	return {
		type: UPDATE_ACCOUNT_FAILED,
		account
	}
}

export function updateAccountSucceeded (account) {
	return {
		type: UPDATE_ACCOUNT_SUCCEEDED,
		account
	}
}

export function repairItem(item_id, token) {
	return {
		type: REPAIR_ITEM,
		item_id,
		token
	}
}

export function refreshItem(item_id, token) {
	return {
		type: REFRESH_ITEM,
		item_id,
		token
	}
}

export function addAccount (account, token) {
	return {
		type: ADD_ACCOUNT,
		account,
		token
	}
}

export function addAccountSucceeded (account) {
	return {
		type: ADD_ACCOUNT_SUCCEEDED,
		account,
	}
}

export function addAccountClear () {
	return {
		type: ADD_ACCOUNT_CLEAR
	}
}

export function addPlaidAccountsSucceeded (accounts) {
	return {
		type: ADD_PLAID_ACCOUNTS_SUCCEEDED,
		accounts
	}
}

export function deleteAccount (account_id, token) {
	return {
		type: DELETE_ACCOUNT,
		account_id,
		token
	}
}