// src/components/Profile.js

import React, { Fragment, useState } from "react";
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Title from '../components/Title';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import InputAdornment from '@material-ui/core/InputAdornment';
import moment from 'moment'
import { updateUserAppData } from '../actions/user'
import { useAuth0 } from "@auth0/auth0-react";
import NumberFormat from 'react-number-format';
import Avatar from '@material-ui/core/Avatar';

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  iconMargin: {
    marginBottom: "0.35em",
  },  
  field : {
    marginTop: theme.spacing(2),
  },
  fieldLabel: {
    fontWeight: "bold",
  },
  nameInput: {
    marginRight: "8px",
    width: '250px'
  },
  dateInput: {
    width: '250px'
  },
  incomeInput: {
    width: '250px'
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },  
});



const Profile = (props) => {
  const { user, userApp, classes, dispatch } = props
  const [editMode, setEditMode] = useState(false);
  const [newUser, setNewUser] = useState(userApp);
  const { getAccessTokenSilently } = useAuth0();

  const name = userApp.first_name + ' ' + userApp.last_name

  function enterEditMode() {
    setEditMode(true);
  }

  const handleChange = event => {
    const {name, value} = event.target
    const updatedUser = {
      ...newUser,
      [name]: value
    }
    setNewUser(updatedUser)
  };

  const handleBirthdayChange = (date) => {
    const updatedUser = {
      ...newUser,
      birthday: moment(date).format('YYYY-MM-DD'),
    }
    setNewUser(updatedUser)
  };

  async function submitEdits(event) {
    event.preventDefault()
    const token = await getAccessTokenSilently();
    dispatch(updateUserAppData(userApp, newUser, token))
    setEditMode(false)
  }

  const inputProps = {
    step: 0.01,
  };

    return (
      <Fragment>
        <Paper className={classes.paper}>
          <Grid container item alignItems="center" justify="flex-start" xs={6}>
            <Grid item >
              <Title>User Profile</Title>
            </Grid>
            <Grid item>
                {!editMode && 
                <IconButton className={classes.iconMargin} onClick={enterEditMode}>
                    <EditIcon fontSize="small"/>
                </IconButton>   
              }
            </Grid>
          </Grid>
          {userApp.profile_image_url && 
            <Avatar alt={name} src={userApp.profile_image_url} className={classes.avatar} />
          }
          <div className={classes.field}>
            <span className={classes.fieldLabel}>{user.profile.email}</span>
          </div>
          <form onSubmit={submitEdits}>
            <div className={classes.field}>
              
              {!editMode && 
                <Fragment>
                  <span className={classes.fieldLabel}>Name: </span>
                  <span>{userApp.first_name} {userApp.last_name}</span>
                </Fragment>
              }
              {editMode &&
                <Fragment>
                  <TextField 
                    required
                    className={classes.nameInput} 
                    id="first_name"
                    name="first_name"
                    label="First" 
                    defaultValue={newUser.first_name}
                    onChange={handleChange}
                  />
                  <TextField 
                    required
                    className={classes.nameInput} 
                    id="last_name"
                    name="last_name"
                    label="Last" 
                    defaultValue={newUser.last_name}
                    onChange={handleChange}
                  />
                </Fragment>
              }

            </div>
            <div className={classes.field}>
              {!editMode && 
                <Fragment>
                  <span className={classes.fieldLabel}>Birthday: </span>
                  <span>{moment(userApp.birthday).format('MM/DD/YYYY')}</span>
                </Fragment>
              }
              {editMode &&
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    disableToolbar
                    className={classes.dateInput}
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="birthday"
                    label="Birthday"
                    value={moment(newUser.birthday).format('MM/DD/YYYY')}
                    onChange={handleBirthdayChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              }
                
            </div>
            <div className={classes.field}>
              {!editMode && 
                <Fragment>
                  <span className={classes.fieldLabel}>Yearly Salary: </span>
                  <span><NumberFormat value={userApp.yearly_salary} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} /></span>
                </Fragment>
              }
              {editMode &&
                <TextField
                  required
                  type="number"
                  inputProps={inputProps}
                  label="Yearly Salary"
                  id="yearly_salary"
                  name="yearly_salary"
                  className={classes.incomeInput}
                  defaultValue={newUser.yearly_salary}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              }
            </div>
            <div className={classes.field}>
              {!editMode && 
                <Fragment>
                  <span className={classes.fieldLabel}>Yearly Other Income (Taxable): </span>
                  <span><NumberFormat value={userApp.yearly_income_other_taxable} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} /></span>
                </Fragment>
              }
              {editMode &&
                <TextField
                  required
                  type="number"
                  inputProps={inputProps}
                  label="Yearly Other Income (Taxable)"
                  id="yearly_income_other_taxable"
                  name="yearly_income_other_taxable"
                  className={classes.incomeInput}
                  defaultValue={newUser.yearly_income_other_taxable}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              }
            </div>
            <div className={classes.field}>
              {!editMode && 
                <Fragment>
                  <span className={classes.fieldLabel}>Yearly Other Income (Non-Taxable): </span>
                  <span><NumberFormat value={userApp.yearly_income_other_nontaxable} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} /></span>
                </Fragment>
              }
              {editMode &&
                <TextField
                  required
                  type="number"
                  inputProps={inputProps}
                  label="Yearly Other Income (Non-Taxable)"
                  id="yearly_income_other_nontaxable"
                  name="yearly_income_other_nontaxable"
                  className={classes.incomeInput}
                  defaultValue={newUser.yearly_income_other_nontaxable}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              }
            </div>
            {editMode &&
              <div className={classes.submitButton}>
                <Button type="submit" color="primary" variant="contained">
                  Save
                </Button>
              </div>
            }
          </form>
        </Paper>
      </Fragment>

    );
}

const mapStateToProps = state => {
  return {
    user: state.user,
    userApp: state.user.userApp
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Profile));