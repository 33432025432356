import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import { useAuth0 } from "@auth0/auth0-react";
import { connect } from 'react-redux'
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import { updateTransaction } from '../actions/transactions'

const DeleteTransaction = (props) => {
  const [open, setOpen] = useState(false);
  const { transaction, dispatch } = props
  const { getAccessTokenSilently } = useAuth0();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function submitDeleteTransaction() {
    const token = await getAccessTokenSilently();

    var updatedTransaction = {
      ...transaction,
      ignored: true
    }

    await dispatch(updateTransaction(transaction, updatedTransaction, token))

  }


  return (
    <span>
      <IconButton onClick={handleClickOpen}>
        <DeleteOutlineIcon fontSize="small" />  
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Delete Transaction</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>Deleting the transaction will hide it from all views and remove it from all aggregations.</span>
            <br />
            <br />
            <span>Delete transaction from {transaction.merchant_name} on <Moment format="ddd, MMM D">{transaction.date_time}</Moment> for <NumberFormat value={transaction.amount} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />?
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={submitDeleteTransaction} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}

export default connect()(DeleteTransaction);
