import React from "react";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Title from './Title';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Moment from 'react-moment';
import { connect } from 'react-redux'
import ProgressSpinner from '../utilities/ProgressSpinner'
import AccountAvatars from '../components/AccountAvatars'


const styles = theme => ({
  negativeCell : {
    color: 'red',
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    minWidth: 200,
  },
  avatar : {
    width: 24,
    height: 24,
    backgroundColor: 'teal',
    fontSize: '1rem',
  },
  userAvatar : {
    width: 24,
    height: 24,
    fontSize: '1rem',
    marginLeft: 8,
  },
  logo : {
    width: 24,
    marginLeft: 8,
  },
});

const AccountSummaryCard = (props) => {
  const { classes  } = props;
  const { account } = props

  return (
    <React.Fragment>
    <Paper className={classes.paper}>
      <Title>Account Summary</Title>
      {!account &&
      <ProgressSpinner />

      }
      {account && 
      <React.Fragment>
        <Grid container>
          <Grid item>
            <Typography component="p" variant="h5">{account.name}</Typography>
          </Grid>
          <Grid item>
            <AccountAvatars account={account} showUser={true} size={"medium"} />
          </Grid>
        </Grid>
        <Typography component="p" variant="h6">
          <NumberFormat value={account.latest_balance} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
        </Typography>
        {!account.closed_date &&
          <Typography component="p" variant="caption">
             (Updated <Moment fromNow>{account.balance_last_updated}</Moment>)
          </Typography>
        }
        {account.closed_date &&
          <Typography component="p" variant="caption">
             (Closed <Moment fromNow>{account.closed_date}</Moment>)
          </Typography>
        }
      </React.Fragment>
    }
    </Paper>
    </React.Fragment>
  );
}

const mapStateToProps = (state,props) => {

  return {
    account: state.accounts[props.account_id]
  };
};


export default connect(mapStateToProps)(withStyles(styles)(AccountSummaryCard));