import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { API_PATH } from '../utilities/apiPath'
import { useAuth0 } from "@auth0/auth0-react";
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import ProgressSpinner from '../utilities/ProgressSpinner'
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment'
import { connect } from 'react-redux'
import { updateAccountLocalState } from '../actions/accounts'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'; 
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  dialogSubtitle: {
    fontWeight: "bold",
    color: "black"
  },
  submitButton: {
    marginTop: "16px",
    marginBottom: "16px",
  },
  paper: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  balanceInput: {
    width: '200px'
  },
  dateInput: {
    width: '200px',
    marginLeft: '24px',
  },
  formContainer: {
    marginTop: '16px',
  },
});

const AddAccountData = (props) => {
  const { classes, account_id, account, showButton = true } = props
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState("main")
  const [file, setFile] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const { getAccessTokenSilently } = useAuth0();
  const [balance, setBalance] = React.useState(account ? account.latest_balance : null);
  const [balanceDate, setBalanceDate] = React.useState(new Date(moment()))
  const { dispatch } = props


  const inputProps = {
    step: 0.01,
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function handleSubmitUploadBalances(event) {
    event.preventDefault()
    setLoading(true)
    const token = await getAccessTokenSilently();
    const formData = new FormData();
    let fileToSend = file
    formData.append('file', fileToSend);
    formData.append('filename', 'test.csv');

    await fetch(API_PATH + 'api/account/' + account_id + '/uploadbalances', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: formData
    });

    // console.log(response)
    setOpen(false)    
    setLoading(false)

  }


 async function handleSubmitAddBalance(event) {
    event.preventDefault()
    setLoading(true)
    const token = await getAccessTokenSilently();

    var formattedDate = moment(balanceDate).format('YYYY-MM-DD HH:mm:ss')

    if (moment(balanceDate).format('YYYY-MM-DD') === moment(new Date(moment())).format('YYYY-MM-DD')) {
      formattedDate = moment(balanceDate).utc().format('YYYY-MM-DD HH:mm:ss')
    } 

    var response = await fetch(API_PATH + 'api/account/' + account_id + '/addbalance', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        balance: balance,
        balance_date: formattedDate
      })
    });

    var data = await response.json()

    // console.log(response)
    setOpen(false)    
    setLoading(false)

    await dispatch(updateAccountLocalState(account, data, token))



  }

  const handleBalanceChange = (event) => {
    // const {name, value} = event.target
    const {value} = event.target
    setBalance(Number(value));
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0])
  }

  const handleBalanceDateChange = (date) => {
    setBalanceDate(date);
  };


  return (
    
    <span>
      {showButton &&
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add Account Data
      </Button>
      }
      {!showButton &&
       <Tooltip title={`Update Account Balance`} arrow>
        <span>
          <IconButton onClick={handleClickOpen}>
            <AttachMoneyIcon fontSize="small" />
          </IconButton>
        </span>
      </Tooltip>  
      }
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth >
        <DialogTitle id="form-dialog-title">
            <Grid container alignItems="center" justify="space-between">
              <Grid item >
                <div>Add Account Data</div>
              </Grid>
              {page !== "main" && 
              <Grid item>
                <IconButton onClick={() => setPage("main")}>
                  <ArrowBackIcon fontSize="small" />
                </IconButton>
                </Grid>
              }
              </Grid>
        </DialogTitle>
        <DialogContent>
        { page === "main" && 
          <React.Fragment>
          <DialogContentText>
            Add or upload account balances or transactions.
          </DialogContentText>
          <Button onClick={() => setPage("uploadBalances")} color="primary"> 
            Upload Balances
          </Button>
          <Button onClick={() => setPage("addBalance")} color="primary">
            Add Balance
          </Button>
          <Button onClick={() => setPage("uploadTransactions")} color="primary"> 
            Upload Transactions
          </Button>
          <Button onClick={() => setPage("addTransaction")} color="primary">
            Add Transaction
          </Button>
          </React.Fragment>
        }
        { page === "uploadBalances" &&
          <React.Fragment>
            <DialogContentText>
              <span className={classes.dialogSubtitle}>
                Upload Balances
              </span>
              <br />
              <span>
                Files should be in CSV format with columns for date and balance. 
                Date should be in the format YYYY-MM-DD and balance should be a decimal with no commas or currency symbols.
              </span>
            </DialogContentText>

            {!loading && 
            <form onSubmit={handleSubmitUploadBalances}>
              <div>
                <Input type="file" onChange={handleFileChange} />
              </div>
              <div className={classes.submitButton}>
                <Button type="submit" variant="contained" color="primary">
                  Upload
                </Button>
              </div>
            </form>
          }
          {loading && 
            <ProgressSpinner />
          }
          </React.Fragment>
        }
        { page === "addBalance" &&
          <React.Fragment>
            <DialogContentText>
              <span className={classes.dialogSubtitle}>
                Add a Balance
              </span>
              <br />
              <span>
                Add a manual account balance.
                The account's current balance will be updated if the balance added is the most recent.
              </span>   
              </DialogContentText> 
              {!loading &&          
                <form onSubmit={handleSubmitAddBalance}>
                  <div className={classes.formContainer}>
                  <Grid container alignItems="center">
                    <Grid item>
                      <TextField
                        required
                        // type="number"
                        // inputProps={inputProps}
                        // inputProps={{ inputMode: 'numeric', pattern: '(\-)?+[0-9]+(\.[0-9][0-9])?' }}
                        // inputProps={{ inputMode: 'numeric' }}
                        label="Account Balance"
                        id="new-account-balance"
                        className={classes.balanceInput}
                        defaultValue={balance}
                        onChange={handleBalanceChange}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item >
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            className={classes.dateInput}
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="balance-date"
                            label="Date"
                            value={balanceDate}
                            onChange={handleBalanceDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                  </div>
                  <Button type="submit" color="primary">
                    Add Balance
                  </Button>
                </form>
              }
            {loading && 
              <ProgressSpinner />
            }
            
          </React.Fragment>
        }
        { page === "uploadTransactions" &&
          <React.Fragment>
            <DialogContentText>
              <span className={classes.dialogSubtitle}>
                Upload Transactions
              </span>
              <br />
              <span>
                Not yet implemented.
              </span>
            </DialogContentText>
          </React.Fragment>
        }
        { page === "addTransaction" &&
          <React.Fragment>
            <DialogContentText>
              <span className={classes.dialogSubtitle}>
                Add a Transaction
              </span>
              <br />
              <span>
                Not yet implemented.
              </span>
            </DialogContentText>
          </React.Fragment>
        }
        </DialogContent>
      </Dialog>
    </span>
  );
}

const mapStateToProps = (state, props) => {
  // const { space_id } = props.match ? props.match.params : {space_id: null};
  const { account_id } = props

  var account = state.accounts[account_id]

  return {
    account
  }
};

export default connect(mapStateToProps)(withStyles(styles)(AddAccountData))
