import React, { useState, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
// import CheckIcon from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton';
// import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
// import NoteAddIcon from '@material-ui/icons/NoteAdd';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Popover from '@material-ui/core/Popover';
import AddRule from '../components/AddRule'
import Badge from '@material-ui/core/Badge';
import { connect } from 'react-redux'
import { updateTransaction } from '../actions/transactions'
import { useAuth0 } from "@auth0/auth0-react";
import CheckIcon from '@material-ui/icons/Check';
import SplitTransaction from '../components/SplitTransaction'
import DeleteTransaction from '../components/DeleteTransaction'
import Hidden from '@material-ui/core/Hidden';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 600,
  },
  header: {
    paddingBottom: 12,
  },
  cellLessPadding: {
    paddingRight: 24,
  },  
  negativeCell : {
    color: 'red',
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  nameInput: {
    // width: 300,
    width: "100%",
    fontSize: "0.875rem",
    marginBottom: 4,
    marginTop: 4,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    },
  },
  notesInput: {
    // width: 150,
  },
  categoryInput: {
    //width: 200,
    minWidth: "120px",
    width: "100%",
    marginBottom: 4,
    marginTop: 4,
  },
  checkIcon : {
    color: 'green',
  },
  typography: {
    padding: theme.spacing(2),
  },
  iconCell: {
    paddingRight: 24,
    paddingTop: 0,
    paddingBottom: 0,
  },  
  smallBadge: {
    padding: '0 4px',
    border: `2px solid ${theme.palette.background.paper}`,
  },
  accountName: {
    fontStyle: "italic",
    color: "gray",
    fontSize: '0.7rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem'
    },
  },
  date: {
    fontStyle: "italic",
    color: "gray",
    fontSize: '0.7rem',
  },
  category: {
    // fontStyle: "italic",
    // color: "gray",
    // fontSize: '0.7rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    },
  },
  merchantName: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    },
  },
  amount: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    },
  },
  iconLeft: {
    paddingLeft: 0
  }
});



const TransactionRow = (props) => {
	const { classes, space_id, edit_mode } = props;
  const { transaction, transactionCategories, dispatch } = props
  const [editMode, setEditMode] = useState(edit_mode)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElRule, setAnchorElRule] = React.useState(null);
  const [updatedTransaction, setUpdatedTransaction] = useState({...transaction})

  const { getAccessTokenSilently } = useAuth0();

  let categoryOptions = [];

  Object.keys(transactionCategories).map( transactionCategoryId => ( 
      categoryOptions.push({
        value: transactionCategories[transactionCategoryId].transaction_category_id,
        label: transactionCategories[transactionCategoryId].transaction_category_name
      })
      ))

  const StyledBadge = withStyles(theme => ({
    badge: {
      // right: -3,
      // top: 13,
      border: `3px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
      fontSize: '0.5rem',
    },
  }))(Badge);

  async function submitEdits() {
    setEditMode(false);
    const token = await getAccessTokenSilently();
    dispatch(updateTransaction(transaction, updatedTransaction, token))
    
  }

  function enterEditMode() {
    setEditMode(true);
  }

   const handleChange = event => {
    const new_transaction = {
      ...updatedTransaction,
      merchant_name: event.target.value,
    }
    setUpdatedTransaction(new_transaction);
  };

  const handleNotesChange = event => {
    const new_transaction = {
      ...updatedTransaction,
      notes: event.target.value,
    }
    setUpdatedTransaction(new_transaction);
  };

  const handleCatChange = value => {
    const new_transaction = {
      ...updatedTransaction,
      transaction_category_id: value.value
    }
    setUpdatedTransaction(new_transaction);
  };

  const handleInfoClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleInfoClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'details' : undefined;

  const handleRuleClick = event => {
    setAnchorElRule(event.currentTarget);
  };

  const handleRuleClose = () => {
    setAnchorElRule(null);
  };

  const openRule = Boolean(anchorElRule);
  const idRule = openRule ? 'rule' : undefined;


	return(
        <TableRow key={transaction.transaction_id} >
          <Hidden smDown>
          <TableCell className={classes.cellLessPadding} align="left">
            <div className={classes.merchantName}>
            {editMode &&
              <TextField 
                  InputProps={{
                    classes: {
                      input: classes.nameInput,
                    },
                  }}
                  className={classes.nameInput} 
                  id={transaction.transaction_id_string} 
                  // label={transaction.original_name} 
                  label=""
                  defaultValue={updatedTransaction.merchant_name}
                  onChange={handleChange}
                  size="small"
                />
            }
            {!editMode && transaction.merchant_name}
            </div>
            <div className={classes.accountName} >
              {transaction.account.name}
            </div>
          </TableCell>
          </Hidden>
          <Hidden smDown>
          <TableCell className={classes.cellLessPadding} align="left">
            <div className={classes.category}>
            {editMode &&
              <Select
                className={classes.categoryInput}
                id={transaction.transaction_id}
                value={{
                  value: updatedTransaction.transaction_category_id, 
                  label: transactionCategories[updatedTransaction.transaction_category_id].transaction_category_name}}
                // defaultValue={element.transaction_category_id}
                onChange={handleCatChange}
                options={categoryOptions}
                >

              </Select>
            }
            {!editMode && transactionCategories[transaction.transaction_category_id].transaction_category_name}
            </div>
            {transaction.notes && !editMode && 
            <div className={classes.accountName} >
              {transaction.notes}
            </div>
            }
          </TableCell>  
          </Hidden>
          <Hidden mdUp>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
          <TableCell className={classes.cellLessPadding} align="left">
            <div className={classes.merchantName}>
            {editMode &&
              <TextField 
                  InputProps={{
                    classes: {
                      input: classes.nameInput,
                    },
                  }}
                  className={classes.nameInput} 
                  id={transaction.transaction_id_string} 
                  // label={transaction.original_name} 
                  label=""
                  defaultValue={updatedTransaction.merchant_name}
                  onChange={handleChange}
                  size="small"
                />
            }
            {!editMode && transaction.merchant_name}
            </div>

            <div className={classes.accountName}>
            {editMode &&
              <Select
                className={classes.categoryInput}
                id={transaction.transaction_id}
                value={{
                  value: updatedTransaction.transaction_category_id, 
                  label: transactionCategories[updatedTransaction.transaction_category_id].transaction_category_name}}
                // defaultValue={element.transaction_category_id}
                onChange={handleCatChange}
                options={categoryOptions}
                >

              </Select>
            }
            {!editMode && transactionCategories[transaction.transaction_category_id].transaction_category_name}
            </div>
            {transaction.notes && !editMode && 
            <div className={classes.accountName} >
              {transaction.notes}
            </div>
            }
          <div>
           
            
              <IconButton className={classes.iconLeft} onClick={handleInfoClick}>
                <StyledBadge invisible={!transaction.notes} color="primary" badgeContent={"N"} >
                  <StyledBadge invisible={!transaction.transaction_split} anchorOrigin={{vertical: 'top', horizontal: 'left',}} color="secondary" badgeContent={"S"} >
                    <InfoOutlinedIcon fontSize="small" />
                  </StyledBadge>
                </StyledBadge>
              </IconButton>   
            
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleInfoClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                <div className={classes.paper}>
                  <div><b>Original Name:</b> {transaction.original_name}</div>
                  <div className={classes.accountName} >
                    {transaction.account.name}
                  </div>
                  {transaction.transaction_split && 
                    <div>
                      <div>
                        Split from transaction:
                      </div>
                      <div>
                        {transaction.transaction_split.parent_transaction.merchant_name && <div>{transaction.transaction_split.parent_transaction.merchant_name}</div>}
                        {transaction.transaction_split.parent_transaction.amount && <div>{transaction.transaction_split.parent_transaction.amount}</div>}
                      </div>
                    </div>
                  }                  
                    {editMode &&
                      <div><b>Notes: </b> 
                      <TextField 
                          className={classes.nameInput} 
                          id={transaction.transaction_id_string} 
                          // label={transaction.original_name} 
                          label=""
                          defaultValue={updatedTransaction.notes}
                          onChange={handleNotesChange}
                        />
                      </div>
                    }
                    {!editMode && transaction.notes &&
                      <div><b>Notes: </b> 
                        {transaction.notes}
                      </div>
                    }

                  

                  <div>
                    { transaction.transaction_location && (Object.keys(transaction.transaction_location).length > 0) &&
                      <Fragment>
                      <b>Location: </b>
                      <Fragment>
                      {transaction.transaction_location.address && transaction.transaction_location.address}
                      {transaction.transaction_location.city && transaction.transaction_location.city}
                      {transaction.transaction_location.state && transaction.transaction_location.state}
                      {transaction.transaction_location.zip && transaction.transaction_location.zip}
                      </Fragment>
                      </Fragment>
                     }
                  </div>

                </div>
                </Popover>
              {!editMode &&
              <IconButton aria-label="edit" onClick={() => enterEditMode()}>

                <EditIcon fontSize="small" />
              </IconButton>
              }
              {editMode && false &&
                <Fragment>
              <IconButton onClick={handleRuleClick}>
                  <PlaylistAddIcon fontSize="small" />
              </IconButton>
                <Popover
                  id={idRule}
                  open={openRule}
                  anchorEl={anchorElRule}
                  onClose={handleRuleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                <div className={classes.paper}>
                  <AddRule 
                    add_mode_default={true} 
                    show_matching_transactions={false} 
                    original_name_default={transaction.original_name}
                    merchant_name_default={transaction.merchant_name}
                    transaction_category_default = {transaction.transaction_category_id} 
                    space_id={space_id} />
                </div>
                </Popover>  
              </Fragment>
              }
              {editMode &&
                <SplitTransaction transaction={transaction} />
              }
              {editMode &&
                <DeleteTransaction transaction={transaction} />
              }
              {editMode &&            
              <IconButton aria-label="submit edits" onClick={() => submitEdits()}>
                 {/*<CheckIcon className={classes.checkIcon} /> */}
                <CheckIcon fontSize="small" />
              </IconButton>
              }
              </div>
          </TableCell>  
          </Hidden>          
          <TableCell className={classes.cellLessPadding} align="right">
            { transaction.amount >= 0.0 &&
              <div className={classes.amount}><NumberFormat value={transaction.amount} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} /></div>
            }
            { transaction.amount < 0.0  &&
              <div className={classes.amount}><NumberFormat className={classes.negativeCell} value={transaction.amount} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} /></div>
            }
            <div className={classes.date}><Moment format="ddd, MMM D">{transaction.date_time}</Moment></div>
          </TableCell>
          <Hidden smDown>
          <TableCell className={classes.iconCell} align="right">
          <div>
           
            
              <IconButton onClick={handleInfoClick}>
                <StyledBadge invisible={!transaction.notes} color="primary" badgeContent={"N"} >
                  <StyledBadge invisible={!transaction.transaction_split} anchorOrigin={{vertical: 'top', horizontal: 'left',}} color="secondary" badgeContent={"S"} >
                    <InfoOutlinedIcon fontSize="small" />
                  </StyledBadge>
                </StyledBadge>
              </IconButton>   
            
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleInfoClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                <div className={classes.paper}>
                  <div><b>Original Name:</b> {transaction.original_name}</div>
                  <div className={classes.accountName} >
                    {transaction.account.name}
                  </div>
                  {transaction.transaction_split && 
                    <div>
                      <div>
                        Split from transaction:
                      </div>
                      <div>
                        {transaction.transaction_split.parent_transaction.merchant_name && <div>{transaction.transaction_split.parent_transaction.merchant_name}</div>}
                        {transaction.transaction_split.parent_transaction.amount && <div>{transaction.transaction_split.parent_transaction.amount}</div>}
                      </div>
                    </div>
                  }                  
                    {editMode &&
                      <div><b>Notes: </b> 
                      <TextField 
                          className={classes.nameInput} 
                          id={transaction.transaction_id_string} 
                          // label={transaction.original_name} 
                          label=""
                          defaultValue={updatedTransaction.notes}
                          onChange={handleNotesChange}
                        />
                      </div>
                    }
                    {!editMode && transaction.notes &&
                      <div><b>Notes: </b> 
                        {transaction.notes}
                      </div>
                    }

                  

                  <div>
                    { transaction.transaction_location && (Object.keys(transaction.transaction_location).length > 0) &&
                      <Fragment>
                      <b>Location: </b>
                      <Fragment>
                      {transaction.transaction_location.address && transaction.transaction_location.address}
                      {transaction.transaction_location.city && transaction.transaction_location.city}
                      {transaction.transaction_location.state && transaction.transaction_location.state}
                      {transaction.transaction_location.zip && transaction.transaction_location.zip}
                      </Fragment>
                      </Fragment>
                     }
                  </div>

                </div>
                </Popover>
              {!editMode &&
              <IconButton aria-label="edit" onClick={() => enterEditMode()}>

                <EditIcon fontSize="small" />
              </IconButton>
              }
              {editMode && false &&
                <Fragment>
              <IconButton onClick={handleRuleClick}>
                  <PlaylistAddIcon fontSize="small" />
              </IconButton>
                <Popover
                  id={idRule}
                  open={openRule}
                  anchorEl={anchorElRule}
                  onClose={handleRuleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                <div className={classes.paper}>
                  <AddRule 
                    add_mode_default={true} 
                    show_matching_transactions={false} 
                    original_name_default={transaction.original_name}
                    merchant_name_default={transaction.merchant_name}
                    transaction_category_default = {transaction.transaction_category_id} 
                    space_id={space_id} />
                </div>
                </Popover>  
              </Fragment>
              }
              {editMode &&
                <SplitTransaction transaction={transaction} />
              }
              {editMode &&
                <DeleteTransaction transaction={transaction} />
              }
              {editMode &&            
              <IconButton aria-label="submit edits" onClick={() => submitEdits()}>
                 {/*<CheckIcon className={classes.checkIcon} /> */}
                <CheckIcon fontSize="small" />
              </IconButton>
              }
              </div>
            </TableCell>
            </Hidden>
        </TableRow>

	);
};

const mapStateToProps = (state,props) => {
  return {
    transaction: state.transactions[props.transaction_id],
    transactionCategories: state.transactionCategories
  };
};


export default connect(mapStateToProps)(withStyles(styles)(TransactionRow));