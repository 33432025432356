import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import Fab from '@material-ui/core/Fab';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';


const styles = theme => ({
  settingsButton: {
  	// float: "right"
  	position: "fixed",
  	right: 20,
  	top: 20,
  	bottom: "auto",
  	left: "auto",
  	[theme.breakpoints.down('sm')]: {
      top: 58,
      right: 10
    },
  },
  popover: {
    padding: theme.spacing(2),
    minWidth: 200
  },  
   selectComp: {
    minWidth: 150,
  }, 
});


const NetWorthSettings = (props) => {
	const { classes, toggleClosed, toggleCompPeriod } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showClosed, setShowClosed] = React.useState(false)
  const [compPeriod, setCompPeriod] = React.useState(1)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };	

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

   const handleChange = (event) => {
    setShowClosed(!showClosed)
    toggleClosed()
  }; 

  const handleCompChange = (value) => {
  	setCompPeriod(value)
  	toggleCompPeriod(value)
  }

	return(
			<React.Fragment>
		      <Fab size="small" className={classes.settingsButton} onClick={handleClick}>
		        <SettingsIcon />
		      </Fab>
		      <Popover
		        id={id}
		        open={open}
		        anchorEl={anchorEl}
		        onClose={handleClose}
		        anchorOrigin={{
		          vertical: 'bottom',
		          horizontal: 'center',
		        }}
		        transformOrigin={{
		          vertical: 'top',
		          horizontal: 'center',
		        }}
		      >
		      	<div className={classes.popover}>
		        	<Typography variant="h6">Net Worth Settings</Typography>
		        		<div>
			        	<FormControlLabel
				        control={
				          <Checkbox
				            checked={showClosed}
				            onChange={handleChange}
				            color="primary"
				          />
				        }
				        label="Show closed accounts"
				      />
				      </div>
				      <div>
				      <FormControl className={classes.selectComp}>
				      <InputLabel >Change Period</InputLabel>
				      <Select
		                  value={compPeriod}
		                  onChange={(event) => handleCompChange(event.target.value)}
		                  
		                >
		                  <MenuItem value={-1}>None</MenuItem>
		                  <MenuItem value={1}>1 Day</MenuItem>
		                  <MenuItem value={7}>7 Days</MenuItem>
		                  <MenuItem value={30}>30 Days</MenuItem>
		                  <MenuItem value={365}>365 Days</MenuItem>
		                </Select>
		               </FormControl>
		               </div>
		        </div>
		      </Popover>
			</React.Fragment>
	);
};




export default withStyles(styles)(NetWorthSettings);