import { call, put, takeEvery  } from 'redux-saga/effects';
import { LOAD_PENDING_TRANSACTIONS, RECEIVE_PENDING_TRANSACTIONS } from '../actions/pendingTransactions';
import { API_PATH } from '../utilities/apiPath'

function* fetchPendingTransactions(action) {
  const token = action.token

  if (token) {
	  const endpoint = API_PATH + 'api/pendingtransactionsforuser';
	  const options = {
		  headers: {
		    Authorization: `Bearer ${token}`
		  }

	  }
	  const response = yield call(fetch, endpoint, options);
	  const data = yield response.json();
	  yield put({ type: RECEIVE_PENDING_TRANSACTIONS, pendingTransactionResponse: data });
	}
}

export function* loadPendingTransactions() {
  yield takeEvery(LOAD_PENDING_TRANSACTIONS, fetchPendingTransactions);
}