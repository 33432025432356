import React from "react";
import { connect } from 'react-redux'
import ScenariosTimeSeries from '../components/ScenariosTimeSeries'
import ScenarioDetail from '../components/ScenarioDetail'
import AddScenario from '../components/AddScenario'

const PlanView = (props) => {

	const { space_id } = props.match ? props.match.params : {space_id: null};
	const { scenarios, scenario_ids } = props


	return (
	  <div>
	  	<ScenariosTimeSeries space_id={space_id} />
	  	{scenario_ids.map((scenario_id) => (
	  		<ScenarioDetail scenario={scenarios[scenario_id]} key={scenario_id} space_id={space_id} />
	  	))}
	  	<AddScenario space_id={space_id} />
	  </div>
	)
}

const mapStateToProps = (state,props) => {
  const { space_id } = props.match ? props.match.params : {space_id: null};

  let scenario_ids = Object.keys(state.scenarios)

  scenario_ids = scenario_ids.filter((scenario_id) => !state.scenarios[scenario_id].is_deleted)

  if (space_id) {
  	scenario_ids = scenario_ids.filter((scenario_id) => state.scenarios[scenario_id].space_id === Number(space_id))
  }

  return {
  	scenarios: state.scenarios,
  	scenario_ids
  }

};

export default connect(mapStateToProps)(PlanView);