import { all, put, takeEvery, select } from 'redux-saga/effects';
import { LOAD_ACCOUNTS } from '../actions/accounts';
import { LOAD_PENDING_TRANSACTIONS } from '../actions/pendingTransactions'
import { LOAD_BALANCE_TIME_SERIES } from '../actions/timeSeries';
import { LOAD_BUDGETS } from '../actions/spaceTransactionCategoryBudgets'
import { LOAD_TRANSACTION_CATEGORY_SPEND } from '../actions/spaceTransactionCategorySpend';
import { LOAD_USER_FINANCIAL_DATA, LOAD_CATEGORY_DATA } from '../actions/shared'
// import { LOAD_SPACES } from '../actions/spaces';
import { LOAD_TRANSACTION_CATEGORIES } from '../actions/transactionCategories'
import { LOAD_ACCOUNT_TYPES } from '../actions/accountTypes'
import { getTransactionQueryParams } from '../utilities/transactionFilters'
import { LOAD_TRANSACTION_QUERY } from '../actions/transactionQueries'


function* fetchUserFinancialData(action) {
	const token = action.token
  const state = yield select();
 //  yield all([
 //  	// put({ type: LOAD_SPACES, token }),
 //  	put({ type: LOAD_ACCOUNTS, token }),
	// put({ type: LOAD_PENDING_TRANSACTIONS, token }),
	// put({ type: LOAD_TRANSACTIONS, token }),
	// put({ type: LOAD_BALANCE_TIME_SERIES, token }),
 //  	put({ type: LOAD_BUDGETS, token }),
 //    put({ type: LOAD_TRANSACTION_CATEGORY_SPEND, token }),  	  	
 //  	])

    let requests = [
      put({ type: LOAD_ACCOUNTS, token }),
        put({ type: LOAD_PENDING_TRANSACTIONS, token }),
        put({ type: LOAD_BALANCE_TIME_SERIES, token }),
        put({ type: LOAD_BUDGETS, token }),
        put({ type: LOAD_TRANSACTION_CATEGORY_SPEND, token }), 
      ]

  const review_filters = {
    is_reviewed: false
  };


   Object.keys(state.spaces).forEach((space_id) => {
    var queryParams = getTransactionQueryParams(review_filters, space_id)
    requests.push(put({ type: LOAD_TRANSACTION_QUERY, queryParams, token }))
   })

    yield all(requests)

}


export function* loadUserFinancialData() {
  yield takeEvery(LOAD_USER_FINANCIAL_DATA, fetchUserFinancialData);
}


function* fetchCategoryData(action) {
	const token = action.token
  yield all([
      put({ type: LOAD_TRANSACTION_CATEGORIES, token }),
      put({ type: LOAD_ACCOUNT_TYPES, token }),  	
  	])
}


export function* loadCategoryData() {
  yield takeEvery(LOAD_CATEGORY_DATA, fetchCategoryData);
}