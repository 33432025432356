import React from "react";
import { connect } from 'react-redux'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Title from '../components/Title';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  cellLessPadding: {
    paddingRight: 8,
    paddingLeft: 8,
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  userAvatar : {
    width: 24,
    height: 24,
    fontSize: "1em"
  },
  userAvatarMedium: {
    width: 32,
    height: 32,
    fontSize: "1em"    
  },  
});

const UserTable = (props) => {
  const { user_ids, users, dataReady, classes } = props


  return (
    <div>
      <Paper className={classes.paper}>
        <Title>Users</Title>
        {dataReady &&
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.cellLessPadding} variant="head" align="left"></TableCell>
                <TableCell className={classes.cellLessPadding} variant="head" align="left">Name</TableCell>
                <TableCell className={classes.cellLessPadding} variant="head" align="left">Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {user_ids.map(user_id => (
                <TableRow key={user_id} >
                  <TableCell className={classes.cellLessPadding} align="left">
                  <Tooltip title={users[user_id].first_name + ' ' + users[user_id].last_name} arrow>
                    <Avatar component='span' className={classes.userAvatarMedium} src={users[user_id].profile_image_url}>
                    {users[user_id].first_name && users[user_id].first_name.charAt(0)}
                    </Avatar>
                  </Tooltip>   
                  </TableCell>
                <TableCell className={classes.cellLessPadding} scope="row" >
                  {users[user_id].first_name + ' ' + users[user_id].last_name}
                </TableCell>
                <TableCell className={classes.cellLessPadding} scope="row" >
                  {users[user_id].email}
                </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }
        </Paper>

    </div>
  );
};

const mapStateToProps = (state,props) => {
  const { space_id } = props


  if (space_id && (!state.spaceUsers || !state.spaceUsers[space_id] || !state.users || Object.keys(state.users).length === 0)) {
    return {
      user_ids: [],
      dataReady: false
    }
  }

  let user_ids = state.spaceUsers[space_id]

  return {
    user_ids: user_ids,
    dataReady: true,
    users: state.users
  }
};

export default connect(mapStateToProps)(withStyles(styles)(UserTable));