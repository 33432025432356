import React, { useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import IconButton from '@material-ui/core/IconButton';
import { useAuth0 } from "@auth0/auth0-react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { connect } from 'react-redux'
import SelectReact from 'react-select';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import InputAdornment from '@material-ui/core/InputAdornment';
import { splitTransaction } from '../actions/transactions'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import ProgressSpinner from '../utilities/ProgressSpinner'

const styles = theme => ({
  cellLessPadding: {
    paddingRight: 24,
  },  
  negativeCell : {
    color: 'red',
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  notesInput: {
    width: 150,
  },
  checkIcon : {
    color: 'green',
  },
  category: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    },
  },
  merchantName: {
  	width: "100%",
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    },
  },
  amount: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    },
    textAlign: "right"
  },
  nameInput: {
    // width: 300,
    width: "100%",
    fontSize: "0.875rem",
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    },
  },
  categoryInput: {
    //width: 200,
    minWidth: "120px",
    width: "100%",
  },
  dialogContent: {
  	overflowY: "visible"
  }
});


const SplitTransaction = (props) => {
	const { transaction, classes, transactionCategories, dispatch, transactionSplitTemplates } = props
	const { getAccessTokenSilently } = useAuth0();
	const [modalOpen, setModalOpen] = useState(false)
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));	
	const totalTrxAmount = transaction.amount
	const [selectedTemplate, setSelectedTemplate] = useState(0)
	const [pending, setPending] = useState(false)

	const newTransaction = (transaction) => {
		return {
			merchant_name: transaction.merchant_name,
			transaction_category_id: transaction.transaction_category_id,
			amount: 0,
			notes: null
		}
	}

	const [splitTransactions, setSplitTransactions] = useState([newTransaction(transaction)
		, {...newTransaction(transaction),
			amount: totalTrxAmount
		}])

	const addSplit = (transaction) => {
		var newSplit = newTransaction(transaction)
		var newSplitTransactions = [...splitTransactions]
		newSplitTransactions.splice(newSplitTransactions.length-1, 0, newSplit)
		setSplitTransactions(newSplitTransactions)
	}

	const clearSplit = (i) => {
		var newSplitTransactions = [...splitTransactions]
		newSplitTransactions.splice(i, 1)
		setSplitTransactions(newSplitTransactions)
	}

  let categoryOptions = [];

  Object.keys(transactionCategories).map( transactionCategoryId => ( 
      categoryOptions.push({
        value: transactionCategories[transactionCategoryId].transaction_category_id,
        label: transactionCategories[transactionCategoryId].transaction_category_name
      })
      ))

  	const updateRemainder = (newSplitTransactions) => {
  		// var newSplitTransactions = [...splitTransactions]
  		var sumAmount = 0
  		newSplitTransactions.forEach((split_transaction) => {
			sumAmount = sumAmount + split_transaction.amount
		})
		sumAmount = sumAmount - newSplitTransactions[newSplitTransactions.length-1].amount
		newSplitTransactions[newSplitTransactions.length-1].amount = Math.round((totalTrxAmount - sumAmount) * 100 + Number.EPSILON ) / 100
		setSplitTransactions(newSplitTransactions)
  	}

    const handleChange = event => {
    	var id = event.target.id.split('-')
    	var newSplitTransactions = [...splitTransactions]
    	if (id[1] === 'amount') {
    		newSplitTransactions[id[0]].amount = Number(event.target.value)
    		// setSplitTransactions(newSplitTransactions)
    		updateRemainder(newSplitTransactions)
    	}
    	else if (id[1] === 'name') {
    		newSplitTransactions[id[0]].merchant_name = event.target.value
    		setSplitTransactions(newSplitTransactions)
    	}
  }; 

  const handleCatChange = (value,i) => {
  	var newSplitTransactions = [...splitTransactions]
  	newSplitTransactions[i].transaction_category_id = value.value
  	setSplitTransactions(newSplitTransactions)
  };

  async function submitSplit() {
    // setEditMode(false);
    const token = await getAccessTokenSilently();
    dispatch(splitTransaction(transaction.transaction_id, splitTransactions, token))
    setPending(true)
  }

  const handleTemplateChange = event => {
  	var selected_split_template_id = event.target.value
  	setSelectedTemplate(selected_split_template_id)
  	const newSplitTransactions = [
	  		...transactionSplitTemplates[selected_split_template_id].transaction_split_template_transactions,
	  		{...newTransaction(transaction),
				amount: totalTrxAmount
			}
	  	]
  	updateRemainder(newSplitTransactions)
  }

  const inputProps = {
    step: 0.01,
  };

	return (
		<React.Fragment>
	      <IconButton aria-label="split" onClick={() => setModalOpen(true)}>
	        <CallSplitIcon fontSize="small" />
	      </IconButton>
			<Dialog 
				fullWidth 
				maxWidth='md' 
				fullScreen={fullScreen} 
				open={modalOpen} 
				onClose={() => setModalOpen(false)} 
				PaperProps={{ style: { overflowY: "visible" } }}
			>
		        <DialogTitle>
		        <Grid container alignItems="center" justify="space-between">
		        	<Grid item >
		        		<div>Split Transaction</div>
		        	</Grid>
		        	<Grid item>
						<Select
			              value={selectedTemplate}
			              onChange={handleTemplateChange}
			              className={classes.selectMonths}
			            >
			              <MenuItem value={0}>None</MenuItem>
			              {
			              	Object.keys(transactionSplitTemplates).map((split_template_id) => (
			              		<MenuItem key={split_template_id} value={split_template_id}>{transactionSplitTemplates[split_template_id].split_template_name}</MenuItem>
			              	))
			              }

	            		</Select>
	            	</Grid>
	            </Grid>
		        </DialogTitle>

		        <DialogContent className={classes.dialogContent}>
		        	<Table size="small">
			        <TableHead>
			          <TableRow>
			            <TableCell className={classes.cellLessPadding} variant="head" align="left">Merchant Name</TableCell>
			            <TableCell className={classes.cellLessPadding} variant="head" align="left">Category</TableCell>
			            <TableCell className={classes.cellLessPadding} variant="head" align="right">Amount</TableCell>
			            <TableCell className={classes.cellLessPadding} variant="head" align="right"></TableCell>
			          </TableRow>
			        </TableHead>
			        <TableBody>
			          {splitTransactions.map((split_transaction,i) => (
			          <TableRow key={i} >
				          <TableCell className={classes.cellLessPadding} align="left">
				            <div className={classes.merchantName}>
				              <TextField 
				                  InputProps={{
				                    classes: {
				                      input: classes.nameInput,
				                    },
				                  }}
				                  className={classes.nameInput} 
				                  id={i.toString() + "-name"} 
				                  // label={transaction.original_name} 
				                  label=""
				                  value={split_transaction.merchant_name}
				                  onChange={handleChange}
				                  size="small"
				                />
				            </div>
				           </TableCell>
				          <TableCell className={classes.cellLessPadding} align="left">
				            <div className={classes.category}>
				              <SelectReact
				                className={classes.categoryInput}
				                id={i.toString() + "-cat"}
				                value={{
				                  value: split_transaction.transaction_category_id, 
				                  label: transactionCategories[split_transaction.transaction_category_id].transaction_category_name}}
				                // defaultValue={element.transaction_category_id}
				                onChange={(value) => handleCatChange(value, i)}
				                options={categoryOptions}
				                >
				              </SelectReact>
				            </div>
				          </TableCell> 
				          <TableCell className={classes.cellLessPadding} align="right">
				            <div className={classes.merchantName}>
				            {i !== splitTransactions.length - 1 && 
				              <TextField 
				                  className={classes.amount} 
				                  id={i.toString() + "-amount"} 
				                  // label={transaction.original_name} 
				                  label=""
				                  value={split_transaction.amount}
				                  onChange={handleChange}
				                  size="small"
				                  type="number"
				                  inputProps={inputProps}
					              InputProps={{
					                startAdornment: <InputAdornment position="start">$</InputAdornment>,
					                classes: {
				                      input: classes.amount,
				                    },
					              }}
				                />
				            }
				                {i === splitTransactions.length - 1 && 
				              	<TextField 
					                  className={classes.amount} 
					                  id={i.toString() + "-amount"} 
					                  // label={transaction.original_name} 
					                  label=""
					                  value={split_transaction.amount}
					                  //onChange={handleChange}
					                  size="small"
					                  disabled
					                  type="number"
					                  inputProps={inputProps}
					                  InputProps={{
						                startAdornment: <InputAdornment position="start">$</InputAdornment>,
						                classes: {
					                      input: classes.amount,
					                    },
						              }}
					                />
				                }
				            </div>
				           </TableCell>
				           <TableCell className={classes.cellLessPadding} align="right">
						      {i < splitTransactions.length - 2 &&
						      <IconButton aria-label="split" onClick={() => clearSplit(i)}>
						        <ClearIcon fontSize="small" />
						      </IconButton>
						     }
						      {i === splitTransactions.length - 2 &&
						      <IconButton aria-label="split" onClick={() => addSplit(transaction)}>
						        <AddIcon fontSize="small" />
						      </IconButton>
						     }
				           </TableCell>
				        </TableRow>
			          ))}
			        </TableBody>
			      </Table>
		        </DialogContent>
		        {!pending && 
			        <DialogActions>
			          <Button onClick={() => setModalOpen(false)} color="primary">
			            Cancel
			          </Button>
			          <Button onClick={() => submitSplit()} color="primary">
			            Split
			          </Button>
			        </DialogActions>
		    	}
		    	{pending && 
		       		<DialogActions>
		       			<ProgressSpinner />
			        </DialogActions>
		    	}
		      </Dialog>
	      </React.Fragment>


	)

}

const mapStateToProps = (state,props) => {
  return {
    transactionCategories: state.transactionCategories,
    transactionSplitTemplates: state.transactionSplitTemplates
  };
};

export default connect(mapStateToProps)(withStyles(styles)(SplitTransaction))