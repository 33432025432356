export const LOAD_BUDGETS = 'LOAD_BUDGETS'
export const RECEIVE_BUDGETS = 'RECEIVE_BUDGETS'
export const UPDATE_BUDGETS = 'UPDATE_BUDGETS'
export const UPDATE_BUDGETS_FAILED = 'UPDATE_BUDGETS_FAILED'
export const UPDATE_BUDGETS_SUCCEEDED = 'UPDATE_BUDGETS_SUCCEEDED'


export function updateBudget (space_id, transactionCategoryBudgets, updatedTransactionCategoryBudgets, token) {
	return {
		type: UPDATE_BUDGETS,
		space_id,
		transactionCategoryBudgets,
		updatedTransactionCategoryBudgets,
		token
	}
}
