import React, { useState } from "react";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
//import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import 'moment-timezone';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux'
import { updateAccount } from '../actions/accounts'
import {NavLink} from "react-router-dom";
import Select from 'react-select';
import LastUpdatedDate from '../components/LastUpdatedDate'
import SyncIcon from '@material-ui/icons/Sync';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import { useAuth0 } from "@auth0/auth0-react";
import DeleteAccount from '../components/DeleteAccount'
import AccountAvatars from '../components/AccountAvatars'
import Hidden from '@material-ui/core/Hidden';
import Moment from 'react-moment';
import AddAccountData from '../components/AddAccountData' 

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 600,
  },
  header: {
    paddingBottom: 12,
  },
  cellLessPadding: {
    paddingRight: 8,
    paddingLeft: 8,
  },
  logo : {
    width: 24,
  },
  negativeCell : {
    color: 'red',
  },
  plaidButton : {
    color: '#fff',
    backgroundColor: '#009be5',
    lineHeight: 1.75,
    fontWeight: 500
  },
  checkIcon : {
    color: 'green',
  },
  errorIcon : {
    color: 'red',
  },
  nameInput: {
    // width: 300,
    width: "100%",
    fontSize: "0.875rem",
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    },
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  iconCell: {
    paddingRight: 8,
    paddingLeft: 8,
    paddingTop: 0,
    paddingBottom: 0,
  },  
  avatarCell: {
    paddingRight: 0,
    paddingLeft: 4,
  },
  unstyledLink: {
  	color: 'inherit',
    textDecoration: 'inherit',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    },
  },
  chip: {
    marginRight: "2px",
    fontSize: '0.6rem',
  },
  multiSelect: {
    maxWidth: "220px"
  },
  accountName: {
  },
  accountType: {
    fontStyle: "italic",
    color: "gray",
    fontSize: '0.7rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem'
    },
  },
  balance: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    },
  },
  lastUpdated: {
    fontSize: '0.7rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem'
    },
  },
  iconButton: {
    [theme.breakpoints.down('sm')]: {
      width: 24,
      height: 24,
    },
  },
  closedDate: {
    fontStyle: "italic",
    color: "gray",
    fontSize: '0.7rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem'
    },
  },
  categoryInput: {
    //width: 200,
    minWidth: "120px",
    width: "100%",
  },
});

const AccountRow = (props) => {
  const { classes } = props;
  const { account, space_id, accountTypes, dispatch, onRepair, user_id, updateItem, spaces, edit_mode_default, show_spaces } = props
  const [editMode, setEditMode] = useState(edit_mode_default)
  const [updatedAccount, setUpdatedAccount] = useState({...account})

  const { getAccessTokenSilently } = useAuth0();

  let account_space_ids = account.space_accounts.map((space_account) => space_account.space_id)

  const [updatedSpaces, setUpdatedSpaces] = useState(account_space_ids.map((s_id) => {
    return {
      value: s_id,
      label: spaces[s_id] ? spaces[s_id].space_name : ''
    }}))

  const subtype_category_name = account.account_category_id 
    ? accountTypes[account.account_subtype_id].account_subtype_name + ' - ' + accountTypes[account.account_subtype_id].account_categories[account.account_category_id].account_category_name 
    : accountTypes[account.account_subtype_id].account_subtype_name;

  let categoryOptions = [];

  Object.keys(accountTypes).forEach( accountSubTypeId => { 
    if (Object.keys(accountTypes[accountSubTypeId].account_categories).length === 0) {
      categoryOptions.push({
        value: accountSubTypeId,
        label: accountTypes[accountSubTypeId].account_subtype_name
      })
    } else {
      Object.keys(accountTypes[accountSubTypeId].account_categories).forEach((accountCategoryId) => {
        categoryOptions.push({
          value: accountSubTypeId + '-' + accountCategoryId,
          label: accountTypes[accountSubTypeId].account_subtype_name + ' - ' + accountTypes[accountSubTypeId].account_categories[accountCategoryId].account_category_name
        })  
      })
    }
  })

  const space_ids = Object.keys(spaces)

  // console.log(space_ids)
  // console.log(updatedSpaces)

  

  async function submitEdits(account_id) {

    const token = await getAccessTokenSilently();

    const new_account = {
      ...updatedAccount,
      is_reviewed: true
    }
    await setUpdatedAccount(new_account);
    await setEditMode(false)

    dispatch(updateAccount(account, new_account, token))
    

  }


  const handleCatChange = value => {

    let ids = value.value.split('-')
    let account_subtype_id = ids[0]
    let account_category_id = ids.length > 1 ? ids[1] : null
    let account_type_id = accountTypes[account_subtype_id].account_type.account_type_id

    const new_account = {
      ...updatedAccount,
      account_subtype_id: account_subtype_id,
      account_type_id: account_type_id,
      account_category_id: account_category_id
    }
    setUpdatedAccount(new_account);
  };


  const handleChange = event => {
    const new_account = {
      ...updatedAccount,
      name: event.target.value,
    }
    setUpdatedAccount(new_account);
  };

 const handleSpaceChange = (value) => {
    setUpdatedSpaces(value);
    const new_account = {
      ...updatedAccount,
      space_accounts: value ? value.map((element) => ({
        space_id: parseInt(element.value)
      })) : []
    }
    console.log(new_account)
    setUpdatedAccount(new_account)
  };


  const space_options = space_ids.map((s_id) => {
    return {
      value: s_id,
      label: spaces[s_id].space_name
    }
  })


  return (
      <TableRow key={account.account_id} >
        <Hidden xsDown>
          <TableCell className={classes.cellLessPadding} align="left">
              <AccountAvatars account={account} showUser={space_id} size={"medium"}/>
          </TableCell>
        </Hidden>
        <TableCell className={classes.cellLessPadding} scope="row" >
          <div>
          {!editMode && 
          <NavLink to={"/accountdetail/" + account.account_id} className={classes.unstyledLink}>
            {account.name}
          </NavLink>
          }
          {editMode &&
            <TextField 
              className={classes.nameInput}
              InputProps={{
                classes: {
                  input: classes.nameInput,
                }, 
              }}
              id={account.account_id_str} 
              label="" 
              defaultValue={account.name}
              onChange={handleChange}
            />
          }
          </div>
          <div>
          {editMode &&
            <Select
              className={classes.categoryInput}
              id={account.account_id}
              value={{
                value: updatedAccount.account_subtype_id 
                  + (updatedAccount.account_category_id ? '-' + updatedAccount.account_category_id
                    : ''), 
                label: accountTypes[updatedAccount.account_subtype_id].account_subtype_name
                  + (updatedAccount.account_category_id 
                    ? ' - ' + accountTypes[updatedAccount.account_subtype_id].account_categories[updatedAccount.account_category_id].account_category_name
                    : ''),
              }}
              onChange={handleCatChange}
              options={categoryOptions}
              >

            </Select>
          }
          {!editMode && 
            <span className={classes.accountType}>{subtype_category_name}</span>
          }          
          </div>
        </TableCell>
          {!space_id  && editMode && show_spaces &&
            <TableCell className={classes.cellLessPadding} align="left">
             <Select
              className={classes.multiSelect}
              defaultValue={updatedSpaces}
              isMulti
              name="space_selector"
              options={space_options}
              classNamePrefix="select"
              onChange={handleSpaceChange}
            /> 
            </TableCell>
          }
          {!space_id && !editMode && show_spaces &&
            <TableCell className={classes.cellLessPadding} align="left">
            {account_space_ids.map((s_id) => (
              <Chip className={classes.chip}  size="small" key={s_id} label={spaces[s_id].space_name} />
            ))}
            </TableCell>
          }        
        <TableCell className={classes.cellLessPadding} align="right">
          <div className={classes.balance}>
          { account.latest_balance >= 0 &&
          <div><NumberFormat value={account.latest_balance} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} /></div>
          }
          { account.latest_balance < 0 &&
          <div><NumberFormat className={classes.negativeCell} value={account.latest_balance} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} /></div>
          }
          </div>
          <div className={classes.lastUpdated}>
              {!account.closed_date && 
                <LastUpdatedDate last_updated_date={account.balance_last_updated} />
              }
              {account.closed_date &&
                <span className={classes.closedDate}>Closed <Moment format="MMM YYYY">{account.closed_date}</Moment></span>
              }
          </div>
        </TableCell>
        <TableCell className={classes.iconCell} align="center">
          {!editMode && account.plaid_account && account.plaid_account.plaid_item.error_state && account.owner_user_id === user_id &&
            <Tooltip title={account.plaid_account.plaid_item.error_code} arrow>
              <IconButton aria-label="fix-error" onClick={() => onRepair(account.plaid_account.plaid_item.item_id)}>
                <ErrorIcon fontSize="small" className={classes.errorIcon}/>
              </IconButton>
            </Tooltip>
          }
          {!editMode && account.plaid_account && account.plaid_account.plaid_item.error_state && account.owner_user_id !== user_id &&
            <Tooltip title={`Only the owner can repair an account (${account.plaid_account.plaid_item.error_code}).`} arrow>
              <span>
                <IconButton disabled={true} >
                  <ErrorIcon fontSize="small" className={classes.errorIcon}/>
                </IconButton>
              </span>
          </Tooltip>
          }
          {account.source_type === "offline" &&
             <AddAccountData account_id={account.account_id} showButton={false} />

          }
          <Hidden xsDown>
            {!editMode && !account.is_refreshing && account.plaid_account &&
            <Tooltip title={`Sync Account`} arrow>
              <IconButton aria-label="sync" onClick={() => updateItem(account.plaid_account.plaid_item.item_id)}>
                <SyncIcon fontSize="small" />
              </IconButton>
            </Tooltip>  
          }
            {!editMode && account.is_refreshing && 
              <CircularProgress size={32} className={classes.progress} />
            }
          </Hidden>
        {editMode &&
          <DeleteAccount fontSize="small" account={account} />
        }
        {!editMode &&
          <Tooltip title={`Edit Account`} arrow>
            <IconButton  onClick={() => setEditMode(true)}>

              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>  
          }
          {editMode &&
          <Tooltip title={`Submit Edits`} arrow>
            <IconButton  onClick={() => submitEdits(account.account_id)}>
              <CheckIcon fontSize="small" />
            </IconButton>
          </Tooltip> 
          } 
        </TableCell>
      </TableRow>
  );
};

const mapStateToProps = (state,props) => {
  return {
    account: state.accounts[props.account_id],
    accountTypes: state.accountTypes,
    userApp: state.userApp,
    spaces: state.spaces,
  };
};


export default connect(mapStateToProps)(withStyles(styles)(AccountRow));

