import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import ScenarioDetailSpendingAdditional from '../components/ScenarioDetailSpendingAdditional'
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
  cellLessPadding: {
    paddingRight: 8,
    paddingLeft: 8,
  },
  inflationInput: {
    width: "80px"
  }
});


const ScenarioDetailSpending = (props) => {

	const { scenario, classes, transaction_groups, editMode, updateScenario, space_id } = props

  const handleChanges = (event, transaction_group_id) => {
    let newSpend = [...scenario.scenario_assumptions_spending]
    const {name, value} = event.target

    newSpend.forEach((spend, i) => {
      if (spend.transaction_group_id === transaction_group_id) {
        newSpend[i][name] = Number(value)
      }
    })

    updateScenario("scenario_assumptions_spending", newSpend)

  }

  const handleInfationChange = (event) => {
    updateScenario("inflation_yearly", event.target.value / 100.0)
  }

	return (
	  <div>
        <div>
          <span>Yearly Inflation Rate: </span>
          <span>
            {!editMode &&
              <NumberFormat value={scenario.inflation_yearly * 100.0} displayType={'text'} thousandSeparator={true} suffix={'%'} decimalScale={2} fixedDecimalScale={true} />
            }
            {editMode &&
              <TextField
                type="number"
                id="inflation_yearly"
                name="inflation_yearly"
                className={classes.inflationInput}
                defaultValue={scenario.inflation_yearly * 100.0}
                onChange={(event) => handleInfationChange(event)}
                inputProps={{
                  style: { textAlign: "right" },
                  step: 0.01,
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            }
          </span>
        </div>
         <Typography variant="h6" gutterBottom>
            Regular Spending
          </Typography>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.cellLessPadding} variant="head" align="left">Category</TableCell>
                <TableCell className={classes.cellLessPadding} variant="head" align="right">Monthly Spend</TableCell>
                <TableCell className={classes.cellLessPadding} variant="head" align="right">Last Month</TableCell>           
                <TableCell className={classes.cellLessPadding} variant="head" align="right">3 Month Avg</TableCell>
                <TableCell className={classes.cellLessPadding} variant="head" align="right">6 Month Avg</TableCell>
                <TableCell className={classes.cellLessPadding} variant="head" align="right">Budget</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scenario.scenario_assumptions_spending.map(spend => (
                <TableRow key={spend.transaction_group_id} >
                  <TableCell className={classes.cellLessPadding} align="left">
                    {transaction_groups[spend.transaction_group_id].transaction_group_name}  
                  </TableCell>
                  <TableCell className={classes.cellLessPadding} align="right">
                   {!editMode && 
                    <NumberFormat value={spend.monthly_spend} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                   }
                    {editMode &&
                    <TextField
                      type="number"
                      id="monthly_spend"
                      name="monthly_spend"
                      className={classes.spendInput}
                      defaultValue={spend.monthly_spend}
                      onChange={(event) => handleChanges(event, spend.transaction_group_id)}
                      inputProps={{
                        style: { textAlign: "right" },
                        step: 0.01,
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                    }
                  </TableCell>
                  <TableCell className={classes.cellLessPadding} align="right">
                    <NumberFormat value={-transaction_groups[spend.transaction_group_id].last_month} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                  </TableCell>
                  <TableCell className={classes.cellLessPadding} align="right">
                    <NumberFormat value={-transaction_groups[spend.transaction_group_id].three_months} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                  </TableCell>
                  <TableCell className={classes.cellLessPadding} align="right">
                    <NumberFormat value={-transaction_groups[spend.transaction_group_id].six_months} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                  </TableCell>
                  <TableCell className={classes.cellLessPadding} align="right">
                    <NumberFormat value={-transaction_groups[spend.transaction_group_id].budget} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <ScenarioDetailSpendingAdditional space_id={space_id} scenario={scenario} updateScenario={updateScenario} editMode={editMode} />
	  </div>
	)
}

const mapStateToProps = (state,props) => {
  const { space_id } = props

  let budgets = state.spaceTransactionCategoryBudgets[space_id]
  let dates = state.spaceTransactionCategorySpend.dates
  let categorySpend = state.spaceTransactionCategorySpend.transactionCategoryTimeSeries[space_id]

  let transaction_groups = {}

  Object.keys(state.transactionCategories).forEach((transaction_category_id) => {
    let transaction_category = state.transactionCategories[transaction_category_id]

    let month_1 = (categorySpend[transaction_category_id] && categorySpend[transaction_category_id][dates[dates.length -2]]) ? categorySpend[transaction_category_id][dates[dates.length -2]] : 0.0
    let month_2 = (categorySpend[transaction_category_id] && categorySpend[transaction_category_id][dates[dates.length -3]]) ? categorySpend[transaction_category_id][dates[dates.length -3]] : 0.0
    let month_3 = (categorySpend[transaction_category_id] && categorySpend[transaction_category_id][dates[dates.length -4]]) ? categorySpend[transaction_category_id][dates[dates.length -4]] : 0.0
    let month_4 = (categorySpend[transaction_category_id] && categorySpend[transaction_category_id][dates[dates.length -5]]) ? categorySpend[transaction_category_id][dates[dates.length -5]] : 0.0
    let month_5 = (categorySpend[transaction_category_id] && categorySpend[transaction_category_id][dates[dates.length -6]]) ? categorySpend[transaction_category_id][dates[dates.length -6]] : 0.0
    let month_6 = (categorySpend[transaction_category_id] && categorySpend[transaction_category_id][dates[dates.length -7]]) ? categorySpend[transaction_category_id][dates[dates.length -7]] : 0.0
    
    if (transaction_groups[transaction_category.transaction_group_id]) {
      transaction_groups[transaction_category.transaction_group_id].budget += budgets[transaction_category_id]
      transaction_groups[transaction_category.transaction_group_id].last_month += month_1
      transaction_groups[transaction_category.transaction_group_id].three_months += (month_1 + month_2 + month_3) / 3.0
      transaction_groups[transaction_category.transaction_group_id].six_months += (month_1 + month_2 + month_3 + month_4 + month_5 + month_6) / 6.0
    } else {

      transaction_groups[transaction_category.transaction_group_id] = {
        transaction_group_id: transaction_category.transaction_group_id,
        transaction_group_name: transaction_category.transaction_group_name,
        last_month: month_1,
        three_months: (month_1 + month_2 + month_3) / 3.0,
        six_months: (month_1 + month_2 + month_3 + month_4 + month_5 + month_6) / 6.0,
        budget: budgets[transaction_category_id]
      }
    }
  })

  return {
    transactionCategories: state.transactionCategories,
    transaction_groups
  }

};

export default connect(mapStateToProps)(withStyles(styles)(ScenarioDetailSpending));