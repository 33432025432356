// src/App.js

import React, { useEffect } from "react";
// import {useState} from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Profile from "./components/Profile";
// import SecuredRoute from "./components/SecuredRoute";
import PrivateRoute from "./components/PrivateRoute";
import AccountsView from "./components/AccountsView";
import AccountDetail from "./components/AccountDetail";
import Transactions from "./components/Transactions";
import NetWorthView from "./components/NetWorthView";
import Budget from "./components/Budget"
import TransactionRules from "./components/TransactionRules";
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Navigator from './components/Navigator';
// import Callback from './components/Callback';
// import Header from './components/Header';
import 'typeface-roboto';
import { connect } from 'react-redux'
import Welcome from './components/Welcome'
import Dashboard from './components/Dashboard'
import { useAuth0 } from "@auth0/auth0-react";
import { loadUserAppData, setUser } from './actions/user'
import Header from './components/Header'
import Loading from './components/Loading'
import Credit from './components/Credit'
import PlanView from './components/PlanView'
import SettingsView from './components/SettingsView'
import Home from './components/Home'


let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

const drawerWidth = 216;

const styles = theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    background: '#eaeff1',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 2),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  footer: {
    padding: theme.spacing(2),
    background: '#eaeff1',
  },
});

const App = (props) => {

  const { classes } = props;
  const { initialDataReady, dispatch } = props;
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  useEffect(() => {
    async function getInitialData() {
      const token = await getAccessTokenSilently();
      dispatch(setUser(user))
      dispatch(loadUserAppData(token, user))
    }

    if (!isAuthenticated || !user) {
      return;
    }
    getInitialData()
  }, [isAuthenticated, user]);


    return (
      <ThemeProvider theme={theme}>
        
        
          <div className={classes.root}>
            <CssBaseline />
            <BrowserRouter >
            { user && initialDataReady && 
             <nav className={classes.drawer}>
              <Hidden mdUp implementation="js">
                <Navigator
                  PaperProps={{ style: { width: drawerWidth } }}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                />

              </Hidden>
              <Hidden smDown implementation="css">
                <Navigator 
                  PaperProps={{ style: { width: drawerWidth } }}
                  variant="permanent"
                  open
                  />
              </Hidden>
            </nav>
          }
              <div className={classes.app}>
                <Header onDrawerToggle={handleDrawerToggle} />

                
                <main className={classes.main}>

                  <Switch>
                    {/* <Route path="/callback" component={Callback} /> */}
                    <Route path="/welcome" component={Welcome} />
                    <PrivateRoute path="/" exact component={Home}/>
                    <PrivateRoute path="/profile" component={Profile} />
                    <PrivateRoute path="/accounts/:space_id" component={AccountsView} />
                    <PrivateRoute path="/accounts" component={AccountsView} />
                    <PrivateRoute path="/accountdetail/:account_id" component={AccountDetail} />
                    <PrivateRoute path="/transactions/:space_id" component={Transactions} />
                    <PrivateRoute path="/transactions" component={Transactions} />
                    <PrivateRoute path="/networth/:space_id" component={NetWorthView} />
                    <PrivateRoute path="/rules/:space_id" component={TransactionRules} />
                    <PrivateRoute path="/budget/:space_id" component={Budget} />
                    <PrivateRoute path="/loading" component={Loading} />
                    <PrivateRoute path="/credit/:space_id" component={Credit} />
                    <PrivateRoute path="/plan/:space_id" component={PlanView} />
                    <PrivateRoute path="/settings/:space_id" component={SettingsView} />
                    <PrivateRoute path="/dashboard/:space_id" component={Dashboard} />
                  </Switch>
                </main>

              </div>
              </BrowserRouter>
            </div>
          
        </ThemeProvider>
        
    );
}

const mapStateToProps = state => {
  return {
    userState: state.user,
    initialDataReady: Object.keys(state.spaces).length > 0
  };
};

// export default App;
export default connect(mapStateToProps)(withStyles(styles)(App));