import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { useAuth0 } from "@auth0/auth0-react";
import { withStyles } from '@material-ui/core/styles';
import ProgressSpinner from '../utilities/ProgressSpinner'
import { connect } from 'react-redux'
import { addScenario } from '../actions/scenarios'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Select from 'react-select';

const styles = theme => ({
  dialogSubtitle: {
    fontWeight: "bold",
    color: "black"
  },
  submitButton: {
    marginTop: "16px",
    marginBottom: "16px",
  },
  paper: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  dialogContent: {
    overflowY: "visible"
  }
});

const AddScenario = (props) => {
  const { classes } = props
  const { space_id, account_subtype_ids, space_users, dispatch, users, scenarios, scenario_ids } = props
  const [loading, setLoading] = React.useState(false)
  const { getAccessTokenSilently } = useAuth0();
  const [open, setOpen] = React.useState(false);
  const [scenarioKey, setScenarioKey] = React.useState(-1);


  let scenarioOptions = [{
    value: -1,
    label: "None (Create New)"
  }]

  scenarioOptions = scenarioOptions.concat(scenario_ids.map((scenario_id) => ({
    value: scenario_id,
    label: scenarios[scenario_id].scenario_name
  })))



  async function handleAddNewScenario() {
    setLoading(true)
    const token = await getAccessTokenSilently();

    let newScenario = {}

    if (scenarioKey === -1) {

      newScenario = {
        scenario_name: "New Scenario",
        space_id: space_id,
        inflation_yearly: 0.02,
        scenario_assumptions_spending: [
          {transaction_group_id: 1, monthly_spend: 0},
          {transaction_group_id: 2, monthly_spend: 0},
          {transaction_group_id: 3, monthly_spend: 0},
        ],
        scenario_assumptions_spending_additional: [],
        scenario_assumptions_returns: account_subtype_ids.map((account_subtype_id) => ({
          account_subtype_id,
          yearly_growth_rate: 0,
          surplus_type: 'percent',
          surplus_amount: 0,
          withdrawal_tax_rate: 0,
          surplus_order: null,
          withdrawal_order: null,
          withdrawal_min_date: null,
        })),
        scenario_assumptions_income: space_users.map((user_id) => ({
          user_id,
          income_name: "Salary",
          yearly_amount: users[user_id].yearly_salary ? users[user_id].yearly_salary : 0,
          yearly_growth_rate: 0.0,
          tax_rate: 0.0,
          populated_from_default: true,
          recurring_type_id: 1
        })).concat(space_users.map((user_id) => ({
          user_id,
          income_name: "Other Taxable",
          yearly_amount: users[user_id].yearly_income_other_taxable ? users[user_id].yearly_income_other_taxable : 0,
          yearly_growth_rate: 0.0,
          tax_rate: 0.0,
          populated_from_default: true,
          recurring_type_id: 1
        }))).concat(space_users.map((user_id) => ({
          user_id,
          income_name: "Other Non Taxable",
          yearly_amount: users[user_id].yearly_income_other_nontaxable ? users[user_id].yearly_income_other_nontaxable : 0,
          yearly_growth_rate: 0.0,
          tax_rate: 0.0,
          populated_from_default: true,
          recurring_type_id: 1
        }))),
      }
    } else {
      newScenario = {
        ...scenarios[scenarioKey],
        space_id: space_id,
        scenario_id: null,
        scenario_name: "New Scenario (Copy of " + scenarios[scenarioKey].scenario_name + ")"
      }

    }


    await dispatch(addScenario(newScenario, token))

    setLoading(false)
    setOpen(false)

  }

  const handleScenarioChanges = (value) => {
    setScenarioKey(value.value)
  }

  return (
    <Fragment>
    <div className={classes.paper}>
      {!loading && 
        <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
          Add Plan Scenario
        </Button>
      }
      {loading && 
        <Fragment>
          <Button variant="contained" color="primary" disabled>
            Add Plan Scenario
          </Button>
          <ProgressSpinner />
        </Fragment>
      }      
    </div>
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth PaperProps={{ style: { overflowY: "visible" } }} >
        <DialogTitle id="form-dialog-title">
            <div>Add Plan Scenario</div>
        </DialogTitle>
        <DialogContent className={classes.dialogContent} >
          <DialogContentText>
            Add a new scenario by starting from scratch or copying an existing scenario.
          </DialogContentText>
          <Select
            className={classes.userInput}
            value={{
              value: scenarioKey, 
              label: scenarioKey === -1 ? "None (Create New)" : scenarios[scenarioKey].scenario_name,
            }}
            onChange={(value) => handleScenarioChanges(value)}
            options={scenarioOptions}
            >

          </Select>
        </DialogContent>
        <DialogActions>
          {!loading &&
            <Fragment>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleAddNewScenario()} color="primary">
            Add
          </Button>
          </Fragment>
          }
          {loading &&
            <ProgressSpinner />
          }
        </DialogActions>
      </Dialog>  
      </Fragment>      
  );
}

const mapStateToProps = (state,props) => {
  const { space_id } = props

  let scenario_ids = Object.keys(state.scenarios)

  scenario_ids = scenario_ids.filter((scenario_id) => !state.scenarios[scenario_id].is_deleted)

  // if (space_id) {
  //   scenario_ids = scenario_ids.filter((scenario_id) => state.scenarios[scenario_id].space_id === Number(space_id))
  // }


  return {
    account_subtype_ids: Object.keys(state.accountTypes),
    space_users: state.spaceUsers[space_id],
    users: state.users,
    scenarios: state.scenarios,
    scenario_ids
  }

};

export default connect(mapStateToProps)(withStyles(styles)(AddScenario));
