import React from 'react';
import TransactionTable from '../components/TransactionTable';
import PendingTransactionTable from '../components/PendingTransactionTable';
import SearchableTransactions from '../components/SearchableTransactions'
//import moment from 'moment'

const Transactions = (props) => {
	const { space_id } = props.match.params;

	const review_filters = {
		is_reviewed: false
	};

	// var start_date = moment().subtract(7,'d').format('YYYY-MM-DD');
	// const recent_filters = {
	// 	start_date: start_date,
	// 	limit: 100
	// };

	return(
		<div>
			<TransactionTable space_id={space_id} title="Transactions to Review" edit_mode_default={true} filters={review_filters} show_cards={false} />
      <PendingTransactionTable space_id={space_id} />
      		<SearchableTransactions space_id={space_id} />
			{/* 
			<TransactionTable space_id={space_id} title="Recent Transactions" edit_mode_default={false} filters={recent_filters} show_cards={false} />		      
		*/}
		</div>

	);
};


export default Transactions;