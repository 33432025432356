import React from "react";
import Moment from 'react-moment';
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  red: {
    color: "red",
    fontStyle: "italic"
  },
  orange: {
    color: "orange",
    fontStyle: "italic"
  },
  green: {
    color: "green",
    fontStyle: "italic"
  },

});

const LastUpdatedDate = (props) => {
  const { classes } = props;
  const { last_updated_date } = props

  const today = moment()
  const updated_date = moment(last_updated_date)
  // console.log(last_updated_date)
  // console.log(today)

  var class_name = 'green'

  if (updated_date >= today.subtract(2, 'days')) {
    class_name = 'green'
  } else if (updated_date >= today.subtract(7, 'days')) {
    class_name = 'orange'
  } else {
    class_name = 'red'
  }

  // console.log(color)


  return (
      <span className={classes[class_name]}>
       <Moment fromNow>{last_updated_date}</Moment>
      </span>
  );
};


export default withStyles(styles)(LastUpdatedDate)

