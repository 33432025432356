import React, { useState, Fragment } from "react";
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import moment from 'moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';


const styles = theme => ({
  cellLessPadding: {
    paddingRight: 8,
    paddingLeft: 8,
  },
  growthInput: {
    width: "80px"
  },
  surplusInput: {
    width: "80px",
    marginRight: "8px"
  },
  orderInput: {
    width: "80px"
  },
  surplusAmountInput: {
    maxWidth: "120px",
    fontSize: "0.875rem"
  },
  taxInput: {
    width: "70px",
    fontSize: "0.875rem"
  },  
  dateInput: {
    width: "130px",
    fontSize: "0.875rem"
  },
});


const ScenarioDetailReturns = (props) => {

	const { scenario, classes } = props
  const { accountTypes, account_subtypes_total, editMode, updateScenario } = props
  const [updatedScenarioReturns, setUpdatedScenarioReturns] = useState([...scenario.scenario_assumptions_returns])

  const orderOptions = updatedScenarioReturns.map((ret, i) => ({
    value: i,
    label: i
  }))

  const surplusTypes = {
    fixed: "$",
    percent: "%"
  }

  const surplusOptions = [
  {
    value: "fixed",
    label: "$"
  },
  {
    value: "percent",
    label: "%"    
  }]


  const handleChanges = (event, account_subtype_id) => {
    let newReturn = [...scenario.scenario_assumptions_returns]
    const {name, value} = event.target

    newReturn.forEach((ret, i) => {
      if (ret.account_subtype_id === account_subtype_id) {
        newReturn[i][name] = value
        if (name === "yearly_growth_rate" || name === "withdrawal_tax_rate") { 
          newReturn[i][name] = Number(value) / 100.0
        }      
      }


    })

    updateScenario("scenario_assumptions_returns", newReturn)
    setUpdatedScenarioReturns(newReturn)

  }

  const handleSurplusOrderChanges = (value, account_subtype_id) => {

    let newReturn = [...scenario.scenario_assumptions_returns]

    newReturn.forEach((ret, i) => {
      if (ret.account_subtype_id === account_subtype_id) {
        newReturn[i].surplus_order = Number(value.value)
      }
    })

    updateScenario("scenario_assumptions_returns", newReturn)
    setUpdatedScenarioReturns(newReturn)
  };  

  const handleWithdrawalOrderChanges = (value, account_subtype_id) => {

    let newReturn = [...scenario.scenario_assumptions_returns]

    newReturn.forEach((ret, i) => {
      if (ret.account_subtype_id === account_subtype_id) {
        newReturn[i].withdrawal_order = Number(value.value)
      }
    })

    updateScenario("scenario_assumptions_returns", newReturn)
    setUpdatedScenarioReturns(newReturn)
  };  

  const handleSurplusTypeChanges = (value, account_subtype_id) => {

    let newReturn = [...scenario.scenario_assumptions_returns]

    newReturn.forEach((ret, i) => {
      if (ret.account_subtype_id === account_subtype_id) {
        newReturn[i].surplus_type = value.value
      }
    })

    updateScenario("scenario_assumptions_returns", newReturn)
    setUpdatedScenarioReturns(newReturn)
  };   

  const handleSurplusChanges = (event, account_subtype_id) => {
    let newReturn = [...scenario.scenario_assumptions_returns]
    const {value} = event.target

    newReturn.forEach((ret, i) => {
      if (ret.account_subtype_id === account_subtype_id) {
        if (newReturn[i].surplus_type === 'fixed') {
          newReturn[i].surplus_amount = Number(value)
        } else {
          newReturn[i].surplus_amount = Number(value) / 100.0
        }
        
      }
    })

    updateScenario("scenario_assumptions_returns", newReturn)
    setUpdatedScenarioReturns(newReturn)

  }  

  const handleDateChanges = (value, account_subtype_id) => {

    let newReturn = [...scenario.scenario_assumptions_returns]

    newReturn.forEach((ret, i) => {
      if (ret.account_subtype_id === account_subtype_id) {
        if (value) {
          newReturn[i].withdrawal_min_date = moment(value).format('YYYY-MM-DD')
        }
        else {
          newReturn[i].withdrawal_min_date = null
        }        
      }
    })

    updateScenario("scenario_assumptions_returns", newReturn)
    setUpdatedScenarioReturns(newReturn)
  };  

	return (
	  <div>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.cellLessPadding} variant="head" align="left">Account Type</TableCell>
                <TableCell className={classes.cellLessPadding} variant="head" align="right">Current Balance</TableCell>
                <TableCell className={classes.cellLessPadding} variant="head" align="right">Yearly Growth Rate</TableCell> 
                <TableCell className={classes.cellLessPadding} variant="head" align="center">Surplus Order</TableCell>
                <TableCell className={classes.cellLessPadding} variant="head" align="left">Surplus Amount</TableCell>
                <TableCell className={classes.cellLessPadding} variant="head" align="center">Withdrawal Order</TableCell>
                <TableCell className={classes.cellLessPadding} variant="head" align="right">Withdrawal Min. Date</TableCell>
                <TableCell className={classes.cellLessPadding} variant="head" align="right">Withdrawal Tax Rate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {updatedScenarioReturns.map(ret => (
                <TableRow key={ret.account_subtype_id} >
                  <TableCell className={classes.cellLessPadding} align="left">
                    {accountTypes[ret.account_subtype_id].account_subtype_name} 
                  </TableCell>
                  <TableCell className={classes.cellLessPadding} align="right">
                    <NumberFormat value={account_subtypes_total[ret.account_subtype_id]} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                  </TableCell>
                  <TableCell className={classes.cellLessPadding} align="right">
                    {!editMode && 
                    <NumberFormat value={ret.yearly_growth_rate * 100} displayType={'text'} thousandSeparator={true} decimalScale={2} suffix={"%"} fixedDecimalScale={true} />
                    }
                    {editMode &&
                    <TextField
                      type="number"
                      id="yearly_growth_rate"
                      name="yearly_growth_rate"
                      className={classes.growthInput}
                      defaultValue={ret.yearly_growth_rate * 100.0}
                      onChange={(event) => handleChanges(event, ret.account_subtype_id)}
                      inputProps={{
                        style: { textAlign: "right" },
                        step: 0.01,
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                    />
                    }
                  </TableCell>
                  <TableCell className={classes.cellLessPadding} align="center">
                    {!editMode &&
                      <span>{ret.surplus_order}</span>
                    }  
                    {editMode &&
                      <Select
                        className={classes.orderInput}
                        value={{
                          value: ret.surplus_order, 
                          label: ret.surplus_order,
                        }}
                        onChange={(value) => handleSurplusOrderChanges(value, ret.account_subtype_id)}
                        options={orderOptions}
                        >
                      </Select>
                    }
                  </TableCell>
                   <TableCell className={classes.cellLessPadding} align="left">
                    {!editMode && ret.surplus_type === 'fixed' && 
                      <NumberFormat value={ret.surplus_amount} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                    }
                    {!editMode && ret.surplus_type === 'percent' && 
                      <NumberFormat value={ret.surplus_amount * 100} displayType={'text'} thousandSeparator={true} decimalScale={2} suffix={"%"} fixedDecimalScale={true} />
                    }
                    {editMode &&
                      <Grid container>
                        <Grid item>
                       <Select
                        className={classes.surplusInput}
                        value={{
                          value: ret.surplus_type, 
                          label: surplusTypes[ret.surplus_type],
                        }}
                        onChange={(value) => handleSurplusTypeChanges(value, ret.account_subtype_id)}
                        options={surplusOptions}
                        >
                      </Select> 
                      </Grid>
                      <Grid item>
                      {editMode && ret.surplus_type === 'fixed' &&
                        <TextField
                          type="number"
                          // id="yearly_amount"
                          name="surplus"
                          className={classes.surplusAmountInput}
                          defaultValue={ret.surplus_amount}
                          onChange={(event) => handleSurplusChanges(event, ret.account_subtype_id)}
                          inputProps={{
                            style: { textAlign: "right", fontSize: "0.875rem" },
                            step: 0.01,
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                        />
                      }
                      {editMode && ret.surplus_type === 'percent' &&
                        <TextField
                          type="number"
                          // id="yearly_amount"
                          name="surplus"
                          className={classes.surplusAmountInput}
                          defaultValue={ret.surplus_amount * 100.0}
                          onChange={(event) => handleSurplusChanges(event, ret.account_subtype_id)}
                          inputProps={{
                            style: { textAlign: "right", fontSize: "0.875rem" },
                            step: 0.01,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                        />
                      }
                    </Grid>
                    </Grid>                    
                    }
                  </TableCell>
                  <TableCell className={classes.cellLessPadding} align="center">
                    {!editMode &&
                      <span>{ret.withdrawal_order}</span>
                    }  
                    {editMode &&
                      <Select
                        className={classes.orderInput}
                        value={{
                          value: ret.withdrawal_order, 
                          label: ret.withdrawal_order,
                        }}
                        onChange={(value) => handleWithdrawalOrderChanges(value, ret.account_subtype_id)}
                        options={orderOptions}
                        >
                      </Select>
                    }
                  </TableCell>
                  <TableCell className={classes.cellLessPadding} align="right">
                    {!editMode && ret.withdrawal_min_date &&
                      <span>{moment(ret.withdrawal_min_date).format('MM/DD/YYYY')}</span>
                    }
                    {!editMode && !ret.withdrawal_min_date &&
                      <span>None</span>
                    }
                  {editMode && ret.withdrawal_min_date && 
                    <Fragment>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            InputProps={{
                              classes: {
                                input: classes.dateInput,
                              },
                            }}
                            disableToolbar
                            className={classes.dateInput}
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            name="start_date"
                            value={moment(ret.withdrawal_min_date).format('MM/DD/YYYY')}
                            onChange={(value) => handleDateChanges(value, ret.account_subtype_id)}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        <IconButton aria-label="edit" onClick={() => handleDateChanges(null, ret.account_subtype_id)}>
                          <ClearIcon fontSize="small" />
                        </IconButton>
                    </Fragment>
                  }
                  {editMode && !ret.withdrawal_min_date && 
                    <Button color="primary" onClick={() => handleDateChanges(moment(), ret.account_subtype_id)}>Add</Button>
                  }
                  </TableCell>
                  <TableCell className={classes.cellLessPadding} align="right">
                    {!editMode && 
                      <NumberFormat value={ret.withdrawal_tax_rate * 100.0} displayType={'text'} thousandSeparator={true} suffix={'%'} decimalScale={2} fixedDecimalScale={true} />
                    }
                    {editMode &&
                    <TextField
                      type="number"
                      // id="tax_rate"
                      name="withdrawal_tax_rate"
                      className={classes.taxInput}
                      defaultValue={ret.withdrawal_tax_rate  * 100.0}
                      onChange={(event) => handleChanges(event, ret.account_subtype_id)}
                      inputProps={{
                        style: { textAlign: "right", fontSize: "0.875rem" },
                        step: 0.01,
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                    />
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
	  </div>
	)
}

const mapStateToProps = (state,props) => {
  const { space_id } = props

  let account_ids = state.spaceAccounts[space_id]


  let account_subtypes_total = {}

  Object.keys(state.accountTypes).forEach((account_subtype_id) => {
    account_subtypes_total[account_subtype_id] = 0
  })

  account_ids.forEach((account_id) => {
    account_subtypes_total[state.accounts[account_id].account_subtype_id] += state.accounts[account_id].latest_balance
  })

  return {
    accountTypes: state.accountTypes,
    account_subtypes_total
  }

};

export default connect(mapStateToProps)(withStyles(styles)(ScenarioDetailReturns));