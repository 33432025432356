import React, { Fragment } from "react";
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux'
import BudgetCell from '../components/BudgetCell'
import TextField from '@material-ui/core/TextField';


const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 600,
  },
  header: {
    paddingBottom: 12,
  },
  cellLessPadding: {
    paddingRight: 16,
    fontSize: "0.8rem"
  },  

  tightCell: {
    paddingRight: 6,
    paddingLeft: 6,
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    fontSize: "0.75rem"
  },  
  tightCellHead: {
    paddingRight: 6,
    paddingLeft: 6,
  }, 
  cellNoBottom: {
    borderBottom: "0px",
  },
  negativeCell : {
    color: 'red',
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  underBudget: {
    background: "#a5d6a7",
  },
  overBudget: {
    background: "#ef9a9a",
  },
  transactionPopover: {
    maxWidth: "1200px",
  },
  budgetInput: {
    fontSize: "small",
    // width: "60px",
  },
  budget: {
    fontSize: "0.75rem",
    fontStyle: "italic",
    textAlign: "right"
  }
});


const BudgetRow = (props) => {
	const { classes } = props;
	const { dates, transaction_category_id, budget, transactionCategorySpend, transactionCategory } = props;
  const { editMode, handleInfoClick, updateBudget } = props

  let totalSpending = 0.0
  if (transactionCategorySpend) {
    dates.forEach((date) => {
      totalSpending += transactionCategorySpend[date] ? transactionCategorySpend[date] : 0.0
    })
  }

  const avgSpending = totalSpending / dates.length

  const handleChange = event => {
    updateBudget(transaction_category_id,event.target.value)
  };

	return(
        <TableRow key={transaction_category_id} >
          <TableCell className={classes.cellLessPadding} align="left">{transactionCategory.transaction_category_name}</TableCell>
          <TableCell className={classes.tightCell} align="right">
          {!editMode && 
            <div className={classes.budget}>
            <NumberFormat value={budget} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0} fixedDecimalScale={true} />
            </div>
          }
          {editMode &&
            <Fragment>
              <TextField 
                  className={classes.budgetInput} 
                  id={transaction_category_id} 
                  label=""
                  defaultValue={budget}
                  onChange={handleChange}
                  InputProps={{
                    classes: {
                      input: classes.budget,
                    },
                  }}
                />
            </Fragment>
          }
          </TableCell>
          {dates.map( (date) => ( 
            <BudgetCell budget={budget} 
              amount={transactionCategorySpend && transactionCategorySpend[date] ? transactionCategorySpend[date] : 0 }
              transaction_category_id={transaction_category_id} 
              key={transaction_category_id.toString() + "?" + date}
              k={transaction_category_id.toString() + "?" + date}
              handleInfoClick={handleInfoClick}
              />
          ))}
          {dates.length > 1 && 
          <React.Fragment>
            <BudgetCell budget={budget} 
                amount={avgSpending ? avgSpending : 0.0}
                transaction_category_id={transaction_category_id} 
                key={transaction_category_id.toString() + "?average"}
                k={transaction_category_id.toString() + "?average"}
                handleInfoClick={null}
                />
             <BudgetCell budget={null} 
                amount={totalSpending ? totalSpending : 0.0}
                transaction_category_id={transaction_category_id} 
                key={transaction_category_id.toString() + "?total"}
                k={transaction_category_id.toString() + "?total"}
                handleInfoClick={null}
                />
          </React.Fragment>
          }

        </TableRow>

	);
};


export default connect()(withStyles(styles)(BudgetRow));