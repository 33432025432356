import { call, put, takeEvery  } from 'redux-saga/effects';
import { LOAD_SCENARIOS, 
	RECEIVE_SCENARIOS, 
	ADD_SCENARIO, 
	ADD_SCENARIO_SUCCEEDED, 
	UPDATE_SCENARIO, 
	UPDATE_SCENARIO_FAILED, 
	UPDATE_SCENARIO_SUCCEEDED,
	SET_DEFAULT_SPACE_SCENARIO } from '../actions/scenarios'
import { API_PATH } from '../utilities/apiPath'

function* fetchScenarios(action) {
  const token = action.token

  if (token) {
	  const endpoint = API_PATH + 'api/scenariosforuser';
	  const options = {
		  headers: {
		    Authorization: `Bearer ${token}`
		  }

	  }
	  const response = yield call(fetch, endpoint, options);
	  const data = yield response.json();
	  yield put({ type: RECEIVE_SCENARIOS, scenarios: data });
	}
}

export function* loadScenarios() {
  yield takeEvery(LOAD_SCENARIOS, fetchScenarios);
}


function* postAddScenario(action) {
	//const user = yield select(getUser);
	const token = action.token

	if (token) {
		const endpoint = API_PATH + 'api/scenario'
		const options = {
			method: 'POST',
	        headers: {
	          'Accept': 'application/json',
	          'Content-Type': 'application/json',
	          'Authorization': `Bearer ${token}`
	        },
	        body: JSON.stringify(action.scenario)
		}
		try {
			const response = yield call(fetch, endpoint, options);
	  		const data = yield response.json();
			yield put({ type: ADD_SCENARIO_SUCCEEDED, scenario: data });
		}
		catch {
			// Do Nothing
		}
		
	  	
	  	
	}

}

export function* addScenario() {
  yield takeEvery(ADD_SCENARIO, postAddScenario);
}

function* postUpdateScenario(action) {
	//const user = yield select(getUser);
	const token = action.token

	if (token) {
		const endpoint = API_PATH + 'api/scenario/' + action.scenario.scenario_id
		const options = {
			method: 'POST',
	        headers: {
	          'Accept': 'application/json',
	          'Content-Type': 'application/json',
	          'Authorization': `Bearer ${token}`
	        },
	        body: JSON.stringify(action.updatedScenario)
		}
		try {
			const response = yield call(fetch, endpoint, options);
			const data = yield response.json();
			yield put({ type: UPDATE_SCENARIO_SUCCEEDED, scenario: data });
		}
		catch {
			yield put({ type: UPDATE_SCENARIO_FAILED, scenario: action.scenario });
		}
		
	  	
	  	
	}

}

export function* updateScenario() {
  yield takeEvery(UPDATE_SCENARIO, postUpdateScenario);
}


function* postSetDefaultScenario(action) {
	//const user = yield select(getUser);
	const token = action.token

	if (token) {
		const endpoint = API_PATH + 'api/scenariodefault'
		const options = {
			method: 'POST',
	        headers: {
	          'Accept': 'application/json',
	          'Content-Type': 'application/json',
	          'Authorization': `Bearer ${token}`
	        },
	        body: JSON.stringify({
	      	scenario_id: action.scenario_id,
	      	space_id: action.space_id
	      })
		}
		try {
			yield call(fetch, endpoint, options);
			// const data = yield response.json();
			// yield put({ type: UPDATE_SCENARIO_SUCCEEDED, scenario: data });
		}
		catch {
			// yield put({ type: UPDATE_SCENARIO_FAILED, scenario: action.scenario });
		}
	}

}

export function* setDefaultScenario() {
  yield takeEvery(SET_DEFAULT_SPACE_SCENARIO, postSetDefaultScenario);
}