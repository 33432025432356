import { RECEIVE_TRANSACTION_CATEGORY_SPEND } from '../actions/spaceTransactionCategorySpend'
import moment from 'moment'

export default function spaceTransactionCategorySpend (state = {}, action) {

  switch(action.type) {
    case RECEIVE_TRANSACTION_CATEGORY_SPEND :

    	let formatted_time_series = {}

    	action.transactionCategorySpend.timeSeries.forEach((element) => {
    		formatted_time_series[element.space_id] = formatted_time_series[element.space_id] ? formatted_time_series[element.space_id] : {}
    		formatted_time_series[element.space_id][element.transaction_category_id] = formatted_time_series[element.space_id][element.transaction_category_id] ? formatted_time_series[element.space_id][element.transaction_category_id] : {}
    		let formatted_date = moment(element.year + '-' + element.month + '-01', "YYYY-MM-DD").format('YYYY-MM-DD')
    		formatted_time_series[element.space_id][element.transaction_category_id][formatted_date] = element.amount
    	})

    	let formatted_dates = action.transactionCategorySpend.dates.map((date) => {
    		return moment(date + '-01', "YYYY-MM-DD").format('YYYY-MM-DD')
    	})


      return {
      	dates: formatted_dates,
      	transactionCategoryTimeSeries: {...formatted_time_series}
      }
      
    default :
      return state
  }
}