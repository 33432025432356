import React from "react";
import UserTable from '../components/UserTable'
import RulesTable from '../components/RulesTable'

const SettingsView = (props) => {

	const { space_id } = props.match ? props.match.params : {space_id: null};


	return (
	  <div>
	  	<UserTable space_id={space_id} />
	  	<RulesTable space_id={space_id} />
	  </div>
	)
}

export default SettingsView;