import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { LOAD_TRANSACTIONS, 
	RECEIVE_TRANSACTIONS, 
	UPDATE_TRANSACTION, 
	UPDATE_TRANSACTION_SUCCEEDED, 
	UPDATE_TRANSACTION_FAILED,
	SPLIT_TRANSACTION,
	SPLIT_TRANSACTION_SUCCEEDED,
	SPLIT_TRANSACTION_FAILED } from '../actions/transactions';
import moment from 'moment'
import { LOAD_TRANSACTION_CATEGORY_SPEND} from '../actions/spaceTransactionCategorySpend';
import { API_PATH } from '../utilities/apiPath'

function* fetchTransactions(action) {
  const token = action.token

  if (token) {
	  var endpoint = API_PATH + 'api/transactionsforuser';
	  const options = {
		  headers: {
		    Authorization: `Bearer ${token}`
		  }

	  }
	var start_date = moment().subtract(30,'d').format('YYYY-MM-DD');
	const filters = {
		start_date: start_date,
		limit: 100
	};

    if (Object.keys(filters).length > 0) {

      var esc = encodeURIComponent;
      var query = Object.keys(filters)
      .map(k => esc(k) + '=' + esc(filters[k]))
      .join('&');

      endpoint = endpoint + "?" + query

    }

	  const response = yield call(fetch, endpoint, options);
	  const data = yield response.json();
	  yield put({ type: RECEIVE_TRANSACTIONS, transactionResponse: data });
	}
}

export function* loadTransactions() {
  yield takeEvery(LOAD_TRANSACTIONS, fetchTransactions);
}


function* putUpdateTransaction(action) {
	const token = action.token

	if (token) {
		const endpoint = API_PATH + 'api/transaction/' + action.transaction.transaction_id
		const options = {
			method: 'PUT',
	        headers: {
	          'Accept': 'application/json',
	          'Content-Type': 'application/json',
	          'Authorization': `Bearer ${token}`
	        },
	        body: JSON.stringify(action.updatedTransaction)
		}

		try {
			yield call(fetch, endpoint, options);
			yield put({ type: UPDATE_TRANSACTION_SUCCEEDED, transaction: action.transaction });
		}
		catch {
			yield put({ type: UPDATE_TRANSACTION_FAILED, account: action.transaction });
		}
		
	  	
	  	
	}

}

export function* updateTransaction() {
  yield takeEvery(UPDATE_TRANSACTION, putUpdateTransaction);
}

function* resetBudget(action) {
	const token = action.token

	yield put({ type: LOAD_TRANSACTION_CATEGORY_SPEND, token });

}

export function* updateTransactionResetBudget() {
  yield takeLatest(UPDATE_TRANSACTION, resetBudget);
  yield takeLatest(SPLIT_TRANSACTION_SUCCEEDED, resetBudget);
}


function* postSplitTransaction(action) {
	const token = action.token

	if (token) {
		const endpoint = API_PATH + 'api/transaction/' + action.transaction_id + '/split'
		const options = {
			method: 'POST',
	        headers: {
	          'Accept': 'application/json',
	          'Content-Type': 'application/json',
	          'Authorization': `Bearer ${token}`
	        },
	        body: JSON.stringify({
	      		split_transactions: action.splitTransactions
	      })
		}

		try {
			const response = yield call(fetch, endpoint, options);
			const data = yield response.json();
			yield put({ type: SPLIT_TRANSACTION_SUCCEEDED, 
				transaction_id: action.transaction_id,
				newTransactions: data.transactions,
				token
			});
		}
		catch {
			yield put({ type: SPLIT_TRANSACTION_FAILED, transaction_id: action.transaction_id });
		}
	}
}

export function* splitTransaction() {
  yield takeEvery(SPLIT_TRANSACTION, postSplitTransaction);
}