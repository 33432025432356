import { call, put, takeEvery } from 'redux-saga/effects';
import { LOAD_BUDGETS,
	RECEIVE_BUDGETS, UPDATE_BUDGETS, UPDATE_BUDGETS_SUCCEEDED, UPDATE_BUDGETS_FAILED } from '../actions/spaceTransactionCategoryBudgets';
import { API_PATH } from '../utilities/apiPath'


function* fetchBudgets(action) {
  const token = action.token

  if (token) {
	  const endpoint = API_PATH + 'api/budgetforuser';
	  const options = {
		  headers: {
		    Authorization: `Bearer ${token}`
		  }

	  }
	  const response = yield call(fetch, endpoint, options);
	  const data = yield response.json();
	  yield put({ type: RECEIVE_BUDGETS, spaceBudgets: data });
	}
}

export function* loadBudgets() {
  yield takeEvery(LOAD_BUDGETS, fetchBudgets);
}


function* putUpdateBudgets(action) {
	const token = action.token

	if (token) {
		const endpoint = API_PATH + 'api/budget/' + action.space_id
		const options = {
			method: 'PUT',
	        headers: {
	          'Accept': 'application/json',
	          'Content-Type': 'application/json',
	          'Authorization': `Bearer ${token}`
	        },
	        body: JSON.stringify(action.updatedTransactionCategoryBudgets)
		}
		try {
			yield call(fetch, endpoint, options);
			yield put({ type: UPDATE_BUDGETS_SUCCEEDED });
		}
		catch {
			yield put({ type: UPDATE_BUDGETS_FAILED, space_id: action.space_id, transactionCategoryBudgets: action.transactionCategoryBudgets });
		}
	}
}

export function* updateBudgets() {
  yield takeEvery(UPDATE_BUDGETS, putUpdateBudgets);
}