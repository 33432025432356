
import React, { Component } from "react";
import { connect } from 'react-redux';
import LoginButton  from './LoginButton';
import { Redirect } from 'react-router-dom'

class Welcome extends Component {
  // const { loading, user } = useAuth0();

  render () {
    const { user } = this.props

    if (!user) {
      return (
        <div>
          <div>Welcome, please log in.</div>
          <LoginButton />
        </div>
      );
    }

    else {
      return <Redirect to='/' />
    }

  };

}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(Welcome);