import { all, call, put, takeEvery  } from 'redux-saga/effects';
import { LOAD_SPACES, RECEIVE_SPACES } from '../actions/spaces';
import { REQUEST_TRANSACTION_QUERY } from '../actions/transactionQueries'
import { getTransactionQueryParams } from '../utilities/transactionFilters'
import { API_PATH } from '../utilities/apiPath'

function* fetchSpaces(action) {
  const token = action.token

  if (token) {
	  const endpoint = API_PATH + 'api/spaces';
	  const options = {
		  headers: {
		    Authorization: `Bearer ${token}`
		  }

	  }
	  const response = yield call(fetch, endpoint, options);
	  const data = yield response.json();

	  let requests = [
	  	put({ type: RECEIVE_SPACES, spaces: data, token }),
	  	]

	const review_filters = {
		is_reviewed: false
	};


	 data.forEach((space) => {
	 	var queryParams = getTransactionQueryParams(review_filters, space.space_id)
	 	requests.push(put({ type: REQUEST_TRANSACTION_QUERY, queryParams, token }))
	 })

	  yield all(requests)
	}
}

export function* loadSpaces() {
  yield takeEvery(LOAD_SPACES, fetchSpaces);
}