import React, { Fragment } from "react";
import { connect } from 'react-redux'
import ProgressSpinner from '../utilities/ProgressSpinner'
import { Redirect } from "react-router-dom";

const Home = (props) => {

	const {userPrioSpace} = props
	let redirectString = "/dashboard/" + userPrioSpace

	return (
		<Fragment>
		{ userPrioSpace && 
			<Redirect to={redirectString} />
		}
		{!userPrioSpace &&
			<ProgressSpinner />
		}
		</Fragment>
	)

}

const mapStateToProps = (state, props) => {

	if (!state.user || !state.user.userApp || !state.user.userApp.space_users) {
		return {
			userPrioSpace: null
		}
	}

	const space_users = state.user.userApp.space_users.sort((a,b) => a.priority - b.priority )

	return {
		userPrioSpace: space_users[0].space_id
	} 

};

export default connect(mapStateToProps)(Home);