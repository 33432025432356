import React from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PersonIcon from '@material-ui/icons/Person';
import PieChartIcon from '@material-ui/icons/PieChart';
import {NavLink} from "react-router-dom";
import clsx from 'clsx';
// import BusinessIcon from '@material-ui/icons/Business';
// import ListAltIcon from '@material-ui/icons/ListAlt';
import { connect } from 'react-redux'
// import { loadUserFinancialData } from '../actions/shared'
// import { useAuth0 } from "@auth0/auth0-react";
import DashboardIcon from '@material-ui/icons/Dashboard';
import UserMenu from '../components/UserMenu'
import ReceiptIcon from '@material-ui/icons/Receipt';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import SettingsIcon from '@material-ui/icons/Settings';

const categories = [
  {
    id: 'My Data',
    children: [
      // { id: 'Dashboard', icon: <DashboardIcon />, path:"/"  },
      // { id: 'Institutions', icon: <BusinessIcon />, path:"/items"  },
      { id: 'My Accounts', icon: <AccountBalanceIcon />, path:"/accounts"  },
      { id: 'Profile', icon: <PersonIcon />, path:"/profile"  },
    ],
  },
];
const space_children = [
  { id: 'Dashboard', icon: <DashboardIcon />, path:"/dashboard" },
  { id: 'Net Worth', icon: <AccountBalanceWalletIcon />, path:"/networth" },
  { id: 'Budget & Cashflow', icon: <PieChartIcon />, path:"/budget" },
  { id: 'Plan', icon: <HomeWorkIcon />, path:"/plan"},
  { id: 'Accounts', icon: <AccountBalanceIcon />, path:"/accounts" },
  { id: 'Transactions', icon: <ReceiptIcon />, path:"/transactions"},
  { id: 'Credit', icon: <CreditCardIcon />, path:"/credit"},
  { id: 'Settings', icon: <SettingsIcon />, path:"/settings"},
];


const styles = theme => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  arrowIcon: {
    color: "white",
  }
});

const Navigator = (props) => {
  const { classes, PaperProps, variant, open, onClose  } = props;
  // const { user } = props
  const { user, spaces, space_ids } = props
  // const { getAccessTokenSilently } = useAuth0();

  // async function reloadInitialData() {
  //   const token = await getAccessTokenSilently();
  //   dispatch(loadUserFinancialData(token))
  // }

  return (
    <Drawer variant={variant} PaperProps={PaperProps} open={open} onClose={onClose} >   
      <List disablePadding>
        <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
          Clarity
        </ListItem>
            {space_ids.map( space_id => (
              <React.Fragment key={space_id}>
                <ListItem className={classes.categoryHeader}>
                  <ListItemText
                    classes={{
                      primary: classes.categoryHeaderPrimary,
                    }}
                  >
                    {spaces[space_id].space_name}
                  </ListItemText>
                </ListItem>
                {space_children.map(({ id: childId, icon, active, path }) => (
                  <ListItem
                    button
                    dense
                    key={childId}
                    component={NavLink} exact to={path + "/" + space_id}
                    className={classNames(
                      classes.item,
                      classes.itemActionable,
                      active && classes.itemActiveItem,
                    )}
                    activeClassName={classes.itemActiveItem}
                  >
                    <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                    <ListItemText
                      classes={{
                        primary: classes.itemPrimary,
                        // textDense: classes.textDense,
                      }}
                    >
                      {childId}
                    </ListItemText>
                  </ListItem>
                ))}
                <Divider className={classes.divider} />
              </React.Fragment>
            ))
        }
        {categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                  <UserMenu user={user} />

              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, active, path }) => (
              <ListItem
                button
                key={childId}
                component={NavLink} exact to={path}
                className={clsx(classes.item, active && classes.itemActiveItem)}
                activeClassName={classes.itemActiveItem}
              >
                <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                    // textDense: classes.textDense,
                  }}
                >
                  {childId}
                </ListItemText>
              </ListItem>
            ))}
            <Divider className={classes.divider} />
          </React.Fragment>
          ))}
      </List>
    </Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    user: state.user,
    spaces: state.spaces,
    space_ids: Object.keys(state.spaces).filter((space_id) => state.spaces[space_id].is_deleted == false)
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Navigator));