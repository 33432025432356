import React, {useState, Fragment} from 'react';
import { withStyles } from '@material-ui/core/styles';
import ProgressSpinner from '../utilities/ProgressSpinner'
import Paper from '@material-ui/core/Paper';
import Title from './Title'
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, LineChart, Line, ReferenceLine, Label
} from 'recharts';
import { connect } from 'react-redux'
import moment from 'moment'
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import IconButton from '@material-ui/core/IconButton';
import NumberFormat from 'react-number-format';
import { labelFormater } from '../utilities/labelFormater'

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    // minWidth: 200,
    // width: '100%',
    // overflowX: 'auto',
  },
  selectMonths: {
    minWidth: 220,
  },
  chartSettings: {
  	float: "right"
  },
  chartTooltip: {
  	margin: "0px",
  	padding: "10px",
  	backgroundColor: "white",
  	border: "1px solid rgb(204, 204, 204)",
  	whiteSpace: "nowrap"
  },
  chartTooltipLabel: {
  	margin: "0px",
  	fontWeight: "bold",
  },
  chartTooltipItemList: {
  	margin: "0px",
  	padding: "0px",
  },  
  chartTooltipItem: {
  	display: "block", 
  	paddingTop: "4px",
  	paddingBottom: "4px",
  },
  chartTooltipItemTotal: {
  	display: "block", 
  	paddingTop: "4px",
  	paddingBottom: "4px",
  	fontWeight: "bold",
  }

});


// const colorArray = ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', 
// '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff',
// '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', 
// '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000'];

// const colorArray = ['#80cbc4','#80deea','#b39ddb','#9fa8da','#ffe082','#b0bec5'];
// const colorArray = ['#c5e1a5','#ef9a9a','#90caf9','#b39ddb','#ffe082','#b0bec5'];
// Google Pallette - 500 level
// const colorArray = ['#f44336','#4caf50','#673ab7','#2196f3','#ff9800','#009688'];
const colorArray = ['#4caf50','#673ab7','#2196f3','#ff9800','#009688'];


const NetWorthTimeChart = (props) => {
	const { classes } = props;
	const { dataReady, formattedTimeSeries, subcategories, formattedTimeSeriesLongTerm, formattedTimeSeriesFuture, subcategoriesNet
			, formattedTimeSeriesNet, formattedTimeSeriesLongTermNet, formattedTimeSeriesFutureNet, sub_categories_net } = props
	const [dataType, setDataType] = useState(5)
	const [areaChart, setAreaChart] = useState(true)

	const firstPoint = formattedTimeSeriesFuture && formattedTimeSeriesFuture[0] ? formattedTimeSeriesFuture[0] : null;

	const yearToDateYear = parseInt(moment().format('YYYY'));

	if (dataReady) {
		var chartData = dataType === 0 ? formattedTimeSeriesLongTermNet : dataType === -1 ?  formattedTimeSeriesNet : dataType === -2 ?  formattedTimeSeriesNet.filter((x) => moment(x.date).year() === moment().year()) : formattedTimeSeriesLongTermNet.concat(formattedTimeSeriesFutureNet.filter((x) => moment(x.date) <= moment(firstPoint.date).add(dataType,'y')))
	}

	const CustomTooltip = ({ active, payload, label, firstDataPoint }) => {

	  if (active) {
	  	var totalValue = 0
	  	var totalFirstValue = 0
	  	payload.forEach((pld) => {
	  		totalValue += pld.value
	  		totalFirstValue += firstDataPoint[pld.name]
	  	})
	  	var totalPercentChange = (totalValue - totalFirstValue) / totalFirstValue * 100.0
	    return (
	      <div className={classes.chartTooltip}>
	      	<p className={classes.chartTooltipLabel}>
	      		{moment(label).format('MM/DD/YY')}
	      	</p>
	      	<ul className={classes.chartTooltipItemList}>
	      		<li className={classes.chartTooltipItemTotal} >
	      			<span className="recharts-tooltip-item-name">
	      				Total
	        		</span>
	        		<span className="recharts-tooltip-item-separator">
	        			{` : `}
	        		</span>
	        		<span className="recharts-tooltip-item-value">
	        			<NumberFormat value={totalValue} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
	        			{" "}(<NumberFormat value={totalPercentChange} displayType={'text'} thousandSeparator={false} suffix={'%'} decimalScale={1} fixedDecimalScale={true} prefix={totalPercentChange > 0 ? "+" : ""} />)
	        		</span>
        		</li>
		      	{payload.reverse().map((pld) => (
		      		<li key={pld.name} className={classes.chartTooltipItem} style={{color: `${pld.color}`}}>
		      			<span>
		      				{pld.name}
		        		</span>
		        		<span>
		        			{` : `}
		        		</span>
		        		<span>
		        			<NumberFormat value={pld.value} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
		        			{firstDataPoint[pld.name] !== 0 && 
		        				<span>
		        				{" "}(<NumberFormat value={((pld.value - firstDataPoint[pld.name]) / firstDataPoint[pld.name]) * 100.0} displayType={'text'} thousandSeparator={false} suffix={'%'} decimalScale={1} fixedDecimalScale={true} prefix={(pld.value - firstDataPoint[pld.name]) > 0 ? "+" : ""} />)
		        				</span>
		        			}
		        		</span>
	        		</li>
		        ))}
	      	</ul>
	      </div>
	    );
	  }

	  return null;
	};	

	return(
			<React.Fragment>
		      <Paper className={classes.paper}>
		          <Grid container alignItems="center" justify="space-between">
		              <Grid item xs={12} sm={6}>
		                <Title>Net Worth Time Series</Title>
		              </Grid>
		              <Grid item xs={12} sm={6}>
		              	<div className={classes.chartSettings}>
		                <Select
		                  value={dataType}
		                  onChange={(event) => setDataType(event.target.value)}
		                  className={classes.selectMonths}
		                >
		                  <MenuItem value={-2}>{yearToDateYear} YTD</MenuItem>
		                  <MenuItem value={-1}>Last 12 Months</MenuItem>
		                  <MenuItem value={0}>Historical</MenuItem>
		                  <MenuItem value={5}>Historical & Plan (5 yrs)</MenuItem>
		                  <MenuItem value={10}>Historical & Plan (10 yrs)</MenuItem>
		                  <MenuItem value={20}>Historical & Plan (20 yrs)</MenuItem>
		                </Select>
		                <IconButton color={areaChart ? "default" : "primary"} onClick={() => {setAreaChart(!areaChart)}}>
		                	<ShowChartIcon fontSize="small" />
		                </IconButton>
		                </div>

		              </Grid>
		            </Grid>		      
				{!dataReady &&
		        	<ProgressSpinner />
		  		}
		  		{dataReady &&
		  			<Fragment>
		  		
		  			{areaChart &&
			        	<ResponsiveContainer width="98%" height={400}>
				    	<AreaChart data={chartData}
				            margin={{top: 10, right: 0, left: 30, bottom: 0}}>			            
				        <CartesianGrid strokeDasharray="3 3"/>
				       
				        <XAxis domain={['dataMin','dataMax']} dataKey="date_number" type="number" scale="time" tickFormatter={(date) => moment(date).format('MM/DD/YY')}/>
				        {/*<YAxis tickFormatter={(value) => new Intl.NumberFormat('en', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(value)} /> */}
				        <YAxis tickFormatter={labelFormater}/>
				        <Tooltip content={<CustomTooltip firstDataPoint={chartData[0]} />} />
				        {/*<Tooltip formatter={(value) => new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(value)} /> */}
				        <Legend />
				        <ReferenceLine x={moment().valueOf()} stroke="gray" strokeWidth={3} strokeDasharray="3 3">
				        	<Label value="Today" position="insideTopRight" />
				        </ReferenceLine>
				        {subcategoriesNet.map((subtype, index) => (
				        		<Area connectNulls key={subtype} type='monotone' dataKey={subtype} stackId="1" stroke={sub_categories_net[subtype]} fill={sub_categories_net[subtype]} />
				        	))

				        }
				      	</AreaChart>
				      	</ResponsiveContainer>
			  		}
		  			{!areaChart &&
			        	<ResponsiveContainer width="98%" height={400}>
				    	<LineChart data={chartData}
				            margin={{top: 10, right: 0, left: 30, bottom: 0}}>			            
				        <CartesianGrid strokeDasharray="3 3"/>
				       
				        <XAxis domain={['dataMin','dataMax']} dataKey="date_number" type="number" scale="time" tickFormatter={(date) => moment(date).format('MM/DD/YY')}/>
				        <YAxis tickFormatter={labelFormater}/>
				        <Tooltip content={<CustomTooltip firstDataPoint={chartData[0]} />} />
				        {/*<Tooltip formatter={(value) => new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(value)} /> */}
				        <Legend />
				        <ReferenceLine x={moment().valueOf()} stroke="gray" strokeWidth={3} strokeDasharray="3 3">
				        	<Label value="Today" position="insideTopRight" />
				        </ReferenceLine>				        
				        {subcategoriesNet.map((subtype, index) => (
				        		<Line connectNulls key={subtype} type='monotone' dataKey={subtype} stroke={sub_categories_net[subtype]} fill={sub_categories_net[subtype]} dot={false} strokeWidth={2} />
				        	))

				        }
				      	</LineChart>
				      	</ResponsiveContainer>
			  		}
			     
			     </Fragment>
			    } 
			  </Paper>
			</React.Fragment>
	);
};

const mapStateToProps = (state, props) => {
  const { space_id } = props;
  const { timeSeries, accounts, accountTypes, timeSeriesLongTerm, scenarios, spaceAccounts } = state


  if (!timeSeries || !timeSeriesLongTerm || !accountTypes || !accounts || !state.spaces[space_id] || !state.spaceAccounts[space_id]) {
    return {
      dataReady: false
    }
  }

	var dateArray = [];
	var currentDate = moment().subtract(365,'d');
	var stopDate = moment();
	while (currentDate <= stopDate) {
	    dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
	    currentDate = moment(currentDate).add(1, 'days');
	}  

	let account_ids = (space_id ? state.spaceAccounts[space_id] : state.user.account_ids)
	
	let data = {}
	let dataNet = {}
	let sub_category_name = ''
	let sub_category_net_name = ''

	let sub_categories = {}
	let sub_categories_net = {}

	let max_date = ''

	dateArray.forEach((curDate) => {

		data[curDate] = {}
		dataNet[curDate] = {}
		account_ids.forEach((act_id) => {

			if(accountTypes[accounts[act_id].account_subtype_id]) {

				sub_category_name = accountTypes[accounts[act_id].account_subtype_id].account_subtype_name
				sub_category_net_name = accountTypes[accounts[act_id].account_subtype_id].account_subtype_net_name

				sub_categories[sub_category_name] = true
				sub_categories_net[sub_category_net_name] = accountTypes[accounts[act_id].account_subtype_id].default_chart_color

				data[curDate][sub_category_name] = data[curDate][sub_category_name] ? data[curDate][sub_category_name] : 0
				data[curDate][sub_category_name] += timeSeries[act_id] && timeSeries[act_id][curDate] ? timeSeries[act_id][curDate] : 0

				dataNet[curDate][sub_category_net_name] = dataNet[curDate][sub_category_net_name] ? dataNet[curDate][sub_category_net_name] : 0
				dataNet[curDate][sub_category_net_name] += timeSeries[act_id] && timeSeries[act_id][curDate] ? timeSeries[act_id][curDate] : 0


				data[curDate]['date'] = curDate
				data[curDate]['date_number'] = moment(curDate).valueOf()

				dataNet[curDate]['date'] = curDate
				dataNet[curDate]['date_number'] = moment(curDate).valueOf()				
				max_date = curDate
			}
		})
	})

	var dateArrayLongTerm = [];
	var currentDateLongTerm = moment();

	spaceAccounts[space_id].forEach((account_id) => {
		if (timeSeriesLongTerm[account_id]) {
			var minDate = moment(Object.keys(timeSeriesLongTerm[account_id])[0])
			if (minDate < currentDateLongTerm) {
				currentDateLongTerm = minDate
			}
		}

	})

	var stopDateLongTerm = moment();
	while (currentDateLongTerm <= stopDateLongTerm) {
	    dateArrayLongTerm.push( moment(currentDateLongTerm).format('YYYY-MM-DD') )
	    currentDateLongTerm = moment(currentDateLongTerm).add(1, 'months');
	}  
	// Add today/latest date to long term chart
	dateArrayLongTerm.push(max_date)
	
	let dataLongTerm = {}
	let dataLongTermNet = {}

	dateArrayLongTerm.forEach((curDate) => {

		dataLongTerm[curDate] = {}
		dataLongTermNet[curDate] = {}
		account_ids.forEach((act_id) => {

			if(accountTypes[accounts[act_id].account_subtype_id]) {

				sub_category_name = accountTypes[accounts[act_id].account_subtype_id].account_subtype_name
				sub_category_net_name = accountTypes[accounts[act_id].account_subtype_id].account_subtype_net_name


				sub_categories[sub_category_name] = true
				sub_categories_net[sub_category_net_name] = accountTypes[accounts[act_id].account_subtype_id].default_chart_color

				dataLongTerm[curDate][sub_category_name] = dataLongTerm[curDate][sub_category_name] ? dataLongTerm[curDate][sub_category_name] : 0
				dataLongTerm[curDate][sub_category_name] += timeSeriesLongTerm[act_id] && timeSeriesLongTerm[act_id][curDate] ? timeSeriesLongTerm[act_id][curDate] : 0

				dataLongTermNet[curDate][sub_category_net_name] = dataLongTermNet[curDate][sub_category_net_name] ? dataLongTermNet[curDate][sub_category_net_name] : 0
				dataLongTermNet[curDate][sub_category_net_name] += timeSeriesLongTerm[act_id] && timeSeriesLongTerm[act_id][curDate] ? timeSeriesLongTerm[act_id][curDate] : 0


				dataLongTerm[curDate]['date'] = curDate
				dataLongTerm[curDate]['date_number'] = moment(curDate).valueOf()

				dataLongTermNet[curDate]['date'] = curDate
				dataLongTermNet[curDate]['date_number'] = moment(curDate).valueOf()				

			}
		})
	})

	// Add today/latest date to long term chart
	dataLongTerm[max_date] = data[max_date]
	dataLongTermNet[max_date] = dataNet[max_date]


	let dataFuture = {}
	let dataFutureNet = {}
	let sub_categories_future = {}

	if (scenarios) {
		let defaultScenarioId = Object.keys(scenarios).filter((scenario_id) => scenarios[scenario_id].space_id === Number(space_id) && scenarios[scenario_id].space_default)[0]


		if (defaultScenarioId && accountTypes && Object.keys(accountTypes).length > 0) {
			scenarios[defaultScenarioId].scenario_calculation_balances.forEach((balance) => {
			let account_subtype_id = balance.account_subtype_id
			let account_subtype_name = accountTypes[account_subtype_id].account_subtype_name
			let account_subtype_net_name = accountTypes[account_subtype_id].account_subtype_net_name


			if (!dataFuture[balance.date]) {
				dataFuture[balance.date] = {}
				dataFuture[balance.date]['date'] = balance.date
				dataFuture[balance.date]['date_number'] = moment(balance.date).valueOf()

				dataFutureNet[balance.date] = {}
				dataFutureNet[balance.date]['date'] = balance.date
				dataFutureNet[balance.date]['date_number'] = moment(balance.date).valueOf()
			}

			if (balance.balance !== 0) {
				dataFuture[balance.date][account_subtype_name] = dataFuture[balance.date][account_subtype_name] ? dataFuture[balance.date][account_subtype_name] + balance.balance : balance.balance
				dataFutureNet[balance.date][account_subtype_net_name] = dataFutureNet[balance.date][account_subtype_net_name] ? dataFutureNet[balance.date][account_subtype_net_name] + balance.balance : balance.balance
				if (!sub_categories_future[account_subtype_id]) {
					sub_categories_future[account_subtype_id] = account_subtype_name
				}			
			}

		})			
		}

	}


  return {
    dataReady: true,
    formattedTimeSeries: Object.values(data),
    formattedTimeSeriesLongTerm: Object.values(dataLongTerm),
    formattedTimeSeriesFuture: Object.values(dataFuture),
    formattedTimeSeriesNet: Object.values(dataNet),
    formattedTimeSeriesLongTermNet: Object.values(dataLongTermNet),
    formattedTimeSeriesFutureNet: Object.values(dataFutureNet),
    subcategories: Object.keys(sub_categories).sort((a,b) => data[max_date][a] - data[max_date][b]),
    subcategoriesNet: Object.keys(sub_categories_net).sort((a,b) => dataNet[max_date][a] - dataNet[max_date][b]),
    sub_categories_net
  };
};



export default connect(mapStateToProps)(withStyles(styles)(NetWorthTimeChart));