import React, { useEffect } from "react";
// import PropTypes from "prop-types";
import { Route, withRouter } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../components/Loading"

import { connect } from 'react-redux'

const PrivateRoute = ({ component: Component, path, initialDataReady, dispatch, ...rest }) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: path }
      });
    };
    fn()


  }, [isLoading, isAuthenticated, loginWithRedirect, path]);

  const render = props =>
    isAuthenticated === true && initialDataReady ? <Component {...props} /> : <Loading />;

  return <Route path={path} render={render} {...rest} />;
};


const mapStateToProps = state => {
  return {
    initialDataReady: Object.keys(state.spaces).length > 0
  };
};

export default connect(mapStateToProps)(withRouter(PrivateRoute));
