// src/index.js

import React from "react";
import ReactDOM from "react-dom";
//import App from "./App";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import config from "./auth_config.json";
import history from "./utilities/history"
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import reducer from './reducers'
import middleware from './middleware'
import {runSaga} from './middleware'
import { loadState, saveState } from './utilities/localStorage'

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const persistedState = loadState();
//const persistedState = {}


const store = createStore(reducer, persistedState, middleware)

// store.subscribe(() => {
//   saveState({
//     spaces: store.getState().spaces,
//     accountTypes: store.getState().accountTypes,
//     user: store.getState().user,
//     transactionCategories: store.getState().transactionCategories,
//   });
// });

store.subscribe(() => {
  saveState(store.getState());
});

runSaga()

ReactDOM.render(
    <Auth0Provider
      domain={config.domain}
      clientId={config.clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: `${config.audience}`
      }}      
      // redirect_uri={window.location.origin}
      
      onRedirectCallback={onRedirectCallback}
  
  >
  <Provider store={store}>


      <App />
  </Provider>
  </Auth0Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();