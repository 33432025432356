import React, { useState, Fragment } from "react";
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux'
import moment from 'moment'
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from 'react-select';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';


const styles = theme => ({
  cellLessPadding: {
    paddingRight: 8,
    paddingLeft: 8,
  },
  incomeNameInput: {
    fontSize: "0.875rem"
  },
  growthInput: {
    width: "70px",
    fontSize: "0.875rem"
  },
  taxInput: {
    width: "70px",
    fontSize: "0.875rem"
  },
  incomeInput: {
    maxWidth: "120px",
    fontSize: "0.875rem"
  },
  userInput: {
    minWidth: "120px",
    fontSize: "0.875rem"
  },
  dateInput: {
    width: "130px",
    fontSize: "0.875rem"
  },
  recurringInput: {
    minWidth: "100px",
    fontSize: "0.875rem"
  },
});


const ScenarioDetailIncome = (props) => {

	const { scenario, classes, editMode, updateScenario } = props
  const { users, spaceUsers } = props
  const [updatedScenarioIncome, setUpdatedScenarioIncome] = useState([...scenario.scenario_assumptions_income])

  const userOptions = spaceUsers.map((user_id) => ({
    value: user_id,
    label: users[user_id].first_name + ' ' + users[user_id].last_name
  }))

  const recurringTypes = {
    1: "Monthly",
    2: "Yearly",
    3: "One Time",
  }


  const recurringOptions = Object.keys(recurringTypes).map((recurring_type_id) => ({
    value: recurring_type_id,
    label: recurringTypes[recurring_type_id]
  }))


  const handleChanges = (event, income_id) => {
    let newIncome = [...scenario.scenario_assumptions_income]
    const {name, value} = event.target

    newIncome.forEach((income, i) => {
      if (income.income_id === income_id) {
        newIncome[i][name] = value
        if (name === "yearly_growth_rate" || name === "tax_rate") { 
          newIncome[i][name] = Number(value) / 100.0
        }
      }
    })

    updateScenario("scenario_assumptions_income", newIncome)
    setUpdatedScenarioIncome(newIncome)

  }

  const handleUserChanges = (value, income_id) => {

    let newIncome = [...scenario.scenario_assumptions_income]

    newIncome.forEach((income, i) => {
      if (income.income_id === income_id) {
        newIncome[i].user_id = value.value
      }
    })

    updateScenario("scenario_assumptions_income", newIncome)
    setUpdatedScenarioIncome(newIncome)
  };

  const handleRecurringChanges = (value, income_id) => {

    let newIncome = [...scenario.scenario_assumptions_income]

    newIncome.forEach((income, i) => {
      if (income.income_id === income_id) {
        newIncome[i].recurring_type_id = Number(value.value)
        newIncome[i].recurring_type = {
          recurring_type_id: Number(value.value),
          recurring_name: recurringTypes[value.value]
        }
      }
    })

    updateScenario("scenario_assumptions_income", newIncome)
    setUpdatedScenarioIncome(newIncome)
  };

  const handleStartDateChanges = (value, income_id) => {

    let newIncome = [...scenario.scenario_assumptions_income]

    newIncome.forEach((income, i) => {
      if (income.income_id === income_id) {
        if (value) {
          newIncome[i].start_date = moment(value).format('YYYY-MM-DD')
        }
        else {
          newIncome[i].start_date = null
        }
      }
    })

    updateScenario("scenario_assumptions_income", newIncome)
    setUpdatedScenarioIncome(newIncome)
  };

  const handleEndDateChanges = (value, income_id) => {

    let newIncome = [...scenario.scenario_assumptions_income]

    newIncome.forEach((income, i) => {
      if (income.income_id === income_id) {
        if (value) {
          newIncome[i].end_date = moment(value).format('YYYY-MM-DD')
        }
        else {
          newIncome[i].end_date = null
        }
      }
    })

    updateScenario("scenario_assumptions_income", newIncome)
    setUpdatedScenarioIncome(newIncome)
  };

  const removeIncome = (income_id) => {

    let newIncome = [...scenario.scenario_assumptions_income]

    newIncome.forEach((income, i) => {
      if (income.income_id === income_id) {
        newIncome.splice(i, 1)
      }
    })


    updateScenario("scenario_assumptions_income", newIncome)
    setUpdatedScenarioIncome(newIncome)
  };

  const addIncome = () => {

    let newIncome = [...scenario.scenario_assumptions_income]

    let newIncomeSource = {
      end_date: null,
      income_id: Math.floor(Math.random() * 100000),
      income_name: "New Income",
      populated_from_default: false,
      recurring_type: {recurring_name: "Monthly", recurring_type_id: 1},
      recurring_type_id: 1,
      scenario_id: scenario.scenario_id,
      start_date: null,
      tax_rate: 0,
      user_id: userOptions[0].value,
      yearly_amount: 0,
      yearly_growth_rate: 0,
    }

    newIncome.push(newIncomeSource)

    updateScenario("scenario_assumptions_income", newIncome)
    setUpdatedScenarioIncome(newIncome)
  };


	return (
	  <div>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.cellLessPadding} variant="head" align="left">Person</TableCell>
                <TableCell className={classes.cellLessPadding} variant="head" align="left">Income Source</TableCell>
                <TableCell className={classes.cellLessPadding} variant="head" align="left">Type</TableCell>
                <TableCell className={classes.cellLessPadding} variant="head" align="right">Yearly Amount</TableCell>  
                <TableCell className={classes.cellLessPadding} variant="head" align="right">Yearly Growth</TableCell>          
                <TableCell className={classes.cellLessPadding} variant="head" align="right">Tax Rate</TableCell>
                <TableCell className={classes.cellLessPadding} variant="head" align="right">Start Date</TableCell>
                <TableCell className={classes.cellLessPadding} variant="head" align="right">End Date</TableCell> 
                {editMode && 
                  <TableCell className={classes.cellLessPadding} variant="head" align="center"></TableCell> 
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {updatedScenarioIncome.map(income => (
                <TableRow key={income.income_id} >
                  <TableCell className={classes.cellLessPadding} align="left">
                    {!editMode &&
                      <span>{users[income.user_id].first_name + ' ' + users[income.user_id].last_name}</span>
                    }  
                    {editMode &&
                    <Select
                      className={classes.userInput}
                      value={{
                        value: income.user_id, 
                        label: users[income.user_id].first_name + ' ' + users[income.user_id].last_name,
                      }}
                      onChange={(value) => handleUserChanges(value, income.income_id)}
                      options={userOptions}
                      >

                    </Select>
                    }
                  </TableCell>
                  <TableCell className={classes.cellLessPadding} align="left">
                    {!editMode && 
                      <span>{income.income_name}</span>
                    }  
                    {editMode &&
                    <TextField
                      InputProps={{
                        classes: {
                          input: classes.incomeNameInput,
                        },
                      }}
                      type="text"
                      // id="income_name"
                      name="income_name"
                      className={classes.incomeNameInput}
                      defaultValue={income.income_name}
                      onChange={(event) => handleChanges(event, income.income_id)}
                    />
                    }
                  </TableCell>
                  <TableCell className={classes.cellLessPadding} align="left">
                    {!editMode && 
                      <span>{income.recurring_type.recurring_name}</span>
                    }  
                    {editMode &&
                      <Select
                        className={classes.recurringInput}
                        value={{
                          value: income.recurring_type.recurring_type_id, 
                          label: recurringTypes[income.recurring_type.recurring_type_id],
                        }}
                        onChange={(value) => handleRecurringChanges(value, income.income_id)}
                        options={recurringOptions}
                        >
                      </Select>
                    }
                  </TableCell>
                  <TableCell className={classes.cellLessPadding} align="right">
                  {!editMode &&
                    <NumberFormat value={income.yearly_amount} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                    }
                    {editMode &&
                    <TextField
                      type="number"
                      // id="yearly_amount"
                      name="yearly_amount"
                      className={classes.incomeInput}
                      defaultValue={income.yearly_amount}
                      onChange={(event) => handleChanges(event, income.income_id)}
                      inputProps={{
                        style: { textAlign: "right", fontSize: "0.875rem" },
                        step: 0.01,
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                    }
                  </TableCell>
                  <TableCell className={classes.cellLessPadding} align="right">
                    {income.recurring_type_id === 3 &&
                      <span>n/a</span>
                    }
                    {income.recurring_type_id !== 3 &&
                      <Fragment>                   
                      {!editMode &&
                        <NumberFormat value={income.yearly_growth_rate * 100.0} displayType={'text'} thousandSeparator={true} suffix={'%'} decimalScale={2} fixedDecimalScale={true} />
                      }
                      {editMode &&
                      <TextField
                        type="number"
                        // id="yearly_growth_rate"
                        name="yearly_growth_rate"
                        className={classes.growthInput}
                        defaultValue={income.yearly_growth_rate * 100.0}
                        onChange={(event) => handleChanges(event, income.income_id)}
                        inputProps={{
                          style: { textAlign: "right", fontSize: "0.875rem" },
                          step: 0.01,
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                      }
                      </Fragment>
                    }
                  </TableCell>
                  <TableCell className={classes.cellLessPadding} align="right">
                    {!editMode && 
                      <NumberFormat value={income.tax_rate * 100.0} displayType={'text'} thousandSeparator={true} suffix={'%'} decimalScale={2} fixedDecimalScale={true} />
                    }
                    {editMode &&
                    <TextField
                      type="number"
                      // id="tax_rate"
                      name="tax_rate"
                      className={classes.taxInput}
                      defaultValue={income.tax_rate  * 100.0}
                      onChange={(event) => handleChanges(event, income.income_id)}
                      inputProps={{
                        style: { textAlign: "right", fontSize: "0.875rem" },
                        step: 0.01,
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                    />
                    }
                  </TableCell>
                  <TableCell className={classes.cellLessPadding} align="right">
                    {!editMode && income.start_date &&
                      <span>{moment(income.start_date).format('MM/DD/YYYY')}</span>
                    }
                    {!editMode && !income.start_date &&
                      <span>None</span>
                    }
                  {editMode && income.start_date && 
                    <Fragment>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            InputProps={{
                              classes: {
                                input: classes.dateInput,
                              },
                            }}
                            disableToolbar
                            className={classes.dateInput}
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            name="start_date"
                            value={ moment(income.start_date).format('MM/DD/YYYY')}
                            onChange={(value) => handleStartDateChanges(value, income.income_id)}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        <IconButton aria-label="edit" onClick={() => handleStartDateChanges(null, income.income_id)}>
                          <ClearIcon fontSize="small" />
                        </IconButton>
                    </Fragment>
                  }
                  {editMode && !income.start_date && 
                    <Button color="primary" onClick={() => handleStartDateChanges(moment(), income.income_id)}>Add</Button>
                  }
                  </TableCell>
                  <TableCell className={classes.cellLessPadding} align="right">
                    {(income.recurring_type_id === 1 || income.recurring_type_id === 2) && 
                      <span>
                      {!editMode && income.end_date &&
                        <span>{moment(income.end_date).format('MM/DD/YYYY')}</span>
                      }
                      {!editMode && !income.end_date &&
                        <span>None</span>
                      }
                      {editMode && income.end_date &&
                        <Fragment>
                         <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                              InputProps={{
                                classes: {
                                  input: classes.dateInput,
                                },
                              }}
                              disableToolbar
                              className={classes.dateInput}
                              variant="inline"
                              format="MM/dd/yyyy"
                              margin="normal"
                              name="end_date"
                              value={moment(income.end_date).format('MM/DD/YYYY')}
                              onChange={(value) => handleEndDateChanges(value, income.income_id)}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />
                          </MuiPickersUtilsProvider>
                          <IconButton aria-label="edit" onClick={() => handleEndDateChanges(null, income.income_id)}>
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        </Fragment>
                      }
                      {editMode && !income.end_date && 
                        <Button color="primary" onClick={() => handleEndDateChanges(moment(), income.income_id)}>Add</Button>
                      }
                      </span>
                    }
                    {income.recurring_type_id === 3 &&
                      <span>n/a</span>
                    }
                  </TableCell>
                  {editMode && 
                    <TableCell className={classes.cellLessPadding} variant="head" align="center">
                      <IconButton aria-label="edit" onClick={() => removeIncome(income.income_id)}>
                        <DeleteOutlineIcon fontSize="small" />
                    </IconButton>
                    </TableCell> 
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {editMode && 
            <Button color="primary" onClick={() => addIncome()}>Add Income Source</Button>
          }
	  </div>
	)
}

const mapStateToProps = (state,props) => {
  const { space_id } = props;

  return {
    users: state.users,
    spaceUsers: state.spaceUsers[space_id]
  }

};

export default connect(mapStateToProps)(withStyles(styles)(ScenarioDetailIncome));