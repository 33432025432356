import React from "react";
import { withStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useAuth0 } from "@auth0/auth0-react";
import { clearState } from '../utilities/localStorage'



const styles = theme => ({
  arrowIcon: {
    color: "white",
  }
});

const UserMenu = (props) => {
  const { classes } = props;
  const { user } = props
  const { logout } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = () => {
    clearState()
    logout()
  }


  return (
  <React.Fragment>
    {user.profile.name} 
    <IconButton aria-label="user-menu" onClick={handleClick}>
      <KeyboardArrowDownIcon className={classes.arrowIcon} />
    </IconButton>
    <Menu
      id="user-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      //anchorOrigin={{ vertical: 'bottom', horizontal: 'right',}}
    >
      <MenuItem onClick={() => signOut()}>Logout</MenuItem>
    </Menu>
  </React.Fragment>
  );
}


export default withStyles(styles)(UserMenu);