import { RECEIVE_TRANSACTION_SPLIT_TEMPLATES } from '../actions/transactionSplitTemplates'

export default function transactionSplitTemplates (state = {}, action) {

  switch(action.type) {
    case RECEIVE_TRANSACTION_SPLIT_TEMPLATES :

      let newTransactionSplitTemplates = {}
      action.transactionSplitTemplates.map((tst) => newTransactionSplitTemplates[tst.split_template_id] = tst) 
      return {
        ...newTransactionSplitTemplates
      }
      
    default :
      return state
  }
}