
export const LOAD_TRANSACTION_CATEGORIES = 'LOAD_TRANSACTION_CATEGORIES'
export const RECEIVE_TRANSACTION_CATEGORIES = 'RECEIVE_TRANSACTION_CATEGORIES'


export function receiveTransactionCategories (transactionCategories) {
	return {
		type: RECEIVE_TRANSACTION_CATEGORIES,
		transactionCategories
	}
}

export function loadTransactionCategories () {
	return {
		type: LOAD_TRANSACTION_CATEGORIES
	}
}